import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Form, Button, Table } from "react-bootstrap";
import { withRouter } from "react-router";
import Sidebar from "../../components/sidebar.js";
import axios from 'axios';
import '../../Dashboard.css';
import '../style/Qc.css';
import Moment from 'react-moment';
import 'moment-timezone';
import edit from "../../images/edit.png";
import { Link } from "react-router-dom";
import { ExportToExcel } from "../../components/ExportToExcel";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@mui/material/TextField";
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { keyboard } from "@testing-library/user-event/dist/keyboard/index.js";

const Dash = props => {
  const [merchentList, setMerchentList] = useState([]);
  const [selectOptions, setselectOptions] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState();
  const [agentId, setAgentID] = useState();
  const [eticket, setEticket] = useState({});
  const [searchInput, setSearchInput] = useState('');
  const [DataForExcel, setDataForExcel] = useState([]);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(50);
  const [displayPagination, setDisplayPagination] = useState(false);
  const [totalMerchant, setTotalMerchant] = useState('');
  const [pageCount, setPageCount] = useState('');
  const [displayStart, setDisplayStart] = useState('');
  const [displayEnd, setDisplayEnd] = useState('');
  const [page, setPage] = React.useState(1);

  function diffDays(date, otherDate) {
    return (Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24)) + " Days");
  }



  useEffect(() => {
    getQcList(offset, limit);
    getWholeQCList();;
  }, []);
  const getQcList = (offsetp, limitp) => {
    axios.get(window.baseUrl + 'getTicketList?limit=' + limitp + '&offset=' + offsetp)
      .then((response) => {
        setDisplayPagination(true);
        setTotalMerchant(response.data.totalMerchants);
        //var res = response.data.data;
        //setMerchentList(response.data.data);
        let pageCount1 = response.data.totalMerchants / 50;
        pageCount1 = Math.ceil(pageCount1);
        if (pageCount != pageCount1) {

          setPageCount(pageCount1);
          setDisplayStart('1');
          setDisplayEnd('50');
        }
        var res = response.data.data;
        console.log(res.length);

        for (var i = 0; i < res.length; i++) {

          for (var x = 0; x < res[i].ticketlist.length; x++) {

            //var check=false;
            if (res[i].ticketlist.length == 1) {
              res[i].ticket_date = res[i].ticketlist[0].created_at;
              res[i].ticket_number = res[i].ticketlist[x].ticket_number;
              res[i].ticket_type = res[i].ticketlist[x].ticket_type;
              res[i].ticket_status = res[i].ticketlist[x].ticket_status;
              res[i].agent_id = res[i].ticketlist[x].agent_id;
              res[i].created_date = res[i].ticketlist[x].created_date;
              res[i].created_at = res[i].ticketlist[x].created_at;
              res[i].updated_at = res[i].ticketlist[x].updated_at;
              res[i].remarks = res[i].ticketlist[x].remarks;
              res[i].errorMessage = '';
            } else {
              if (x == res[i].ticketlist.length - 1) {

                if ((res[i].ticketlist[x].ticket_status == 0) || (res[i].ticketlist[x].ticket_status == 1)) {

                  // console.log(res[i].ticketlist[x].ticket_status +res[i].ticketlist[x].ticket_number );
                  res[i].ticket_date = res[i].ticketlist[0].created_at;
                  res[i].ticket_number = res[i].ticketlist[x].ticket_number;
                  res[i].ticket_type = res[i].ticketlist[x].ticket_type;
                  res[i].ticket_status = res[i].ticketlist[x].ticket_status;
                  res[i].agent_id = res[i].ticketlist[x].agent_id;
                  res[i].created_date = res[i].ticketlist[x].created_date;
                  res[i].created_at = res[i].ticketlist[x].created_at;
                  res[i].updated_at = res[i].ticketlist[x].updated_at;
                  res[i].remarks = res[i].ticketlist[x].remarks;
                  res[i].errorMessage = '';
                }

              }
              else if ((res[i].ticketlist[x].ticket_status == 0) || (res[i].ticketlist[x].ticket_status == 1)) {
                res[i].ticket_date = res[i].ticketlist[0].created_at;
                res[i].ticket_number = res[i].ticketlist[x].ticket_number;
                res[i].ticket_type = res[i].ticketlist[x].ticket_type;
                res[i].ticket_status = res[i].ticketlist[x].ticket_status;
                res[i].agent_id = res[i].ticketlist[x].agent_id;
                res[i].created_date = res[i].ticketlist[x].created_date;
                res[i].created_at = res[i].ticketlist[x].created_at;
                res[i].updated_at = res[i].ticketlist[x].updated_at;
                res[i].remarks = res[i].ticketlist[x].remarks;
                res[i].errorMessage = '';
                // check= true;
              }
              else {
                if (res[i].ticket_number == null) {
                  res[i].ticket_date = res[i].ticketlist[0].created_at;
                  res[i].ticket_number = res[i].ticketlist[x].ticket_number;
                  res[i].ticket_type = res[i].ticketlist[x].ticket_type;
                  res[i].ticket_status = res[i].ticketlist[x].ticket_status;
                  res[i].agent_id = res[i].ticketlist[x].agent_id;
                  res[i].created_date = res[i].ticketlist[x].created_date;
                  res[i].created_at = res[i].ticketlist[x].created_at;
                  res[i].updated_at = res[i].ticketlist[x].updated_at;
                  res[i].remarks = res[i].ticketlist[x].remarks;
                  res[i].errorMessage = '';
                }
              }

            }

          }
        }
        res.sort(function (a, b) {
          return new Date(b.ticket_date) - new Date(a.ticket_date)
        })
        setMerchentList(res);
        setDataForExcel(res);

        console.log(res);
      });
  }

  const getWholeQCList = () => {
    axios.get(window.baseUrl + 'getTicketList')
      .then((response) => {
        var res = response.data.data;

        for (var i = 0; i < res.length; i++) {

          for (var x = 0; x < res[i].ticketlist.length; x++) {

            //var check=false;
            if (res[i].ticketlist.length == 1) {
              res[i].ticket_date = res[i].ticketlist[0].created_at;
              res[i].ticket_number = res[i].ticketlist[x].ticket_number;
              res[i].ticket_type = res[i].ticketlist[x].ticket_type;
              res[i].ticket_status = res[i].ticketlist[x].ticket_status;
              res[i].agent_id = res[i].ticketlist[x].agent_id;
              res[i].created_date = res[i].ticketlist[x].created_date;
              res[i].created_at = res[i].ticketlist[x].created_at;
              res[i].updated_at = res[i].ticketlist[x].updated_at;
              res[i].remarks = res[i].ticketlist[x].remarks;
              res[i].errorMessage = '';
            } else {
              if (x == res[i].ticketlist.length - 1) {

                if ((res[i].ticketlist[x].ticket_status == 0) || (res[i].ticketlist[x].ticket_status == 1)) {

                  // console.log(res[i].ticketlist[x].ticket_status +res[i].ticketlist[x].ticket_number );
                  res[i].ticket_date = res[i].ticketlist[0].created_at;
                  res[i].ticket_number = res[i].ticketlist[x].ticket_number;
                  res[i].ticket_type = res[i].ticketlist[x].ticket_type;
                  res[i].ticket_status = res[i].ticketlist[x].ticket_status;
                  res[i].agent_id = res[i].ticketlist[x].agent_id;
                  res[i].created_date = res[i].ticketlist[x].created_date;
                  res[i].created_at = res[i].ticketlist[x].created_at;
                  res[i].updated_at = res[i].ticketlist[x].updated_at;
                  res[i].remarks = res[i].ticketlist[x].remarks;
                  res[i].errorMessage = '';
                }

              }
              else if ((res[i].ticketlist[x].ticket_status == 0) || (res[i].ticketlist[x].ticket_status == 1)) {
                res[i].ticket_date = res[i].ticketlist[0].created_at;
                res[i].ticket_number = res[i].ticketlist[x].ticket_number;
                res[i].ticket_type = res[i].ticketlist[x].ticket_type;
                res[i].ticket_status = res[i].ticketlist[x].ticket_status;
                res[i].agent_id = res[i].ticketlist[x].agent_id;
                res[i].created_date = res[i].ticketlist[x].created_date;
                res[i].created_at = res[i].ticketlist[x].created_at;
                res[i].updated_at = res[i].ticketlist[x].updated_at;
                res[i].remarks = res[i].ticketlist[x].remarks;
                res[i].errorMessage = '';
                // check= true;
              }
              else {
                if (res[i].ticket_number == null) {
                  res[i].ticket_date = res[i].ticketlist[0].created_at;
                  res[i].ticket_number = res[i].ticketlist[x].ticket_number;
                  res[i].ticket_type = res[i].ticketlist[x].ticket_type;
                  res[i].ticket_status = res[i].ticketlist[x].ticket_status;
                  res[i].agent_id = res[i].ticketlist[x].agent_id;
                  res[i].created_date = res[i].ticketlist[x].created_date;
                  res[i].created_at = res[i].ticketlist[x].created_at;
                  res[i].updated_at = res[i].ticketlist[x].updated_at;
                  res[i].remarks = res[i].ticketlist[x].remarks;
                  res[i].errorMessage = '';
                }
              }

            }

          }
        }
        res.sort(function (a, b) {
          return new Date(b.ticket_date) - new Date(a.ticket_date)
        })
        console.log("Sorted===>", res)
        setDataForExcel(res);
      });

  }

  function search(rows) {
    console.log("bow2", rows)
    return rows.filter(
      (rows) =>
        rows.mainmobilenumber !== null && rows.mainmobilenumber !== undefined && rows.mainmobilenumber !== undefined
    )
  }

  const agent_id = (event) => {
    event.preventDefault();
    setAgentID(event.target.value)
  }
  async function agent_validation(val) {
    console.log(val);
    const agent_validation = {
      "ticket_no": val.ticket_number,
      "merchant_id": val.merchantid,
      "agent_id": agentId
    };
    const headers = {
      "Content-Type": "application/json",
    };
    let key = merchentList.findIndex((data) => data.mainmobilenumber === val.mainmobilenumber)
    console.log("index", key)
    console.log(agent_validation);
    console.log("mrchnt===>", merchentList)
    axios
      .post(window.baseUrl + `agentList`, agent_validation, {
        headers: headers,
      })
      .then((res) => {
        console.log(res.data);
        console.log(res.data.message);
        if (res.data.agent_status == 1) {
          let clone = [...merchentList];
          let obj = clone[key];
          obj.errorMessage = res.data.message;
          clone[key] = obj;
          setMerchentList([...clone])
        }
        else {
          let clone = [...merchentList];
          let obj = clone[key];
          obj.agent_id = agentId;
          clone[key] = obj;
          setMerchentList([...clone])
        }
        //   window.location.reload();
      });
  }

  const handleEticket = (event, phoneNumber) => {
    setEticket(event);
    console.log(JSON.parse(event.target.value));
    console.log(phoneNumber)
    var item = JSON.parse(event.target.value);
    for (var i = 0; i < merchentList.length; i++) {
      if (merchentList[i].mainmobilenumber === phoneNumber) {
        merchentList[i].ticket_number = item.ticket_number;
        merchentList[i].ticket_type = item.ticket_type;
        merchentList[i].ticket_status = item.ticket_status;
        merchentList[i].agent_id = item.agent_id;
        merchentList[i].created_date = item.created_date;
        merchentList[i].created_at = item.created_at;
        merchentList[i].ticket_date = item.ticket_date;
        merchentList[i].updated_at = item.updated_at;
        merchentList[i].remarks = item.remarks;
        merchentList[i].errorMessage = '';
      }

    }

    setMerchentList(merchentList);
    setSearchInput(searchInput)

  }

  const handleSearchInput=(event)=>{
    setSearchInput(event.target.value);
    if(event.target.value === "" || event.target.value === "NULL")
    {
    let offset1 = 0;
     setLimit(50);
     let limt1 = 50;
     setOffset(offset1);
     getQcList(offset1, limt1);
    }
 }

 const handleSearch = () => {
   
  axios.get(window.baseUrl + 'getTicketList?search=' + searchInput)
    .then((response) => {
      // var res = response.data.data;
      // console.log(res.length);
      if (response.data.message == "Empty List") { 
        console.log("no data")
      }
      else {
        setDisplayPagination(false);
        

        let res = response.data.data;
        for (var i = 0; i < res.length; i++) {

          for (var x = 0; x < res[i].ticketlist.length; x++) {

            //var check=false;
            if (res[i].ticketlist.length == 1) {
              res[i].ticket_date = res[i].ticketlist[0].created_at;
              res[i].ticket_number = res[i].ticketlist[x].ticket_number;
              res[i].ticket_type = res[i].ticketlist[x].ticket_type;
              res[i].ticket_status = res[i].ticketlist[x].ticket_status;
              res[i].agent_id = res[i].ticketlist[x].agent_id;
              res[i].created_date = res[i].ticketlist[x].created_date;
              res[i].created_at = res[i].ticketlist[x].created_at;
              res[i].updated_at = res[i].ticketlist[x].updated_at;
              res[i].remarks = res[i].ticketlist[x].remarks;
              res[i].errorMessage = '';
            } else {
              if (x == res[i].ticketlist.length - 1) {

                if ((res[i].ticketlist[x].ticket_status == 0) || (res[i].ticketlist[x].ticket_status == 1)) {

                  // console.log(res[i].ticketlist[x].ticket_status +res[i].ticketlist[x].ticket_number );
                  res[i].ticket_date = res[i].ticketlist[0].created_at;
                  res[i].ticket_number = res[i].ticketlist[x].ticket_number;
                  res[i].ticket_type = res[i].ticketlist[x].ticket_type;
                  res[i].ticket_status = res[i].ticketlist[x].ticket_status;
                  res[i].agent_id = res[i].ticketlist[x].agent_id;
                  res[i].created_date = res[i].ticketlist[x].created_date;
                  res[i].created_at = res[i].ticketlist[x].created_at;
                  res[i].updated_at = res[i].ticketlist[x].updated_at;
                  res[i].remarks = res[i].ticketlist[x].remarks;
                  res[i].errorMessage = '';
                }

              }
              else if ((res[i].ticketlist[x].ticket_status == 0) || (res[i].ticketlist[x].ticket_status == 1)) {
                res[i].ticket_date = res[i].ticketlist[0].created_at;
                res[i].ticket_number = res[i].ticketlist[x].ticket_number;
                res[i].ticket_type = res[i].ticketlist[x].ticket_type;
                res[i].ticket_status = res[i].ticketlist[x].ticket_status;
                res[i].agent_id = res[i].ticketlist[x].agent_id;
                res[i].created_date = res[i].ticketlist[x].created_date;
                res[i].created_at = res[i].ticketlist[x].created_at;
                res[i].updated_at = res[i].ticketlist[x].updated_at;
                res[i].remarks = res[i].ticketlist[x].remarks;
                res[i].errorMessage = '';
                // check= true;
              }
              else {
                if (res[i].ticket_number == null) {
                  res[i].ticket_date = res[i].ticketlist[0].created_at;
                  res[i].ticket_number = res[i].ticketlist[x].ticket_number;
                  res[i].ticket_type = res[i].ticketlist[x].ticket_type;
                  res[i].ticket_status = res[i].ticketlist[x].ticket_status;
                  res[i].agent_id = res[i].ticketlist[x].agent_id;
                  res[i].created_date = res[i].ticketlist[x].created_date;
                  res[i].created_at = res[i].ticketlist[x].created_at;
                  res[i].updated_at = res[i].ticketlist[x].updated_at;
                  res[i].remarks = res[i].ticketlist[x].remarks;
                  res[i].errorMessage = '';
                }
              }

            }

          }
        }
        setMerchentList(response.data.data);

      }

      console.log("bow", response);
    });
};

const handlePageChange = (event, value) => {
  setPage(value);
  
  let offset1 = offset;
  let offset2 = (value) * 50;
  let limt1 = limit;

  if (value == 1) {
    offset1 = 0;
    setLimit(50);
    limt1 = 50;
    setDisplayStart('1');
    setDisplayEnd('50');
  } else
    if (offset2 > totalMerchant) {
      offset1 = (value-1) * 50;
      console.log("newOffset===>", offset1)
      limt1 = offset1;
      setLimit(offset1);
      setDisplayStart(((value - 1) * 50) + 1);
      setDisplayEnd(totalMerchant);
    }
    else {
      offset1 = ((value-1) * 50);
      limt1 = 50;
      setLimit(50);
      setDisplayStart(((value - 1) * 50) + 1);
      setDisplayEnd(value * 50);
    }
    
  setOffset(offset1);
  getQcList(offset1, limt1);

};


  return (
    <Container fluid>
      <Row>
        <Col xs={2} id="sidebar-wrapper">
          <Sidebar />
        </Col>
        <Col xs={10} id="page-content-wrapper">
        
          
          <div id="container" className="qcpanelclass qcpanelclass_info">
          <div className="search py-4 align-right px-4" >
            <TextField id="outlined-basic" variant="outlined" placeholder="Merchant phone" value={searchInput} onChange={(e) => handleSearchInput(e)}
              InputProps={{
                startAdornment: (
                  <InputAdornment>
                    <SearchIcon />
                  </InputAdornment>
                )
              }} />
              <Button variant="outlined" className="Search_button" onClick={handleSearch}>Search</Button>
          </div>
          <div className="search py-4 align-right px-4">
            <ExportToExcel apiData={DataForExcel} fileName="QC panel Listing" />
          </div>

            <Table striped bordered size="sm" style={{ position: "sticky", top: '0', zIndex: 1 }} >
              <thead>
                <tr>
                  <th className="p-4 qc_head text-center" style={{ position: "sticky", top: '0', zIndex: 1 }}>Merchant phone no</th>
                  <th className="p-4 qc_head text-center" style={{ position: "sticky", top: '0', zIndex: 1 }}>Ticket no</th>
                  <th className="p-4 qc_head text-center" style={{ position: "sticky", top: '0', zIndex: 1 }}>Ticket type</th>
                  <th className="p-4 qc_head text-center" style={{ position: "sticky", top: '0', zIndex: 1 }}>Creation date</th>
                  <th className="p-4 qc_head text-center" style={{ position: "sticky", top: '0', zIndex: 1 }}>Agent ID</th>
                  <th className="p-4 qc_head text-center" style={{ position: "sticky", top: '0', zIndex: 1 }}>Business name</th>
                  <th className="p-4 qc_head text-center" style={{ position: "sticky", top: '0', zIndex: 1 }}>City</th>
                  <th className="p-4 qc_head text-center" style={{ position: "sticky", top: '0', zIndex: 1 }}>Ticket ageing</th>
                  <th className="p-4 qc_head text-center" style={{ position: "sticky", top: '0', zIndex: 1 }}>Merchant status</th>
                  <th className="p-4 qc_head text-center" style={{ position: "sticky", top: '0', zIndex: 1 }}>Ticket status</th>
                  <th className="p-4 qc_head text-center" style={{ position: "sticky", top: '0', zIndex: 1 }}>Remarks</th>
                  <th className="p-4 qc_head text-center" style={{ position: "sticky", top: '0', zIndex: 1 }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {search(merchentList).map((val, key) => {
                  return (
                    <tr>
                      <td className="p-3 text-left align-middle">{val.mainmobilenumber}</td>
                      <td className="p-3 text-left align-middle">
                        <select className="form-control" name="ticketNumber" onChange={(e) => handleEticket(e, val.mainmobilenumber)}>
                          {val.ticketlist.map((e, key1) => {
                            console.log(val.ticketlist[key1].ticket_number);
                            if (key1 == (val.ticketlist.length - 1)) {

                              return <option key={key1} value={JSON.stringify(e)} >{e.ticket_number}</option>;
                            } else {
                              if ((val.ticketlist[key1 + 1].ticket_status == 0) || (val.ticketlist[key1 + 1].ticket_status == 1)) {

                                return <option key={key1} value={JSON.stringify(e)} disabled>{e.ticket_number}</option>;
                              }
                              else {
                                return <option key={key1} value={JSON.stringify(e)} >{e.ticket_number}</option>;
                              }

                            }
                          })}
                        </select>
                      </td>
                      <td className="p-3 text-left align-middle">{val.ticket_type == 0 ? <div className="p-1 mb-0 ">New onboarding</div> : <div className="p-1 mb-0 ">Profile update</div>}</td>
                      <td className="p-3 text-left align-middle"><Moment format="DD/MM/YYYY">{val.created_at}</Moment></td>
                      <td className="pt-3 pb-3 pl-1 pr-1 text-left align-middle">{val.agent_id == null ? <div><input type="text" name="name" className="agent_input mt-0 d-inline-block" autocomplete="off" onChange={agent_id} /><button type="button" className="agent_button d-inline-block float-right" onClick={() => agent_validation(val)}>Submit</button>{val.errorMessage && (<p className="w-100 float-left text-center pt-3">{val.errorMessage}</p>)}</div> : val.agent_id}</td>
                      <td className="p-3 text-left align-middle">{val.shopname}</td>
                      <td className="p-3 text-left align-middle">{val.city}</td>
                      {console.log("agentID========>", val.agent_id)}
                      <td className="p-3 text-left align-middle">{val.ticket_status == 2 ? diffDays(new Date(val.created_at), new Date(val.updated_at)) : diffDays(new Date(val.created_at), new Date())}</td>
                      <td className="p-3 text-center align-middle">{val.bizstatus === 0 ? <div className="p-1 mb-0 text-dark  mx-auto" style={{ backgroundColor: '#62FDA0' }}>Active</div> : <div className="p-1 mb-0 text-dark  mx-auto" style={{ backgroundColor: '#FD626B' }}>Inactive</div>}</td>
                      <td className="p-3 text-center align-middle">{val.ticket_status == 0 ? <div className="p-1 mb-0 text-dark  mx-auto" style={{ backgroundColor: '#A5EBEF' }}>New</div> : val.ticket_status == 1 ? <div className="p-1 mb-0 text-dark  mx-auto" style={{ backgroundColor: '#FFBD7E' }}>Pending</div> : val.ticket_status == 2 ? <div className="p-1 mb-0 text-dark  mx-auto" style={{ backgroundColor: '#62FDA0' }}>Closed</div> : <div className="p-1 mb-0 text-dark  mx-auto" style={{ backgroundColor: '#FD626B' }}>Merchant action</div>}</td>
                      <td className="p-3 text-left align-middle">{val.remarks == null ? '' : val.remarks}</td>
                      {val.agent_id == null ?
                        <td className="p-3 text-center align-middle">

                          <Link className="pr-2" style={{ pointerEvents: 'none', opacity: '0.5' }} to={{
                            pathname: `/qcValidation/${val.merchantid}/${val.ticket_number}`
                          }}>
                            <img style={{ width: '30px', height: '30px' }} src={edit} ></img>
                          </Link>
                        </td> : <td className="p-3 text-center align-middle">

                          <Link className="pr-2" to={{
                            pathname: `/qcValidation/${val.merchantid}/${val.ticket_number}`
                          }}>
                            <img style={{ width: '30px', height: '30px' }} src={edit} ></img>
                          </Link>
                        </td>
                      }
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
          
        </Col>
        {displayPagination == true ?
              <Stack spacing={2} style={{ display: 'block', width: '83%', marginBottom: '5px', left: '16%', right: '0px', position: 'fixed', bottom: '0px', backgroundColor: '#CDECF4', padding: '5px 3px ' }}>
                <Typography style={{ display: 'inline-block', width: '30%' }}>Showing Results {displayStart} to {displayEnd} of {totalMerchant} </Typography>
                <Pagination style={{ display: 'inline-block', marginTop: '-3px', float: 'right' }} count={pageCount} page={page}  className="PaginationCheck" onChange={handlePageChange} />
              </Stack>
              : ''}
      </Row>
    </Container>
  )
};
const Dashboard = withRouter(Dash);
export default Dashboard