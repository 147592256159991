import React, { useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button,Input } from '@mui/material';
import { useHistory } from "react-router-dom";
import { authentication } from "../firebase-config";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import '../login.css';
import Logo from "../chatty_bao_logo.png";

const Login = (props) => {
  const history = useHistory(); 
  const [mobileNumber, setMobileNumber] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showOtp, setShowOtp] = useState(false);
  const [Otp,setOtp] =useState('');
  const [errorMessage,setErrorMessage] =useState('');
 
  if( window.sessionStorage.getItem('sessionId')!=undefined)
  {
    history.push("/sidebar");
  }
  
  const handleChange = (event) => {
    let number="+91"+event.target.value;
    let number_phone=event.target.value;
   setMobileNumber(number);
   setPhoneNumber(number_phone);
  }
  const handleChangeOtp = (event) => {
   
    setOtp(event.target.value);
   
  }
  const resendOtp=(e)=>{
    e.preventDefault();
    generateRecaptcha();
    let appVerifier= window.recaptchaVerifier;
    var phoneNumber=mobileNumber;
    signInWithPhoneNumber(authentication,phoneNumber,appVerifier)
    .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        console.log(confirmationResult);
        window.confirmationResult = confirmationResult;
        setShowOtp(true);
       // history.push("/otp");
        // ...
      }).catch((error) => {
        // Error; SMS not sent
        console.log(error);
        setErrorMessage('');
          setErrorMessage("SMS not sent");
       
        // ...
      });
   
  }
  const verifyOTp = (event)=>{
    event.preventDefault();
    let otp =Otp;
    console.log(otp);
    
    if(otp.length === 6){
      //verify otp
      console.log("kkk");
       let confirmationResult=window.confirmationResult;
       confirmationResult.confirm(otp).then((result) => {
        // User signed in successfully.
        const user = result.user;
        const firebaseId=user.uid;
        console.log(user);
        console.log(firebaseId);
        const headers = {
          'Content-Type': 'application/json',
                }
        const user_check = {
                "mobileNumber":phoneNumber,
                "firebaseId":user.uid
              };
              console.log(user_check);
            axios.post(window.baseUrl+`webLogin`,user_check, {
              headers: headers
         })
        .then(res => {
        console.log(res.data);
        console.log(res.data.message);
        if(res.data.message!="User not exist")
        {
        console.log(res.data.data.sessionId);
        var current = Date.now();
        window.sessionStorage.setItem('logintime',current);
        window.sessionStorage.setItem('sessionId',res.data.data.sessionId);
        window.sessionStorage.setItem('adminMobileNumber',res.data.data.mobileNumber);
        window.sessionStorage.setItem('categoryPanel',res.data.data.category);
        window.sessionStorage.setItem('qcPanel',res.data.data.qc);
        window.sessionStorage.setItem('misPanel',res.data.data.mis);
        history.push("/sidebar");
        }else{
          setErrorMessage('');
          setErrorMessage(res.data.message);
        }
      })
        // ...
      }).catch((error) => {
        // User couldn't sign in (bad verification code?)
        // ...
        setErrorMessage('');
          setErrorMessage("Entered wrong OTP");
      });
      
      //verify otp
    }
  }
  
  const generateRecaptcha=()=>{
    if (!window.recaptchaVerifier) {
    window.recaptchaVerifier = new RecaptchaVerifier('recapcha-container', {
        'size': 'invisible',
        'callback': (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
         // onSignInSubmit();
         console.log(response);
        }
      }, authentication);
    } 
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    setErrorMessage('');
    const sendOtp=()=>{
     
      generateRecaptcha();
      let appVerifier= window.recaptchaVerifier;
      var phoneNumber=mobileNumber;
      signInWithPhoneNumber(authentication,phoneNumber,appVerifier)
      .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          console.log(confirmationResult);
          window.confirmationResult = confirmationResult;
          setShowOtp(true);
         // history.push("/otp");
          // ...
        }).catch((error) => {
          // Error; SMS not sent
          console.log(error);
          // ...
        
        });
        
    }
    if(mobileNumber === '') {
      setErrorMessage('Please enter Mobile Number');
      return;
    }
    else{
      sendOtp();
     
    }
    
    
//     const user = {
//       "mobileNumber": mobileNumber.toString(),
//       "firebaseId":"AhL9OCAqrLbtnM3cKmse6BlR3RL2"
//     };
// console.log(user);
//     axios.post(`http://3.23.36.177:4000/v1/login`, {
//       user
//   })
//       .then(res => {
//         console.log(res.data);
//         console.log(res.data.data.sessionId);
//         window.sessionStorage.setItem('sessionId',res.data.data.sessionId);
//         history.push("/home");
//       })

  }

    return (
    
    <form className='login_form'>
      <h1>Welcome to ChattyBao</h1>
      <div className='chatty_bao'>
       <img className='chatty_bao_logo' src={Logo} />
       </div>
       <div className='login_div'>
          <label className='mobile_number'> Please enter mobile number </label>
          <input type="number" className='mobile_num_input' name="mobileNumber" onChange={handleChange} />
          <div  style={{ display: showOtp ? "block" : "none" }} >
            <label className="otp">Enter Otp</label>
            <input type="number"  id="otp" onChange={handleChangeOtp} />
            <button  className="resend_otp pt2 w-100" onClick={resendOtp}>Resend otp</button>
            <div className="procced"  >
              <button onClick={verifyOTp}>Submit</button>
            </div>
           </div>
           <span className="text-help" >
            {errorMessage ? errorMessage : ''}
          </span>
          <div className="procced" style={{ display: !showOtp ? "block" : "none" }} >
          <button  onClick={handleSubmit}>Proceed</button>
          </div>
          
          <div id="recapcha-container"></div>

        </div>
    </form>

        
    );
    };
  


export default Login;