import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Col, Modal, ModalBody, Row, Input, Label, FormGroup, Button, Form } from "reactstrap";
import { withRouter } from "react-router";
import Sidebar from "./sidebar.js";
import '../Dashboard.css';
import axios from 'axios';
import { TextField } from "react-admin";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import StatusData from './utils/Json/statusData';
import MerchantService from '../api/services/MerchantService';
import {
  alertDangerConfirmMessage,
  alertSuccessMessage,
  alertSuccessMessageMerchant
} from "../components/customComponent/ConfirmationMessage/ConfirmationMessage";
import CategoryService from '../api/services/CategoryService'
import AwsService from '../api/services/AwsImageService';
import FileUploader from '../components/customComponent/FileUploader/FileUploader';
import Loader from "../components/loader/loader";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import { saveAs } from "file-saver";
import AwsPDFService from '../api/services/AwsPDFService';
import moment from 'moment';
import {APIConfig} from "../api/apiConfig/apiConfig.js";





const BusinessProof = [
  { id: 0, name: "Select", value: "Select" },
  { id: 1, name: "GST certificate", value: "GST certificate" },
  { id: 2, name: "Shops & establishment certificate", value: "Shops & establishment certificate" },
  { id: 3, name: "Electricity bill of last 3 months", value: "Electricity bill of last 3 months" },
  { id: 4, name: "Business PAN card", value: "Business PAN card" },
  { id: 5, name: "Udyog Aadhar", value: "Udyog Aadhar" },
  { id: 6, name: "Cancelled cheque in name of business", value: "Cancelled cheque in name of business" },
  { id: 7, name: "Printed bill book", value: "Printed bill book" },
  { id: 8, name: "Rent agreement", value: "Rent agreement" },
];
const ClassificationSelect = [
  { id: 0, name: "Organized retail", value: "S", disabled: false },
  { id: 1, name: "Shops with GST verified and/OR Recommendation", value: "A", disabled: false },
  { id: 2, name: "Shops with GST verified ", value: "B", disabled: false },
  { id: 3, name: "Shops without GST verified", value: "C", disabled: false },
  { id: 4, name: "Downgraded shops", value: "D", disabled: false },
  { id: 5, name: "Classification E", value: "E", disabled: false },
];



const GstStatus = [
  { id: 0, name: "Verified", option: "Verified", value: 0 },
  { id: 1, name: "unVerified", option: "Unverified", value: 1 },


];

const subscriptionEvents = [
  {id: 0, name: 'Existing merchant'},
  {id: 1, name: 'Standard'},
  {id: 2, name: 'VIP Monthly'},
  {id: 3, name: 'VIP Quarterly'},
  {id: 4, name: 'VIP Annual'},
]

const renewalFlagData = [
  {id: 0, value: 'Off'},
  {id: 1, value: 'On'},
];


const Editdetails = (props) => {
  console.log("props===========>", props.location.state.closetime)
  console.log("props2===========>", props.location.state.closetime2)
  console.log("caty", props)

  const moment = require('moment');
  const {baseUrl} = APIConfig;
  const [id, setId] = useState(props.match.params.id);
  const [shopName, setShopName] = useState();
  const [Address, setAddress] = useState();
  const [merchantName, setMerchantName] = useState();
  const [ownerAadharNumber, setOwnerAadharNumber] = useState();
  const [Child_mob_1, setChild_mob_1] = useState();
  const [Child_mob_2, setChild_mob_2] = useState();
  const [teamMobileNumbers, setTeamMobileNumbers] = useState();
  const [kycDocumentType, setkycDocumentType] = useState();
  const [kycDocumentNumber, setKycDocumentNumber] = useState();
  const [businessProofType, setBusinessProofType] = useState();
  const [businessProofNumber, setBusinessProofNumber] = useState();
  const [businessProofExpiry, setBusinessProofExpiry] = useState();
  const [businessProof, setBusinessProof] = useState('');
  console.log("businessProof", businessProof);
  const [businessProofPhotos, setBusinessProofPhotos] = useState([]);
  const [isFssaiFileChanged, setIsFssaiFileChanged] = useState(false);
  const [licenseUrl, setLicenseUrl] = useState([]);
  const [BankAccNumber, setBankAccNumber] = useState();
  const [bankName, setBankName] = useState();
  const [bankIfsc, setBankIfscCode] = useState();
  const [openTime, setOpenTime] = useState();
  const [closeTime, setCloseTime] = useState();
  const [openTime2, setOpenTime2] = useState();
  const [closeTime2, setCloseTime2] = useState();
  const [storeDescription, setStoreDescription] = useState();
  const [MarketName, setMarketName] = useState();
  const [CategoryName, setCategoryName] = useState();
  const [gstNumber, setGstnumber] = useState();
  const [catalogPhotos, setCatalogphotos] = useState([]);
  const [fssaiImage, setFssaiImage] = useState([]);
  const [teamMembers, setTeammembers] = useState();
  // const [Bizstatus,setBizstatus] =useState();
  const [verifyStatus, setVerifyStatus] = useState();
  const [ratings, setRatings] = useState();
  const [referralCode, setReferralCode] = useState();
  const [accessControl, setAccesscontrol] = useState();
  const [bizStatus, setBizStatus] = useState('');
  const [accVerifyStatus, setAccVerifyStatus] = useState('');
  const [categoryl1, setCategoryl1] = useState('');
  const [categoryl2, setCategoryl2] = useState('');
  const [categoryl3, setCategoryl3] = useState('');
  const [categoryl4, setCategoryl4] = useState('');
  const [addressLine1, setAddressLine1] = useState('')
  const [addressLine2, setAddressLine2] = useState('');
  const [weeklyOff, setWeeklyOff] = useState('');
  const [pincode, setPincode] = useState('');
  const [picUrl, setPicUrl] = useState('');
  const [city, setCity] = useState('');
  const [accName, setAccName] = useState('');
  const [accNumber, setAccNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [photo, setPhoto] = useState('');
  const [photoFileName, setPhotoFileName] = useState('');
  const [photoFile, setPhotoFile] = useState('');
  const [isFileChanged, setIsFileChanged] = useState(false);
  const [isLicenseFileChanged, setIsLicenseFileChanged] = useState(false);
  const [shopLocation, setShopLocation] = useState('');
  const [l1Data, setL1Data] = useState([]);
  const [l2Data, setL2Data] = useState([]);
  const [l3Data, setL3Sata] = useState([]);
  const [l4Data, setL4Data] = useState([]);
  const [photo_qc, setPhoto_qc] = useState('0');
  const [business_qc, setBusiness_qc] = useState('0');
  const [bank_qc, setBank_qc] = useState('0');
  const [license_qc, setlicense_qc] = useState('0');
  const [l3Id, setl3Id] = useState('');
  const [canChequeurl, setCanChequeurl] = useState('');
  const [classification, setClassification] = useState();
  const [commission_sd_per, setCommission_sd_per] = useState('');
  const [gst_on_commission_per, setGst_on_commission_per] = useState('');
  const [commission_per, setCommission_per] = useState('');
  const [gstStatus, setGstStatus] = useState('');
  const [catalogPdf, setCatlogPDF] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [licenseNo, setLicenseNo] = useState('');
  const [adminPhoneNumber, setAdminPhoneNumber] = useState('');
  const [subscriptionPlan, setSubscriptionFlag] = useState('');
  const [currentSubscriptionPlan, setCurrentSubscriptionFlag] = useState('');
  const [renewalFlag, setRenewalFlag] = useState('');
  const [freeDelivery, setFreeDelivery] = useState();

  const addFreeDelivery = async()=> {
    await axios
    .post(baseUrl + 'updateFreeDeliveryCount',{
      merchantID : id,
      freeDelivery : freeDelivery
    })
    .then((response)=>{
      var responseStatus = response.data.status;
      if (responseStatus === 1) {
        alert("Free deliveries failed");
      } else {
        alert("Success");
        window.location.reload();
      }
    })
  }

  useEffect(()=> {
    const phno = window.sessionStorage.getItem('adminMobileNumber')
    setAdminPhoneNumber(phno);
  },[])
  

  console.log("businessProofPhotos" + businessProofPhotos);

  const useStyles = makeStyles(() => ({
    formControlLabel: { fontSize: "14px", "& label": { fontSize: "0.6rem" } }
  }));
  const classes = useStyles();
  //const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = React.useState('female');

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const handleCommissionPer = (event) => {
    setCommission_per(event.target.value);
  };
  const handleCommission_sd_per = (event) => {
    setCommission_sd_per(event.target.value);
  };
  const handleGst_on_commission_per = (event) => {
    setGst_on_commission_per(event.target.value);
  };

  // ClassificationSelect.find((value) => {
  //   if (value.value === classification) {
  //     value.disabled = true;
  //   }
  // })


  const handleShopName = (event) => {
    setBusiness_qc(2);
    setPhoto_qc(2);
    event.preventDefault();
    setShopName(event.target.value);
  }
  const handleShopLocation = (event) => {
    event.preventDefault();
    setShopLocation(event.target.value);
  }


  const handleOwnerAadharNumber = (event) => {
    event.preventDefault();
    setOwnerAadharNumber(event.target.value);
  }

  const handleGstNumber = (event) => {
    event.preventDefault();
    setGstnumber(event.target.value);
  }
  const handleCatalogPhotos = (event) => {
    event.preventDefault();
    setCatalogphotos(event.target.value)
  }

  const handleLicenseNo = (event) => {
    event.preventDefault();
    setLicenseNo(event.target.value)
  }

  const handleTeamMembers = (event) => {
    event.preventDefault();
    setTeammembers(event.target.value)
  }
  const handleSecondaryMobileNumbers1 = (event) => {
    event.preventDefault();
    setChild_mob_1(event.target.value)
  }
  const handleSecondaryMobileNumbers2 = (event) => {
    event.preventDefault();
    setChild_mob_2(event.target.value)
  }
  const handleMerchantName = (event) => {
    event.preventDefault();
    setMerchantName(event.target.value)
  }
  const handleTeamMobileNumbers = (event) => {
    event.preventDefault();
    setTeamMobileNumbers(event.target.value);
  }
  const handleRerralCode = (event) => {
    event.preventDefault();
    setReferralCode(event.target.value)
  }
  const handleAccVerifyStatus = (event) => {
    event.preventDefault();
    setAccVerifyStatus(event.target.value)
  }
  const handleRatings = (event) => {
    event.preventDefault();
    setRatings(event.target.value)
  }
  const handlekycDocumentType = (event) => {
    event.preventDefault();
    setkycDocumentType(event.target.value);
  }
  const handleKycDocumentNumber = (event) => {
    event.preventDefault();
    setKycDocumentNumber(event.target.value);
  }

  const handleBusinessprooftype = (event) => {
    event.preventDefault();
    setBusinessProofType(event.target.value);
  }
  const handleBusinessProofNumber = (event) => {
    event.preventDefault();
    setBusinessProofNumber(event.target.value)
  }
  const handleBusinessproofexpiry = (event) => {
    event.preventDefault();
    setBusinessProofExpiry(event.target.value);
  }
  const handleBusinessProof = (event) => {
    event.preventDefault();
    setBusinessProof(event.target.value)
  }
  const handleClassification = (event) => {
    event.preventDefault();
    setClassification(event.target.value)
    // ClassificationSelect.map((value) => {
    //   value.disabled = false;
    // })
  }
  const handlegstStatus = (event) => {
    event.preventDefault();
    //setgs(event.target.value)
    //alert(event.target.value);
    setGstStatus(event.target.value);
  }
  const handleStoreDescription = (event) => {
    event.preventDefault();
    setStoreDescription(event.target.value);
  }
  const handleAccessControl = (event) => {
    event.preventDefault();
    setAccesscontrol(event.target.value)
  }
  const handleCategoryL1 = (event) => {
    let l1test = [];
    let l2test = [];
    let l3test = [];
    let l4test = [];
    setCategoryl2('');
    setCategoryl3('');
    setCategoryl4('');
    setL2Data([]);
    setL3Sata([]);
    setL4Data([]);
    setCategoryl1(event.target.value)
    l1Data.map(item => {
      if (event.target.value === item.id) {
        setL2Data([...item.children]);
        l2test.push(item.children);
        if (l2test.length >= 1) {

          item.children.map((it, index) => {


            if (index == 0) {
              console.log("trueeee", it.id);
              setCategoryl2(it.id);
              setL3Sata([...it.children]);
              l3test.push(it.children);

              if (it.children.length >= 1) {
                console.log(l3test + "l3test");
                it.children.map((it1, index) => {


                  if (index == 0) {
                    console.log("trueeee", it1.id);
                    setCategoryl3(it1.id);
                    l4test.push(it1.children);
                    setL4Data([...it1.children])

                    if (it1.children.length >= 1) {
                      it1.children.map((it2, index) => {
                        if (index == 0) {
                          console.log("trueeee", it2.id);
                          setCategoryl4(it2.id);

                        }

                      })
                    }
                  }

                })
              }
            }



          })
        }
      }
    })




  }
  function handleNewPDF(item) {

    // setMenuStatus('0');
    console.log(item[0].file);

    let newName = merchantName.replace(/ /gi, "-") + "_menu_" + moment().unix();

    var prefix = item[0].file.name.split('.')[0];
    var fileExtension = '.' + item[0].file.name.split('.')[1];
    var name = newName + new Date().getTime() + fileExtension;
    var blob = item[0].file.slice(0, item[0].size, item[0].type);
    var newFile = new File([blob], name, { type: item[0].type });


    AwsPDFService.uploadImageToS3(newFile)
      .then(data => {
        //   url = data.uri;
        if (data.uri !== '') {
          // setCategoryImage(url);
          // alert(data.uri)
          setCatlogPDF(data.uri);
          console.log(data.uri);
        }
      }
      )
      .catch(err => console.error(err));

  };
  const handleCategoryL2 = (event) => {

    let l2test = [];
    let l3test = [];
    let l4test = [];

    setCategoryl3('');
    setCategoryl4('');

    setL3Sata([]);
    setL4Data([]);
    setCategoryl2(event.target.value)
    l2Data.map(item => {
      if (event.target.value === item.id) {
        setL3Sata([...item.children]);
        l3test.push(item.children);
        if (l3test.length >= 1) {
          item.children.map((it, index) => {


            if (index == 0) {
              console.log("trueeee", it.id);
              setCategoryl3(it.id);
              setL4Data([...it.children]);
              l4test.push(it.children);

              if (it.children.length >= 1) {
                it.children.map((it1, index) => {


                  if (index == 0) {
                    console.log("trueeee", it1.id);
                    setCategoryl4(it1.id);



                  }

                })
              }
            }



          })
        }
      }
    })

  }
  const handleCategoryL3 = (event) => {
    // event.preventDefault();
    // let l3Id
    l3Data.map(item => {
      if (event.target.value === item.id) {
        setL4Data([...item.children])
        if (item.children.length >= 1) {
          item.children.map((it, index) => {
            if (index == 0) {
              console.log("trueeee", it.id)
              setCategoryl4(it.id)
            }
          })
        }
      }
    })
    console.log("l333", event.target.value)
    setCategoryl3(event.target.value)
  }
  const handleCategoryL4 = (event) => {
    event.preventDefault();
    // let l4Id
    // {l4Data.map(item => {
    //   if(event.target.value === item.name){
    //     console.log("true")
    //     l4Id = item.id
    //   }

    // })}  
    console.log("l3334", event)
    setCategoryl4(event.target.value)
  }
  const handleCloseTime = (event) => {
    event.preventDefault();
    setCloseTime(event.target.value)
  }
  const handleCloseTime2 = (event) => {
    event.preventDefault();
    setCloseTime2(event.target.value)
  }
  const handleOpenTime = (event) => {
    event.preventDefault();
    setOpenTime(event.target.value)
  }
  const handleOpenTime2 = (event) => {
    event.preventDefault();
    setOpenTime2(event.target.value)
  }
  const handleAddressLine1 = (event) => {
    event.preventDefault();
    setAddressLine1(event.target.value)
  }
  const handleAddressLine2 = (event) => {
    event.preventDefault();
    setAddressLine2(event.target.value)
  }
  const handleWeeklyOff = (event) => {
    event.preventDefault();
    setWeeklyOff(event.target.value)
  }
  const handlePincode = (event) => {
    event.preventDefault();
    setPincode(event.target.value)
  }
  const handlePicUrl = (event) => {
    event.preventDefault();
    setPicUrl(event.target.value)
  }
  const handleCity = (event) => {
    event.preventDefault();
    setCity(event.target.value)
  }
  const handleBankName = (event) => {
    setBank_qc(2);
    event.preventDefault();
    setBankName(event.target.value)
  }
  const handleBankIfsc = (event) => {
    setBank_qc(2);
    event.preventDefault();
    setBankIfscCode(event.target.value)
  }
  const handleAccName = (event) => {
    setBank_qc(2);
    event.preventDefault();
    setAccName(event.target.value)
  }
  const handleMaketName = (event) => {

    event.preventDefault();
    setMarketName(event.target.value)
  }
  const handleAccNumber = (event) => {
    setBank_qc(2);
    event.preventDefault();
    setAccNumber(event.target.value)
  }
  const handleVerifyStatus = (event) => {
    event.preventDefault();
    setVerifyStatus(event.target.value)
  }

  const handleLatitude = (event) => {
    event.preventDefault();
    setLatitude(event.target.value)
  }

  const handleLongitude = (event) => {
    event.preventDefault();
    setLongitude(event.target.value)
  }

  const handleSumbitCancel = (event) => {
    props.history.push("/merchant");
  }
  const handleStatus = (item) => {
    setBizStatus(item)
  }

  const handleSubscription = (event) => {
    event.preventDefault();
    setSubscriptionFlag(Number(event.target.value));
  }

  const handleRenewalFlag = (event) => {
    event.preventDefault();
    setRenewalFlag(event.target.value);
  }

  const getThumbnail = (item) => {
    setPhoto(item[0].base64);
    setPhotoFile(item[0].file);
    setPhotoFileName(item[0].file.name);
    setPhoto_qc(2);
    setBusiness_qc(2);

    let url = ''
    setIsFileChanged(true);
    if (item[0].file != '') {
      AwsService.uploadImageToS3(item[0].file)
        .then(data => {
          url = data.uri;
          if (data.uri !== '') {
            // setCategoryImage(url);
            setPicUrl(data.uri)
          }
        }
        )
        .catch(err => console.error(err));
    }
    if (url) {
    }
  };
  const getCatalogImg = (item) => {
    // setPhoto(item[0].base64);
    // setPhotoFile(item[0].file);
    // setPhotoFileName(item[0].file.name);

    const photoFile = [];
    setLicenseUrl([]);
    console.log("photoFile", photoFile);
    setIsFileChanged(true);

    item.map((imgpaths) => {
      AwsService.uploadImageToS3(imgpaths.file)
        .then((data) => {
          photoFile.push(data.uri);
        })
        .catch((err) => console.error(err));
    });

    setTimeout(() => {
      setCatalogphotos(photoFile);
    }, 3000);
  };
  const getLicenseImage = (item) => {
    // setPhoto(item[0].base64);
    // setPhotoFile(item[0].file);
    // setPhotoFileName(item[0].file.name);
    //   let url = ''
    //   setIsLicenseFileChanged(true);
    //   setlicense_qc(2);

    //   if (item[0].file != '') {
    //     AwsService.uploadImageToS3(item[0].file)
    //       .then(data => {
    //         url = data.uri;
    //         if (data.uri !== '') {
    //           // setCategoryImage(url);
    //           setLicenseUrl(data.uri)
    //         }
    //       }
    //       )
    //       .catch(err => console.error(err));
    //   }
    //   if (url) {
    //   }
    // };
    const photoFile = [];
    setLicenseUrl([]);
    console.log("photoFile", photoFile);
    setIsLicenseFileChanged(true);

    setlicense_qc(2);
    item.map((imgpaths) => {
      AwsService.uploadImageToS3(imgpaths.file)
        .then((data) => {
          photoFile.push(data.uri);
        })
        .catch((err) => console.error(err));
    });

    setTimeout(() => {
      setLicenseUrl(photoFile);
    }, 3000);
  };

  const getFssaiImage = (item) => {
    // setPhoto(item[0].base64);
    // setPhotoFile(item[0].file);
    // setPhotoFileName(item[0].file.name);
    //   let url = ''
    //   setIsFssaiFileChanged(true);
    //   setlicense_qc(2);
    //   if (item[0].file != '') {
    //     AwsService.uploadImageToS3(item[0].file)
    //       .then(data => {
    //         url = data.uri;
    //         if (data.uri !== '') {
    //           // setCategoryImage(url);
    //           setFssaiImage(data.uri)
    //         }
    //       }
    //       )
    //       .catch(err => console.error(err));
    //   }
    //   if (url) {
    //   }
    // };
    const photoFile = [];
    setFssaiImage([]);
    console.log("photoFile", photoFile);
    setIsFssaiFileChanged(true);
    setlicense_qc(2);
    item.map((imgpaths) => {
      AwsService.uploadImageToS3(imgpaths.file)
        .then((data) => {
          photoFile.push(data.uri);
        })
        .catch((err) => console.error(err));
    });

    setTimeout(() => {
      setFssaiImage(photoFile);
    }, 3000);
  };
  const getBusinessProofPhotos = (item) => {
    const photoFile = [];
    setBusinessProofPhotos([]);
    console.log("photoFile", photoFile);
    setIsFileChanged(true);
    setlicense_qc(2);
    item.map((imgpaths) => {
      AwsService.uploadImageToS3(imgpaths.file)
        .then((data) => {
          photoFile.push(data.uri);
        })
        .catch((err) => console.error(err));
    });

    setTimeout(() => {
      setBusinessProofPhotos(photoFile);
    }, 3000);
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
    console.log("hello" + JSON.stringify(props.location.state));
    console.log("hello 2" + props.location.l3Data)

    if (props.location.state) {
      setShopName(props.location.state.shopname);
      setClassification(props.location.state.classification);
      setAddressLine1(props.location.state.addressline1);
      setAddressLine2(props.location.state.addressline2);
      setMerchantName(props.location.state.merchantname)
      setOwnerAadharNumber(props.location.state.owneraadharnumber);

      setTeamMobileNumbers(props.location.state.teammobilenumbers);
      if (props.location.state.teammobilenumbers.length == 0) {
        setChild_mob_1('');
        setChild_mob_2('');
      } else if (props.location.state.teammobilenumbers.length == 1) {
        setChild_mob_1(props.location.state.teammobilenumbers[0]);
        setChild_mob_2('');
      }
      else {
        setChild_mob_1(props.location.state.teammobilenumbers[0]);
        setChild_mob_2(props.location.state.teammobilenumbers[1]);
      }
      setBankAccNumber(props.location.state.accnumber);
      setBankName(props.location.state.bankname);
      setBankIfscCode(props.location.state.bankifsc);
      setOpenTime(props.location.state.opentime);
      setCloseTime(props.location.state.closetime);
      setOpenTime2(props.location.state.opentime2);
      setCloseTime2(props.location.state.closetime2);
      setWeeklyOff(props.location.state.weeklyoff);
      setGstnumber(props.location.state.gstnumber);
      setGstStatus(props.location.state.gst_status);
      setCatlogPDF(props.location.state.menu_pdf);
      setLatitude(props.location.state.latitude);
      setLongitude(props.location.state.longitude);
      //{ props.location.state.catalogphotos == "undefined" || props.location.state.catalogphotos == 'null' || props.location.state.catalogphotos == '' || props.location.state.catalogphotos == null ? setCatalogphotos('') : setCatalogphotos(props.location.state.catalogphotos) };
      var catlog_img = props.location.state.catalogphotos;

      var array_catlog_img = [];
      { props.location.state.catalogphotos == "undefined" || props.location.state.catalogphotos == null || props.location.state.catalogphotos == [] ? array_catlog_img = [] : array_catlog_img = catlog_img };

      { props.location.state.catalogphotos == "undefined" || props.location.state.catalogphotos == 'null' || props.location.state.catalogphotos == null || props.location.state.catalogphotos == '' ? setCatalogphotos('') : setCatalogphotos(array_catlog_img) };
      // setFssaiImage(props.location.state.fssaicertificateurl);
      var fssai_img = props.location.state.fssaicertificateurl;
      var array_fssai_img = [];
      { props.location.state.fssaicertificateurl == "undefined" || props.location.state.fssaicertificateurl == null ? array_fssai_img = [] : array_fssai_img = fssai_img.split(',') };

      { props.location.state.fssaicertificateurl == "undefined" || props.location.state.fssaicertificateurl == 'null' || props.location.state.fssaicertificateurl == null || props.location.state.fssaicertificateurl == '' ? setFssaiImage('') : setFssaiImage(array_fssai_img) };
      // setLicenseUrl(props.location.state.licensephoto);
      var lic_img = props.location.state.licensephoto;
      var array_lic_img = [];
      { props.location.state.licensephoto == "undefined" || props.location.state.licensephoto == null ? array_lic_img = [] : array_lic_img = lic_img.split(',') };

      { props.location.state.licensephoto == "undefined" || props.location.state.licensephoto == 'null' || props.location.state.licensephoto == null || props.location.state.licensephoto == '' ? setLicenseUrl('') : setLicenseUrl(array_lic_img) };
      { props.location.state.lincenseno == "undefined" || props.location.state.lincenseno == 'null' || props.location.state.lincenseno == null || props.location.state.lincenseno == '' ? setLicenseNo('') : setLicenseNo(props.location.state.lincenseno) };
      setl3Id(props.location.state.categoryl3);
      setCanChequeurl(props.location.state.canchequeurl);
      setCommission_sd_per(props.location.state.commission_sd_per);
      setGst_on_commission_per(props.location.state.gst_on_commission_per);
      setCommission_per(props.location.state.commission_per);
      var bus_img = props.location.state.businessproofimg;
      var array_bus_img = [];
      { props.location.state.businessproofimg == "undefined" || props.location.state.businessproofimg == null ? array_bus_img = [] : array_bus_img = bus_img.split(',') };

      { props.location.state.businessproofimg == "undefined" || props.location.state.businessproofimg == 'null' || props.location.state.businessproofimg == null || props.location.state.businessproofimg == '' ? setBusinessProofPhotos('') : setBusinessProofPhotos(array_bus_img) };
      setShopLocation(props.location.state.shoplocation);

      setTeammembers(props.location.state.teammembers);
      let it = StatusData.find((item) => item.value === props.location.state.bizstatus);
      setBizStatus(it);
      setVerifyStatus(props.location.state.verifystatus)
      setRatings(props.location.state.ratings);
      setReferralCode(props.location.state.referralcode);
      setAccesscontrol(props.location.state.accesscontrol);
      setAccName(props.location.state.accname);
      setAccNumber(props.location.state.accnumber);
      setPicUrl(props.location.state.picurl);
      setCity(props.location.state.city)
      setPincode(props.location.state.pincode);
      setSubscriptionFlag(props.location.state.subscription_flag);
      setCurrentSubscriptionFlag(props.location.state.subscription_flag);
      setRenewalFlag(props.location.state.renewal_flag);

      // let l3 = []
      // let l4 = []
      // categoryData.map(children1 => {
      //   if(children1.children) {
      //     children1.children.map(children2 => {
      //     if(children2.children) {
      //       children2.children.map(children3 => {
      //         l3.push(children3)
      //       })
      //     }
      //     })
      //   }
      // })
      // let l3 = props.location.l3Data.map(item => console.log("aaa",item.id,props.location.state.categoryl3));
      console.log(props.location.l3Data + "jkll");
      let L1CategoryData = [];
      if (props.location.l1Data == undefined) {
        let check_l1 = localStorage.getItem('L1CategoryData');
        L1CategoryData = JSON.parse(check_l1);
      } else {
        L1CategoryData = [...props.location.l1Data];
        localStorage.setItem('L1CategoryData', JSON.stringify(L1CategoryData));
      }
      console.log(L1CategoryData);

      let l1cat = L1CategoryData.find((item) => item.id === props.location.state.categoryl1);
      setCategoryl1(l1cat.id);
      setL1Data(L1CategoryData);

      let L2CategoryData = [];
      // L2CategoryData([...l1cat.children]);
      // L2CategoryData(l1cat.children);
      let l2cat = l1cat.children.find((item) => item.id === props.location.state.categoryl2);
      setL2Data([...l1cat.children]);
      setCategoryl2(l2cat.id);
      let l3cat = l2cat.children.find((item) => item.id === props.location.state.categoryl3);
      setL3Sata([...l2cat.children]);
      setCategoryl3(l3cat.id);
      let l4cat = l3cat.children.find((item) => item.id === props.location.state.categoryl4);
      setL4Data([...l3cat.children]);
      setCategoryl4(l4cat.id);


      console.log(l1cat);

      console.log("props",props.location.state)

      console.log(props.location.l1Data + "jkll9000");
      // let L3CategoryData = [];
      // if (props.location.l3Data != undefined) {
      //   L3CategoryData = [...props.location.l3Data]
      //   localStorage.setItem('L3CategoryData', JSON.stringify(L3CategoryData));
      // }

      // L3CategoryData = localStorage.getItem('L3CategoryData');
      // L3CategoryData = JSON.parse(L3CategoryData);
      // console.log(L3CategoryData);
      // console.log(localStorage.getItem('L3CategoryData'));
      // console.log(props.location.state.categoryl3);

      // if (L3CategoryData) {
      //   let l3cat = L3CategoryData.find((item) => item.id === props.location.state.categoryl3);
      //   console.log(JSON.stringify(l3cat) + "l3cat");
      //   let l4arr = [];
      //   console.log(l3cat.children);
      //   if (l3cat.children) {
      //     l4arr.push(l3cat.children)
      //     let l4cat = l3cat.children.find((item) => item.id === props.location.state.categoryl4);
      //     console.log("rrr", props.location.state.categoryl4)
      //     if (l4cat != undefined) {
      //       setCategoryl4(l4cat.id);
      //       console.log("uiiii", l4cat.id);
      //     }

      //     setL4Data([...l3cat.children])
      //   }
      //   setCategoryl3(l3cat.id);
      //   console.log(l3cat.id);
      // }
      // setKycDocumentNumber(props.location.state.kycdocumentnumber);
      setAccVerifyStatus(props.location.state.accverifystatus);
      setCategoryName(props.location.state.l1_categoryname + ',' + props.location.state.l2_categoryname + ',' + props.location.state.l3_categoryname + ',' + props.location.state.l4_categoryname)
      var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      //First of month
      // if(props.location.state.weeklyoff==='7')
      // {
      //   var dayName='';
      //   for(var i=0;i<days.length;i++)
      //   {
      //     if(i!=days.length-1)
      //     {
      //       dayName+= days[i]+",";
      //     }
      //     else{
      //       dayName+= days[i];
      //     }
      //   }
      // }else{
      // var dayName= days[props.location.state.weeklyoff];
      // }
      // setWeeklyOff(dayName);
      setMarketName(props.location.state.mandyorareaormarket);
      if (props.location.state.storedescription === "null") {
        setStoreDescription();
      } else {
        setStoreDescription(props.location.state.storedescription);
      }
      if (props.location.state.kycdocumenttype === "null") {
        setkycDocumentType('');
      } else {
        setkycDocumentType(props.location.state.kycdocumenttype);
      }
      if (props.location.state.kycdocumentnumber === "null") {
        setKycDocumentNumber('');
      } else {
        setKycDocumentNumber(props.location.state.kycdocumentnumber);
      }
      if (props.location.state.businessprooftype === "null") {

        setBusinessProofType('');
      } else {
        setBusinessProofType(props.location.state.businessprooftype);
      }
      if (props.location.state.businessproofnumber === "null") {
        setBusinessProofNumber('');
      } else {
        setBusinessProofNumber(props.location.state.businessproofnumber);
      }

      if (props.location.state.businessproofexpiry === "null") {
        setBusinessProofExpiry();
      } else {
        setBusinessProofExpiry(props.location.state.businessproofexpiry);
      }
      if (props.location.state.businessproof === "null") {
        setBusinessProof("");
      } else {
        setBusinessProof(props.location.state.businessproof);
      }
    }
    getCategoryTree();
  }, [props]);
  const handleDownload = (url) => {
    let url1 = url;
    let image_type = url1.split(/[#?]/)[0].split('.').pop().trim();
    let newimge = "image" + "." + image_type;
    saveAs(url1, newimge);
  }
  const handleSubmit = async () => {
    setIsLoading(true);
    console.log('checking');
    let data = {
      bizStatus: bizStatus ? bizStatus.value : '',
      ownerAadharNumber: ownerAadharNumber,
      gstNumber: gstNumber,
      catalogPhotos: catalogPhotos,
      teamMembers: teamMembers,
      verifyStatus: verifyStatus,
      ratings: ratings,
      teamMobileNumbers: teamMobileNumbers,
      referralCode: referralCode,
      kycDocumentType: kycDocumentType,
      kycDocumentNumber: kycDocumentNumber,
      businessProofType: businessProofType,
      businessProofExpiry: businessProofExpiry,
      buisnessProof: businessProof,
      buisnessProofImg: businessProofPhotos != null ? businessProofPhotos : "",
      storeDescription: storeDescription,
      accessControl: accessControl,
      merchantId: id,
      accVerifyStatus: accVerifyStatus,
      businessProofNumber: businessProofNumber,
      merchantName: merchantName,
      categoryl1: categoryl1,
      categoryl2: categoryl2,
      categoryl3: categoryl3,
      categoryl4: categoryl4,
      shopName: shopName,
      closeTime: closeTime,
      openTime: openTime,
      closeTime2: closeTime2,
      openTime2: openTime2,
      addressLine1: addressLine1,
      weeklyOff: weeklyOff,
      pincode: pincode,
      picurl: picUrl,
      city: city,
      bankName: bankName,
      bankIfsc: bankIfsc,
      accName: accName,
      accNumber: accNumber,
      addressLine2: addressLine2,
      shopLocation: shopLocation,
      mandyOrAreaOrMarket: MarketName,
      fssai: fssaiImage,
      licenseUrl: licenseUrl,
      photo_qc: photo_qc,
      license_qc: license_qc,
      bank_qc: bank_qc,
      business_qc: business_qc,
      canChequeurl: canChequeurl,
      classification: classification,
      commission_per: commission_per,
      gst_on_commission_per: gst_on_commission_per,
      commission_sd_per: commission_sd_per,
      gstStatus: gstStatus,
      child_mob_1: Child_mob_1,
      child_mob_2: Child_mob_2,
      catalogPdf: catalogPdf,
      latitude: latitude,
      longitude: longitude,
      lincenseno: licenseNo,
      logged_mobile_num: adminPhoneNumber,
      subscription_flag: subscriptionPlan,
      renewal_flag: renewalFlag,
    };
    console.log(data);
    updateMerchant(data);
  };
  const updateMerchant = async (data) => {
    await MerchantService.updateMerchant(data)
      .then(async (res) => {
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              let result = JSON.parse(res);
              console.log(result.data.errorMsg);
              if (result.code === 0 && result.message == "Success" && (result.data.errorMsg === undefined || result.data.errorMsg === "Updated")) {
                setIsLoading(false);
                alertSuccessMessageMerchant(`${result.message}`);
                //props.handleRefresh();
                props.history.push("/merchant");


              } else if (result.code === 0 && result.data.errorMsg !== undefined) {
                setIsLoading(false);
                alertDangerConfirmMessage(`${result.data.errorMsg}`);
              } else {
                setIsLoading(false);
                alertDangerConfirmMessage(`${result.message}`);
              }
            });
          } catch (err) {
            console.log(err);
            setIsLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }
  const getCategoryTree = async () => {
    await CategoryService.getCategoryTree()
      .then(async (res) => {
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              let result = JSON.parse(res);
              if (result.code === 0) {
                let array = []
                {
                  result.data && result.data.map(item => {
                    {
                      item.children && item.children.map(it => {
                        {
                          if (it.id === props.location.state.categoryl2) {
                            it.children && it.children.map(data => {
                              //console.log("meow", data.id)

                              {

                                data &&
                                  array.push(data)

                              }
                            })
                          }
                        }
                      })
                    }
                  })
                }
                setL3Sata([...array]);
              } else {
                console.log(result.message);
              }
            })
          } catch (err) {
            console.log(err);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const timeData = ['am','pm'];
  return (


    <Container fluid>
      <div>
        {isLoading ? <Loader /> : ''}
        <Row>
          <Col xs={2} id="sidebar-wrapper">
            <Sidebar />
          </Col>
          <Col xs={10} id="page-content-wrapper">
            <div className="tittle">
              <h1>Merchant Edit</h1>
            </div>
            <div className="editMerchent">
              <label className="editLabel">Business Name :</label>
              <input type="text" className="editInput" defaultValue={shopName} onChange={handleShopName} readOnly></input>
              <label className="editLabel">Merchant Aadhar :</label>
              <input type="number" className="editInput" defaultValue={ownerAadharNumber} onChange={handleOwnerAadharNumber} ></input>
              <label className="editLabel">Sec Mobile No 1 :</label>
              <input type="text" className="editInput" defaultValue={Child_mob_1} onChange={handleSecondaryMobileNumbers1} maxlength="10"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}></input>
              <label className="editLabel">Sec Mobile No 2 :</label>
              <input type="text" className="editInput" defaultValue={Child_mob_2} onChange={handleSecondaryMobileNumbers2} maxlength="10"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}></input>
              <label className="editLabel">GST Number :</label>
              <input type="text" className="editInput" defaultValue={gstNumber} onChange={handleGstNumber} maxlength="15" style={{ textTransform: 'uppercase' }}></input>
              <label className="editLabel">GST Status :</label>
              <select v-model="selected" className="editInput" value={gstStatus} onChange={handlegstStatus}>
                {GstStatus.map(data => (
                  <option key={data.id} value={data.value} disabled={data.disabled} >
                    {data.option}
                  </option>
                ))}
              </select>              {l3Id == "8e827a10-737b-11ec-9806-93163a488dc9" || l3Id == "c205e5d0-737f-11ec-9806-93163a488dc9" || l3Id == "57991b70-7381-11ec-9806-93163a488dc9" ?
                <label className="editLabel">Catalog Photos ( jpg, jpeg, png ):</label> : ''}
              {/* <input type="text" className="editInput"  defaultValue={catalogPhotos} onChange={handleCatalogPhotos} ></input> */}
              {l3Id == "8e827a10-737b-11ec-9806-93163a488dc9" || l3Id == "c205e5d0-737f-11ec-9806-93163a488dc9" || l3Id == "57991b70-7381-11ec-9806-93163a488dc9" ?

                <FormGroup row className="mx-0 align-items-left justify-content-left">
                  <Col
                    xs={4}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}
                    className="d-flex"
                  >
                    {!catalogPhotos ? (
                      <FileUploader
                        catalogImg={true}
                        getCatalogImg={(item) =>
                          getCatalogImg(item)
                        }
                        isEditable={false}
                      />
                    )
                      :
                      (
                        <div style={{ width: '100%' }}>
                          {catalogPhotos && catalogPhotos.map((imageurl) => (
                            <img

                              src={`${isFileChanged ? `${imageurl}` : `${imageurl}`}`}
                              alt={""}
                              className="w-50 mt-3 d-block"

                            />
                          ))}
                        </div>
                      )
                    }
                    {catalogPhotos ? (
                      <FileUploader
                        catalogImg={true}
                        getCatalogImg={(item) =>
                          getCatalogImg(item)
                        }
                        isEditable={true}

                      />
                    ) : null
                    }
                  </Col>
                </FormGroup>
                :
                ''}
              {l3Id == "8e827a10-737b-11ec-9806-93163a488dc9" || l3Id == "c205e5d0-737f-11ec-9806-93163a488dc9" || l3Id == "57991b70-7381-11ec-9806-93163a488dc9" ?
                <label className="editLabel">Catalog PDF:</label> : ''}
              {catalogPdf != '' && catalogPdf != 'null' && catalogPdf != null ? <p style={{ display: 'inline-flex', lineBreak: 'anywhere' }}>{catalogPdf} </p> : ''}
              <div style={{marginBottom:'2%'}}>
                
                <div style={{ display: 'inline-block', width: '20%' }}>
                  {catalogPdf != '' && catalogPdf != 'null' && catalogPdf != null ? <button onClick={() => handleDownload(catalogPdf)} style={{ borderRadius: '4px',lineHeight: '41px',width:'90%' }}> Download PDF</button> : ''}
                </div>
                <div style={{ display: 'inline-block', width: '30%' }}>
                  {l3Id == "8e827a10-737b-11ec-9806-93163a488dc9" || l3Id == "c205e5d0-737f-11ec-9806-93163a488dc9" || l3Id == "57991b70-7381-11ec-9806-93163a488dc9" ?
                    <FileUploader
                      newPdf={true}
                      handleNewPDF={(item) =>
                        handleNewPDF(item)
                      }
                      isEditable={false}
                    /> : ''}
                </div>
</div>
                <label className="editLabel">Mandi or Area or Market</label>
                <input type="text" className="editInput" style={{ textTransform: 'capitalize' }} defaultValue={MarketName} onChange={handleMaketName} ></input>
                <label className="editLabel">Shop Location</label>
                <input type="text" className="editInput" defaultValue={shopLocation} onChange={handleShopLocation} ></input>
                {l3Id == "8e827a10-737b-11ec-9806-93163a488dc9" ?
                  <label className="editLabel">Fssai certificate image ( jpg, jpeg, png ):</label>
                  : ''}
                {l3Id == "8e827a10-737b-11ec-9806-93163a488dc9" ?
                  // <FormGroup row className="mx-0 align-items-left justify-content-left">
                  //   <Col
                  //     xs={4}
                  //     sm={4}
                  //     md={4}
                  //     lg={4}
                  //     xl={4}
                  //     className="d-flex px-0 py-2 justify-content-center align-items-center pb-3"
                  //   >
                  //     {!fssaiImage ? (
                  //       <FileUploader
                  //         fssaiImage={true}
                  //         getFssaiImage={(item) =>
                  //           getFssaiImage(item)
                  //         }
                  //         isEditable={false}
                  //       // isDefault={isDefaultThumbnail}
                  //       />
                  //     )
                  //       :
                  //       // null
                  //       (
                  //         <>
                  //           <img
                  //             src={`${isFssaiFileChanged ? `${fssaiImage}` : `${fssaiImage}`}`}
                  //             alt={""}
                  //             className="w-50"
                  //           />
                  //         </>
                  //       )
                  //     }
                  //     {fssaiImage ? (
                  //       <FileUploader
                  //         fssaiImage={true}
                  //         getFssaiImage={(item) =>
                  //           getFssaiImage(item)
                  //         }
                  //         isEditable={true}

                  //       />
                  //     ) : null
                  //     }
                  //   </Col>
                  // </FormGroup>
                  <FormGroup row className="mx-0 align-items-left justify-content-left">
                    <Col
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={4}
                      className="d-flex"
                    >
                      {!fssaiImage ? (
                        <FileUploader
                          fssaiImage={true}
                          getFssaiImage={(item) =>
                            getFssaiImage(item)
                          }
                          isEditable={false}
                        />
                      )
                        :
                        (
                          <div style={{ width: '100%' }}>
                            {fssaiImage && fssaiImage.map((imageurl) => (
                              <img

                                src={`${isFssaiFileChanged ? `${imageurl}` : `${imageurl}`}`}
                                alt={""}
                                className="w-50 mt-3 d-block"

                              />
                            ))}
                          </div>
                        )
                      }
                      {fssaiImage ? (
                        <FileUploader
                          fssaiImage={true}
                          getFssaiImage={(item) =>
                            getFssaiImage(item)
                          }
                          isEditable={true}

                        />
                      ) : null
                      }
                    </Col>
                  </FormGroup>
                  : ''}
                {l3Id == "e9a556c0-738e-11ec-9806-93163a488dc9" ?
                  <label className="editLabel">License Image ( jpg, jpeg, png ):</label>
                  : ''}
                {l3Id == "e9a556c0-738e-11ec-9806-93163a488dc9" ?

                  <FormGroup row className="mx-0 align-items-left justify-content-left">
                    <Col
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={4}
                      className="d-flex"
                    >
                      {!licenseUrl ? (
                        <FileUploader
                          licenseUrl={true}
                          getLicenseImage={(item) =>
                            getLicenseImage(item)
                          }
                          isEditable={false}
                        />
                      )
                        :
                        (
                          <div style={{ width: '100%' }}>
                            {licenseUrl && licenseUrl.map((imageurl) => (
                              <img

                                src={`${isLicenseFileChanged ? `${imageurl}` : `${imageurl}`}`}
                                alt={""}
                                className="w-50 mt-3 d-block"

                              />
                            ))}
                          </div>
                        )
                      }
                      {licenseUrl ? (
                        <FileUploader
                          licenseUrl={true}
                          getLicenseImage={(item) =>
                            getLicenseImage(item)
                          }
                          isEditable={true}

                        />
                      ) : null
                      }
                    </Col>
                  </FormGroup>

                  : ''}

                {l3Id == "8e827a10-737b-11ec-9806-93163a488dc9" || l3Id === '57991b70-7381-11ec-9806-93163a488dc9' || l3Id === 'c50ae540-737b-11ec-9806-93163a488dc9' || l3Id === '3c6935d0-7c62-11ec-90de-8dac060fa840' || l3Id === '1ebfc1c0-7389-11ec-9806-93163a488dc9' || l3Id === 'c205e5d0-737f-11ec-9806-93163a488dc9' || l3Id === 'b6541c60-737b-11ec-9806-93163a488dc9' || l3Id === 'd3c44510-7c61-11ec-90de-8dac060fa840' || l3Id === '637fad50-7381-11ec-9806-93163a488dc9' ?
                  <label className="editLabel">Fssai license number :</label>
                  : ''}
                {l3Id == "8e827a10-737b-11ec-9806-93163a488dc9" || l3Id === '57991b70-7381-11ec-9806-93163a488dc9' || l3Id === 'c50ae540-737b-11ec-9806-93163a488dc9' || l3Id === '3c6935d0-7c62-11ec-90de-8dac060fa840' || l3Id === '1ebfc1c0-7389-11ec-9806-93163a488dc9' || l3Id === 'c205e5d0-737f-11ec-9806-93163a488dc9' || l3Id === 'b6541c60-737b-11ec-9806-93163a488dc9' || l3Id === 'd3c44510-7c61-11ec-90de-8dac060fa840' || l3Id === '637fad50-7381-11ec-9806-93163a488dc9' ?
                  <input type="text" className="editInput" maxLength={15} defaultValue={licenseNo} onChange={handleLicenseNo} ></input>
                  : ''}

                <label className="editLabel">Team Members :</label>
                <input type="text" className="editInput" defaultValue={teamMembers} onChange={handleTeamMembers} ></input>
                <label className="editLabel">Merchant Name :</label>
                <input type="text" className="editInput" defaultValue={merchantName} style={{ textTransform: 'capitalize' }} onChange={handleMerchantName} ></input>
                <label className="editLabel">Referal code :</label>
                <input type="text" className="editInput" defaultValue={referralCode} onChange={handleRerralCode} ></input>
                <label className="editLabel">Account Verify Status :</label>
                <input type="text" className="editInput" defaultValue={accVerifyStatus} onChange={handleAccVerifyStatus} ></input>
                <label className="editLabel">Ratings :</label>
                <input type="text" className="editInput" defaultValue={ratings} onChange={handleRatings} ></input>


                <label className="editLabel">Business Proof  :</label>


                <select v-model="selected" className="editInput" value={businessProof} onChange={handleBusinessProof}>
                  {BusinessProof.map(data => (
                    <option key={data.id} value={data.value} >
                      {data.name}
                    </option>
                  ))}
                </select>
                <label className="editLabel">Classification  :</label>
                <select v-model="selected" className="editInput" value={classification} onChange={handleClassification}>
                  {ClassificationSelect.map(data => (
                    <option key={data.id} value={data.value} disabled={data.disabled} >
                      {data.value}
                    </option>
                  ))}
                </select>


                <label className="editLabel">Business Proof Img ( jpg, jpeg, png ):</label>

                <FormGroup row className="mx-0 align-items-left justify-content-left">
                  <Col
                    xs={4}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}
                    className="d-flex"
                  >
                    {!businessProofPhotos ? (
                      <FileUploader
                        isBusinessProofPhotos={true}
                        getBusinessProofPhotos={(item) =>
                          getBusinessProofPhotos(item)
                        }
                        isEditable={false}
                      />
                    )
                      :
                      (
                        <div style={{ width: '100%' }}>
                          {businessProofPhotos && businessProofPhotos.map((imageurl) => (
                            <img

                              src={`${isFileChanged ? `${imageurl}` : `${imageurl}`}`}
                              alt={""}
                              className="w-50 mt-3 d-block"

                            />
                          ))}
                        </div>
                      )
                    }
                    {businessProofPhotos ? (
                      <FileUploader
                        isBusinessProofPhotos={true}
                        getBusinessProofPhotos={(item) =>
                          getBusinessProofPhotos(item)
                        }
                        isEditable={true}

                      />
                    ) : null
                    }
                  </Col>
                </FormGroup>

                {/* Business Proof Img end */}

                <label className="editLabel">Store description  :</label>
                <input type="text" className="editInput" defaultValue={storeDescription} onChange={handleStoreDescription} ></input>
                <label className="editLabel">Access Control  :</label>
                <input type="text" className="editInput" defaultValue={accessControl} onChange={handleAccessControl} ></input>
                <label className="editLabel">Commission Percentage :</label>
                <input type="text" className="editInput" onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }} defaultValue={commission_per} onChange={handleCommissionPer} ></input>
                <label className="editLabel">Gst Commission Percentage :</label>
                <input type="text" className="editInput" onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }} defaultValue={gst_on_commission_per} onChange={handleGst_on_commission_per} ></input>
                <label className="editLabel">Commission Self Delivery Percentage :</label>
                <input type="text" className="editInput" onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }} defaultValue={commission_sd_per} onChange={handleCommission_sd_per} ></input>
                <label className="editLabel">Category L1  :</label>
                <select v-model="selected" className="editInput" value={categoryl1} onChange={handleCategoryL1}>
                  {l1Data.map(item => (
                    <option value={item.id} >
                      {item.name}
                    </option>
                  ))}
                </select>
                <label className="editLabel">Category L2  :</label>
                <select v-model="selected" className="editInput" value={categoryl2} onChange={handleCategoryL2}>
                  {l2Data.map(item => (
                    <option value={item.id} >
                      {item.name}
                    </option>
                  ))}
                </select>
                <label className="editLabel">Category L3  :</label>
                {/* <input type="text" className="editInput"  defaultValue={categoryl3} onChange={handleCategoryL3} ></input> */}
                <select v-model="selected" className="editInput" value={categoryl3} onChange={handleCategoryL3}>
                  {l3Data.map(item => (
                    <option value={item.id} >
                      {item.name}
                    </option>
                  ))}
                </select>
                <label className="editLabel">Category L4  :</label>
                {/* {categoryl3 && l3Data.map(item => {
                          if(item.id === categoryl3) {
                            if(item.children){
                              return( */}
                <select v-model="selected" className="editInput" value={categoryl4} onChange={handleCategoryL4}>
                  {console.log("l4Data", l4Data)}
                  {l4Data.map(l4 => (
                    <option key={l4.id} value={l4.id} >
                      {/* {/* {console.log("item.children",l4.name)} */}
                      {l4.name}
                    </option>
                  ))}
                </select>
                {/* }
                          }
                        })} */}
                {/* <input type="text" className="editInput"  defaultValue={categoryl4} onChange={handleCategoryL4} ></input> */}
                <label className="editLabel">Open Time 1 :</label>
                {/* <div style={{display: "flex", flexDirection: "row"}}> */}
                {/* <TimePicker className="time-picker editInput" clearIcon={null} disableClock clockIcon={null} value={timeValue} /> */}
                <input type="text" className="editInput" defaultValue={openTime} onChange={handleOpenTime} ></input>
                {/* <select style={{height: "25px"}} value={timeData[0]}>
                  {timeData.map((value) => (
                    <option value={value} key={value}>{value}</option>
                  ))}
                </select> */}
                {/* </div> */}
                <label className="editLabel">Close Time 1 :</label>
                <input type="text" className="editInput" defaultValue={closeTime} onChange={handleCloseTime} ></input>
                <label className="editLabel">Open Time 2  :</label>
                <input type="text" className="editInput" defaultValue={openTime2} onChange={handleOpenTime2} ></input>
                <label className="editLabel">Close Time 2  :</label>
                <input type="text" className="editInput" defaultValue={closeTime2} onChange={handleCloseTime2} ></input>
                <label className="editLabel">Address Line 1  :</label>
                <input type="text" className="editInput" defaultValue={addressLine1} onChange={handleAddressLine1} ></input>
                <label className="editLabel">Address Line 2  :</label>
                <input type="text" className="editInput" defaultValue={addressLine2} onChange={handleAddressLine2} ></input>
                <label className="editLabel">Latitude  :</label>
                <input type="text" className="editInput" defaultValue={latitude} onChange={handleLatitude} ></input>
                <label className="editLabel">Longitude  :</label>
                <input type="text" className="editInput" defaultValue={longitude} onChange={handleLongitude} ></input>
                <label className="editLabel">Subscription Plan  :</label>
                <select v-model="selected" className="editInput" value={subscriptionPlan} onChange={handleSubscription} disabled={currentSubscriptionPlan === 0 ? false : true}>
                    {subscriptionEvents.map(item => (
                      <option value={item.id} >
                        {item.name}
                      </option>
                    ))}
                </select>
                <label className="editLabel">Renewal Flag  :</label>
                <select v-model="selected" className="editInput" value={renewalFlag} onChange={handleRenewalFlag}>
                  {renewalFlagData.map(item => (
                    <option value={item.id} >
                      {item.value}
                    </option>
                  ))}
                </select>
                {console.log("renewalFlag==>", renewalFlag)}
                {console.log("subFlag==>", subscriptionPlan)}
                <label className="editLabel">Weekly Off :</label>
                {/* <input type="text" className="editInput"  defaultValue={weeklyOff} onChange={handleWeeklyOff} ></input> */}
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={weeklyOff}
                  onChange={handleWeeklyOff}
                >
                  <FormControlLabel value="0" control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 20, }, }} />} label={<Typography className={classes.formControlLabel}>Sunday</Typography>} />
                  <FormControlLabel value="1" control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 20, }, }} />} label={<Typography className={classes.formControlLabel}>Monday</Typography>} />
                  <FormControlLabel value="2" control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 20, }, }} />} label={<Typography className={classes.formControlLabel}>Tuesday</Typography>} />
                  <FormControlLabel value="3" control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 20, }, }} />} label={<Typography className={classes.formControlLabel}>Wednesday</Typography>} />
                  <FormControlLabel value="4" control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 20, }, }} />} label={<Typography className={classes.formControlLabel}>Thursday</Typography>} />
                  <FormControlLabel value="5" control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 20, }, }} />} label={<Typography className={classes.formControlLabel}>Friday</Typography>} />
                  <FormControlLabel value="6" control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 20, }, }} />} label={<Typography className={classes.formControlLabel}>Saturday</Typography>} />
                  <FormControlLabel value="7" control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 20, }, }} />} label={<Typography className={classes.formControlLabel}>All</Typography>} />

                </RadioGroup>
                <label className="editLabel">Pin Code :</label>
                <input type="text" className="editInput" defaultValue={pincode} onChange={handlePincode} ></input>
                <label className="editLabel">Pic Url ( jpg, jpeg, png ):</label>
                {/* <input type="text" className="editInput"  defaultValue={picUrl} onChange={handlePicUrl} ></input> */}
                <FormGroup row className="mx-0 align-items-left justify-content-left">
                  <Col
                    xs={4}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}
                    className="d-flex px-0 py-2 justify-content-center align-items-center pb-3"
                  >
                    {!picUrl ? (
                      <FileUploader
                        isImg={true}
                        getThumbnail={(item) =>
                          getThumbnail(item)
                        }
                        isEditable={false}
                      // isDefault={isDefaultThumbnail}
                      />
                    )
                      :
                      // null
                      (
                        <>
                          <img
                            src={`${isFileChanged ? `${picUrl}` : `${picUrl}`}`}
                            alt={""}
                            className="w-50"
                          />
                        </>
                      )
                    }
                    {picUrl ? (
                      <FileUploader
                        isImg={true}
                        getThumbnail={(item) =>
                          getThumbnail(item)
                        }
                        isEditable={true}

                      />
                    ) : null
                    }
                  </Col>
                </FormGroup>
                <label className="editLabel">City :</label>

                <input type="text" className="editInput" defaultValue={city} onChange={handleCity} ></input>
                {verifyStatus != 0 ?
                  <label className="editLabel">Bank Name :</label>
                  : ''}
                {verifyStatus != 0 ?
                  <input type="text" className="editInput" defaultValue={bankName} onChange={handleBankName} ></input> : ''}
                {verifyStatus != 0 ?
                  <label className="editLabel">Bank IFSC :</label>
                  : ''}
                {verifyStatus != 0 ?
                  <input type="text" className="editInput" defaultValue={bankIfsc} onChange={handleBankIfsc} ></input>
                  : ''}
                {verifyStatus != 0 ?
                  <label className="editLabel">Account holder Name :</label>
                  : ''}
                {verifyStatus != 0 ?
                  <input type="text" className="editInput" defaultValue={accName} onChange={handleAccName} ></input>
                  : ''}
                {verifyStatus != 0 ?
                  <label className="editLabel">Account Number :</label>
                  : ''}
                {verifyStatus != 0 ?
                  <input type="text" className="editInput" defaultValue={accNumber} onChange={handleAccNumber} ></input>
                  : ''}
                {/* <label className="editLabel">Verification Status :</label>
              <input type="text" className="editInput" defaultValue={verifyStatus} onChange={handleVerifyStatus} ></input> */}
              {(currentSubscriptionPlan >= 2) && 
              <div className="freeDeliveries">
                <label className="editLabel">Add Free Deliveries :</label>
                <div className="deliveryFlex">
                <input className="addFreeDeliveries" type="number" onChange={(e) => setFreeDelivery(e.target.value)}></input>
                <button className="addFreeDeliveryButton" onClick={addFreeDelivery}>Add</button>
                </div>
                
              </div>}
                <div className="editButton">
                  <label className="editLabel">Merchant Status :</label>
                  <Select
                    value={bizStatus}
                    onChange={handleStatus}
                    options={StatusData}
                    placeholder={"Select"}
                    classNamePrefix="common-select-drop-down"
                    isSearchable={false}
                    isClearable
                  />
                  <Row>

                    <Col xs={6} sm={6} md={6} lg={6} xl={6} className='pt-2'>
                      <Button color="secondary"
                        className="btn btn-block custom-danger-btn custom-btn--small" onClick={handleSumbitCancel}>Cancel</Button>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6} className='pt-2'>
                      <Button color="success"
                        className="btn btn-block custom-theme-btn custom-btn--small" onClick={() => handleSubmit()}>Update</Button>
                    </Col>
                  </Row>
                </div>

              </div>
          </Col>
{console.log("classification========>", classification)}
        </Row>
      </div>
    </Container>

  );
};
export default Editdetails;