import React, { useState, useEffect, useRef } from 'react';
import { Container } from "reactstrap";
import { Row, Col } from "react-bootstrap";
import "../styles/deliveryDashboard.css"
import Sidebar from "../components/sidebar.js";
import { TextField } from "@mui/material";
import axios from 'axios';
import Moment from "react-moment";
import { APIConfig } from "../api/apiConfig/apiConfig";
import { Typography } from '@material-ui/core';


const DeliveryDashboard = () => {
    const { baseUrl, deliveryOrderDetails, nearbyDeliveryAgents, assignDeliveryAgents } = APIConfig;
    const [orderID, setOrderID] = useState("");
    const [response1, setResponse1] = useState({});
    const [timeData, setTimeData] = useState({});
    const [response2, setResponse2] = useState([]);
    const [nearbyAgentFlag, setnearbyAgentFlag] = useState(0);
    const dropDownRef = useRef(null);
    
    const constantmessage = {
        merchantNotExist: "Merchant not exist",
        releasingAgentFailed: "Releasing old agent failed",
        selfDelivery: "Self delivery",
        loadShare: "Loadshare",
        delivered: "Delivered",
        orderIDNotFound: "Order ID is not found",
        orderNotAssigned: "This order is not assigned to anyone",
        merchantNotFound: "Merchant details not found",
        agentNotFound: "No nearby delivery agents found",
        releasingAgentFailed: "Releasing current Agent failed",
        assigningAgentFailed: "Assigning to new agent failed",
        assigningFailed: "Assigning Failed",
        success: "Success",
        noNearbyAgent: 1,
        agentRole: 2,
    }

    const getDeliveryOrderDetails = async() => {
        await axios
        .post(baseUrl + deliveryOrderDetails,{
            order_id : orderID
        })
        .then((response)=>{
            var response1Status = response.data.status;
            if (response1Status == 1) {
                alert(constantmessage.orderIDNotFound);
            } else {
                setTimeData(response.data.data);
                var dataResponse1 = response.data.response;
                setResponse1(dataResponse1);
                if (dataResponse1.AssignedTo != null ) {
                    getNearbyDeliveryAgents(orderID);
                } else{
                    getNearbyDeliveryAgents(orderID);
                    alert(constantmessage.orderNotAssigned);
                }
            }
            
        })
    }

    async function getNearbyDeliveryAgents(orderId) {
        await axios
        .post(baseUrl + nearbyDeliveryAgents,{
            orderId : orderId
        })
        .then((response)=>{
            var response2Status = response.data.status;
            if (response2Status == 1) {
                if (response.data.message == constantmessage.merchantNotExist) {
                    setnearbyAgentFlag(1);
                    alert(constantmessage.merchantNotFound);
                } else {
                    setnearbyAgentFlag(1);
                    alert(constantmessage.agentNotFound);
                }
            } else {
                var dataResponse2 = response.data.data;
                setResponse2(dataResponse2);
            }
            
        })
    }


    const assignDeliveryAgent = async () => {
        var selectedNumber = dropDownRef.current.value;
        var regex = /\|\|([^|]+)\|\|/;
        // Use match to find the value between || and ||
        var match = selectedNumber.match(regex);
        // If a match is found, return the captured value, otherwise return null
        var phoneNumber = match ? match[1].trim() : null;
        console.log(phoneNumber);
        await axios
        .post(baseUrl + assignDeliveryAgents,{
            orderId : orderID,
            mobNumber: phoneNumber
        })
        .then((response)=>{
            var assignResponseStatus = response.data.status;
            if (assignResponseStatus == 1) {
                var assignResponseMessage = response.data.message;
                if (assignResponseMessage == constantmessage.releasingAgentFailed) {
                    alert(constantmessage.releasingAgentFailed);
                } else {
                    alert(constantmessage.assigningAgentFailed);
                }
            } else {
                alert(constantmessage.success);
                window.location.reload();
            }
            
        })
        .catch(function (error) {
            console.log(error);
            alert(constantmessage.assigningFailed);
        });
    }

    

    return (

        <Container fluid>
            <Row>
                <Col xs={2} id="sidebar-wrapper">
                    <Sidebar />
                </Col>
                <Col xs={10} id="page-content-wrapper">
                    <div className='page_container'>
                        <div className='mainContainer'>
                            <div className='left'>
                                <div className='textFieldStyle'>
                                    <TextField className='textBoxStyle' 
                                        type='number'
                                        label="Enter Order ID" 
                                        onChange={(e) => setOrderID(e.target.value)}/>
                                </div>
                                <div>
                                    <button className='searchButton' onClick={getDeliveryOrderDetails}>Search</button>
                                </div>
                            </div>
                            {Object.keys(response1).length !== 0 && (
                                <div className='right'>

                                <div className='box-border'>
                                    <Typography>Order Type: {Object.keys(response1).length !== 0 && response1.orderType}</Typography>
                                </div>
                                <div className='box-border'>
                                    <Typography>Order Status: {Object.keys(response1).length !== 0 && response1.orderStatus}</Typography>
                                    <div className='all-box-container'>
                                        <div className='time'>
                                        {timeData.cb_allocation_time != null &&
                                        (timeData.cb_allocation_time).length !== 0 && 
                                        <Typography>Assigned Time: {timeData.cb_allocation_time}</Typography> }
                                        {timeData.cb_arrived_time != null &&
                                        (timeData.cb_arrived_time).length !== 0 && 
                                        <Typography>Arrived Time: {timeData.cb_arrived_time}</Typography>}
                                        {timeData.cb_picked_up_time != null &&
                                        (timeData.cb_picked_up_time).length !== 0 && 
                                        <Typography>Picked Up Time: {timeData.cb_picked_up_time}</Typography>}
                                        {timeData.cb_reached_time != null &&
                                        (timeData.cb_reached_time).length !== 0 && 
                                        <Typography>Reached Destination Time: {timeData.cb_reached_time}</Typography>}
                                        {timeData.cb_delivered_time != null &&
                                        (timeData.cb_delivered_time).length !== 0 && 
                                        <Typography>Delivered Time: {timeData.cb_delivered_time}</Typography>}
                                        </div>
                                        <div className='box'>
                                        <Typography>{Object.keys(response1).length !== 0 && response1.orderType}</Typography>
                                        <Typography>Order Status: {Object.keys(response1).length !== 0 && response1.orderStatus}</Typography>
                                        </div>
                                        
                                    </div>
                                </div>
                                {(response1.orderType !== constantmessage.selfDelivery && response1.AssignedTo !== null) &&
                                    (
                                    <div className='box-border'>
                                    <Typography>Assigned To: {Object.keys(response1).length !== 0 && response1.AssignedTo}  {Object.keys(response1).length !== 0 && response1.riderName}  {Object.keys(response1).length !== 0 && response1.riderNumber}</Typography>
                                </div>
                                )}
                                {(response1.orderStatus !== constantmessage.delivered) && 
                                    (response1.AssignedTo !== constantmessage.loadShare) &&
                                    (nearbyAgentFlag !== constantmessage.noNearbyAgent) &&
                                    (
                                    <div className='changeRider box-border'>
                                    <div>
                                        <Typography>Change Rider:</Typography>
                                    </div>
                                    <div className='dropDown'>
                                        <select ref={dropDownRef}>
                                        {response2.map((data, index) => (
                                            <option key={index}>
                                            {data.working_role === constantmessage.agentRole ? 'DP' : 'BE'} -- {data.name} || {data.user_mob_num} || {(data.distance/1000).toFixed(3)} Km
                                            </option>
                                        ))}
                                        </select>
                                    </div>
                                    <div>
                                        <button className='searchButton' onClick={assignDeliveryAgent}>Submit</button>
                                    </div>

                                </div>
                                )}
                                

                            </div>
                            )}
                            
                        </div>



                    </div>

                </Col>
            </Row>
        </Container>
    );
};

export default DeliveryDashboard;