import React, { useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import {Container } from "reactstrap";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Row, Col, Card, Form } from "react-bootstrap";
import EditCategory from "./customComponent/EditCategory";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from '@mui/material';
import { APIConfig }  from "../api/apiConfig/apiConfig";
import CategoryService from "../api/services/CategoryService";
import { useHistory } from "react-router-dom";
import AwsService from '../../src/api/services/AwsImageService';
import Sidebar from "./sidebar.js";
import '../Dashboard.css'
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@mui/material/TextField";
import {
  alertDangerConfirmMessage,
  alertSuccessMessage,
} from "../components/customComponent/ConfirmationMessage/ConfirmationMessage";

const Home = (props) => {
  // const history = useHistory();
  // if( window.sessionStorage.getItem('sessionId')==undefined)
  // {
  //   history.push("/");
  // }
  const [data, setData] = useState([]);
  const [editPopup, setEditPopup] = useState(false);
  const [category, setCategory] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [parent_id, setParent_id] = useState("");
  const [categoryType, setCategoryType] = useState("");
  const [addOrUpdate, setAddOrUpdate] = React.useState(false);
  const [addL3, setAddL3] = React.useState(false);
  const [addL1, setAddL1] = React.useState(false);
  const [addL4, setAddL4] = React.useState(false);
  const [datas, setDatas] = useState("");
  const [l1name, setL1name] = useState("");
  const [l2name, setL2name] = useState("");
  const [l3name, setL3name] = useState("");
  const [radius, setRadius] = useState("");
  const [merchantDeliveryCharges, setMerchantDeliveryCharges] = useState("");
  const [subscriptionCharges, setSubscriptionCharges] = useState("");
  const [chattyBaoHomeDelivery, setChattyBaoHomeDelivery] = useState("");
  const [deliveryTAT, setDeliveryTAT] = useState("");
  const [categoryDescription, setCategoryDescription] = useState("");
  const [dryRunRadius, setDryRunRadius] = useState("");
  const [paymentAndNetworkCharges, setPaymentAndNetworkCharges] = useState("");
  const [classification, setClassification] = useState("");
  const [deliveryChargeOnCustomer, setDeliveryChargeOnCustomer] = useState("");
  const [eligibilityForCBDelivery, setEligibilityForCBDelivery] = useState("");
  const [gstOnPaymentAndNetworkCharges, setGstOnPaymentAndNetworkCharges] = useState("");
  const [baseLogisticsRate, setBaseLogisticsRate] = useState("");
  const [perKmLogisticsRate, setPerKmLogisticsRate] = useState("");
  const [gstOnLogisticsCharges, setGstOnLogisticsCharges] = useState("");
  const [subscriptionCharges1, setSubscriptionCharges1] = useState("");
  const [gstOnSubscriptionCharges1, setGstOnSubscriptionCharges1] = useState("");
  const [subscriptionCharges2, setSubscriptionCharges2] = useState("");
  const [gstOnSubscriptionCharges2, setGstOnSubscriptionCharges2] = useState("");
  const [subscriptionCharges3, setSubscriptionCharges3] = useState("");
  const [gstOnSubscriptionCharges3, setGstOnSubscriptionCharges3] = useState("");
  const [categoryImage,setCategoryImage] = useState("");
  const [isEdit , setIsEdit] = useState(true);
  const [searchInput, setSearchInput] = useState('');
  const [childVisible, setChildVisible] = useState(true);

  const deliveryOption=[
    { value: 0, label: "Yes", isCheck: false },
    { value: 1, label: "No", isCheck: false }
  ];

  React.useEffect(() => {
    getCategoryTree();
  }, []);

  const handleChildVisible = () => {
    setChildVisible(!childVisible);
  };

  const handleCategoryName = async (val) => {
    setCategory(val);
  };

  const handleRadius = async (val) => {
    
    setRadius(val);
  };

  const handleMerchantDeliveryCharges = async (val) => {
    setMerchantDeliveryCharges(val);
  };

  const handleSubscriptionCharges = async (val) => {
    setSubscriptionCharges(val);
  };

  const handleChattyBaohomeDelivery = async (val) => {
    setChattyBaoHomeDelivery(val);
  };

  const handleDeliveryTAT = async (val) => {
    setDeliveryTAT(val);
  };

  const handleCategoryDescription= async (val) => {
    setCategoryDescription(val);
  };

  const handleEligibilityForCBDelivery = async (val) => {
    setEligibilityForCBDelivery(val);
  };

  const handleGstOnPaymentAndNetworkCharges = async (val) => {
    setGstOnPaymentAndNetworkCharges(val);
  };

  const handleBaseLogisticsRate = async (val) => {
    setBaseLogisticsRate(val);
  };

  const handlePerKmLogisticsRate = async (val) => {
    setPerKmLogisticsRate(val);
  };

  const handleGstOnLogisticsCharges = async (val) => {
    setGstOnLogisticsCharges(val);
  };

  const handleSubscriptionCharges1 = async (val) => {
    setSubscriptionCharges1(val);
  };

  const handleGSTonsubscriptioncharge1 = async (val) => {
    setGstOnSubscriptionCharges1(val);
  };

  const handleSubscriptionCharges2 = async (val) => {
    setSubscriptionCharges2(val);
  };

  const handleGSTonsubscriptioncharge2 = async (val) => {
    setGstOnSubscriptionCharges2(val);
  };

  const handleSubscriptionCharges3 = async (val) => {
    setSubscriptionCharges3(val);
  };

  const handleGSTonsubscriptioncharge3 = async (val) => {
    setGstOnSubscriptionCharges3(val);
  };

  const handleDryRunRadius= async (val) => {
    setDryRunRadius(val);
  };

  const handleImageDetails= async (val) => {
    setCategoryImage(val);
  };


  const handlePaymentAndNetworkCharges= async (val) => {
    setPaymentAndNetworkCharges(val);
  };

  const handleClassification= async (val) => {
    setClassification(val);
  };

  const handleDeliveryChargeOnCustomer= async (val) => {
    setDeliveryChargeOnCustomer(val);
  };

  const editCategory = async (val,id,type,parentId,datas,l1name,l2name,l3name) => {
    console.log("hello");
    
    setIsEdit(true)
    setDatas(data);
    setL1name(l1name)
    setL2name(l2name)
    setL3name(l3name)
    if(type!=="l1" && type!=="l2"){
      setAddL3(true);
    }else{
      setAddL3(false);
    }
    if(type==="l3"){
      setAddL4(false);
    }else{
      setAddL4(true);
    }
    setParent_id(parentId);
    setCategory(val);
    setAddOrUpdate(false);
    setCategoryId(id);
    setCategoryType(type);
    setEditPopup(!editPopup);
    setDatas(datas);
  };

  const addCategory = async (val,id,type,parentId,datas,l1name,l2name,l3name,item) => {
    if(radius==''){
      setRadius(0);
    }
    if(baseLogisticsRate=='')
    {
      setBaseLogisticsRate(0);
    }
    if(deliveryTAT=='')
    {
      setDeliveryTAT(0);
    }
    if(gstOnPaymentAndNetworkCharges=='')
    {
      setGstOnPaymentAndNetworkCharges(0);
    }
    if(deliveryChargeOnCustomer=='')
    {
      setDeliveryChargeOnCustomer(0);
    }
    if(eligibilityForCBDelivery=='')
    {
      setEligibilityForCBDelivery(0);
    }
    if(gstOnLogisticsCharges==''){
     setGstOnLogisticsCharges(0);
    }
    if(paymentAndNetworkCharges=='')
    {
      setPaymentAndNetworkCharges(0);
    }
    if(gstOnSubscriptionCharges1=='')
    {
      setGstOnSubscriptionCharges1(0);
    }
    if(gstOnSubscriptionCharges2=='')
    {
      setGstOnSubscriptionCharges2(0);
    }
    if(gstOnSubscriptionCharges3=='')
    {
      setGstOnSubscriptionCharges3(0);
    }
    if(merchantDeliveryCharges=='')
    {
      setMerchantDeliveryCharges(0);
    }
    if(paymentAndNetworkCharges==''){
        setPaymentAndNetworkCharges(0);
    }
    if(perKmLogisticsRate=='')
    {
      setPerKmLogisticsRate(0);
    }
    if(subscriptionCharges==''){
     setSubscriptionCharges(0);
    }
    if(subscriptionCharges1==''){
      setSubscriptionCharges1(0);
     }
     if(subscriptionCharges2==''){
      setSubscriptionCharges2(0);
     }
     if(subscriptionCharges3==''){
      setSubscriptionCharges3(0);
     }
     if(dryRunRadius=='')
     {
      setDryRunRadius(0);
     }
    setIsEdit(false)
    setL1name(l1name)
    setL2name(l2name)
    setL3name(l3name)
    setDatas(item);
    if(type!=="l1" ){
      setAddL3(true);
    }else{
      setAddL3(false);
    }
    if(type==="l3"){
      setAddL4(true);
    }else{
      setAddL4(false);
    }
    setAddOrUpdate(true);
    if(val==="l1"){
      setAddL1(true);
    }else{
      setCategory(val);
      setAddL1(false);
    }
    setParent_id(id);
    setCategoryType(type);
    setEditPopup(!editPopup);
  };

  const handleCancelBtn = () => {
    setEditPopup(!editPopup);
    setAddL3(false);
    getCategoryTree()
  };

  const addUpdateCategory = async (id,categoryType,parent_id,btn) => {

    let param={
      categoryName:category
    };
    param.btn=btn;
    
    if(btn==="0"){ 
      let type=categoryType==="l1" ? "l2" : categoryType==="l2" ? "l3"  : "l4" ;
      if(!parent_id){
        param.type="l1";
      }else{
        if(type==="l2"){
          param.type=type;
          param.l1CategoryId=parent_id;
        }else if(type==="l3"){
          param.type=type;
          param.l2CategoryId=parent_id;
          param.radius=radius;
          param.merchantDeliveryCharges=merchantDeliveryCharges;
          param.subscriptionCharges=subscriptionCharges;
          param.chattyBaoHomeDelivery=chattyBaoHomeDelivery.value;
          param.deliveryTAT=deliveryTAT;
          param.categoryDescription=categoryDescription;
          param.eligibilityForCBDelivery = eligibilityForCBDelivery;
          param.gstOnPaymentAndNetworkCharges = gstOnPaymentAndNetworkCharges;
          param.baseLogisticsRate = baseLogisticsRate;
          param.perKmLogisticsRate = perKmLogisticsRate;
          param.gstOnLogisticsCharges = gstOnLogisticsCharges;
          param.subscriptionCharges1 = subscriptionCharges1;
          param.gstOnSubscriptionCharges1 = gstOnSubscriptionCharges1;
          param.subscriptionCharges2 = subscriptionCharges2;
          param.gstOnSubscriptionCharges2 = gstOnSubscriptionCharges2;
          param.subscriptionCharges3 = subscriptionCharges3;
          param.gstOnSubscriptionCharges3 = gstOnSubscriptionCharges3;
          param.categoryImage = categoryImage;
          param.paymentAndNetworkCharges = paymentAndNetworkCharges;
        
        }else{    
          param.type=type;
          param.l3CategoryId=parent_id;
          param.radius=radius;
          param.merchantDeliveryCharges=merchantDeliveryCharges;
          param.subscriptionCharges=subscriptionCharges;
          param.categoryDescription=categoryDescription;
          param.dryRunRadius=dryRunRadius;
          param.paymentAndNetworkCharges=paymentAndNetworkCharges;
          if(classification=="")
          {
            param.classification=0;
          }else{
          param.classification=classification.value;
          }
          param.deliveryChargeOnCustomer=deliveryChargeOnCustomer;     
          param.eligibilityForCBDelivery = eligibilityForCBDelivery;
          param.gstOnPaymentAndNetworkCharges = gstOnPaymentAndNetworkCharges;
          param.baseLogisticsRate = baseLogisticsRate;
          param.perKmLogisticsRate = perKmLogisticsRate;
          param.gstOnLogisticsCharges = gstOnLogisticsCharges;
          param.subscriptionCharges1 = subscriptionCharges1;
          param.gstOnSubscriptionCharges1 = gstOnSubscriptionCharges1;
          param.subscriptionCharges2 = subscriptionCharges2;
          param.gstOnSubscriptionCharges2 = gstOnSubscriptionCharges2;
          param.subscriptionCharges3 = subscriptionCharges3;
          param.gstOnSubscriptionCharges3 = gstOnSubscriptionCharges3; 
             
        }
      }
    }else{ //update
 console.log(param);
 console.log(radius);
      if((radius=='')){
        setRadius(0);
      }
      if(baseLogisticsRate=='')
      {
        setBaseLogisticsRate(null);
      }
      if(deliveryTAT=='')
      {
        setDeliveryTAT(0);
      }
      if(gstOnPaymentAndNetworkCharges=='')
      {
        setGstOnPaymentAndNetworkCharges(0);
      }
      if(deliveryChargeOnCustomer=='')
      {
        setDeliveryChargeOnCustomer(0);
      }
      if(eligibilityForCBDelivery=='')
      {
        setEligibilityForCBDelivery(0);
      }
      if(gstOnLogisticsCharges==''){
       setGstOnLogisticsCharges(0);
      }
      if(paymentAndNetworkCharges=='')
      {
        setPaymentAndNetworkCharges(0);
      }
      if(gstOnSubscriptionCharges1=='')
      {
        setGstOnSubscriptionCharges1(0);
      }
      if(gstOnSubscriptionCharges2=='')
      {
        setGstOnSubscriptionCharges2(0);
      }
      if(gstOnSubscriptionCharges3=='')
      {
        setGstOnSubscriptionCharges3(0);
      }
      if(merchantDeliveryCharges=='')
      {
        setMerchantDeliveryCharges(0);
      }
      if(paymentAndNetworkCharges==''){
          setPaymentAndNetworkCharges(0);
      }
      if(perKmLogisticsRate=='')
      {
        setPerKmLogisticsRate(0);
      }
      if(subscriptionCharges==''){
       setSubscriptionCharges(0);
      }
      if(subscriptionCharges1==''){
        setSubscriptionCharges1(0);
       }
       if(subscriptionCharges2==''){
        setSubscriptionCharges2(0);
       }
       if(subscriptionCharges3==''){
        setSubscriptionCharges3(0);
       }
       if(dryRunRadius=='')
       {
        setDryRunRadius(0);
       }
      let type=categoryType;
      if(!parent_id){
        param.type="l1";
        param.id=id;
      }else{
        if(type==="l2"){
          param.id=id;
          param.type=type;
          param.l1CategoryId=parent_id;
        }else if(type==="l3"){
          param.id=id;
          param.type=type;
          param.l2CategoryId=parent_id;
          param.radius= radius ?radius : datas.radius == null || datas.radius == ""
          ? 0
          : datas.radius;
          param.merchantDeliveryCharges= merchantDeliveryCharges ?merchantDeliveryCharges:datas.merchantDeliveryCharges==null||datas.merchantDeliveryCharges=="" ?0 :datas.merchantDeliveryCharges;
          param.subscriptionCharges= subscriptionCharges ?subscriptionCharges:datas.subscriptionCharges==null||datas.subscriptionCharges=="" ?0 :datas.subscriptionCharges ;
        
          console.log(chattyBaoHomeDelivery);
          const deliveryItem = deliveryOption.find((it) => it.value === datas.chattyBaoHomeDelivery);
//param.chattyBaoHomeDelivery = deliveryItem === undefined || deliveryItem === null || deliveryItem==="" ? 0 : deliveryItem.value;
        
           if(deliveryItem==undefined)
           {
            param.chattyBaoHomeDelivery=0;
           }else
           if(chattyBaoHomeDelivery!='')
           {
            param.chattyBaoHomeDelivery=chattyBaoHomeDelivery.value;
           }else{
          param.chattyBaoHomeDelivery=deliveryItem.value;
           }
          param.deliveryTAT= deliveryTAT ?deliveryTAT: datas.deliveryTAT==null|| datas.deliveryTAT==""?0:datas.deliveryTAT;
          param.categoryDescription= categoryDescription ? categoryDescription: datas.categoryDescription==null|| datas.categoryDescription==""?0:datas.categoryDescription;
          param.eligibilityForCBDelivery = eligibilityForCBDelivery ?eligibilityForCBDelivery: datas.eligibilityForCBDelivery==null||datas.eligibilityForCBDelivery==""?0:datas.eligibilityForCBDelivery;
          param.gstOnPaymentAndNetworkCharges = gstOnPaymentAndNetworkCharges ?gstOnPaymentAndNetworkCharges:datas.gstOnPaymentAndNetworkCharges==null||datas.gstOnPaymentAndNetworkCharges==""?0:datas.gstOnPaymentAndNetworkCharges;
          param.baseLogisticsRate = baseLogisticsRate ?baseLogisticsRate:datas.baseLogisticsRate==null||datas.baseLogisticsRate==""?0:datas.baseLogisticsRate;
          param.perKmLogisticsRate = perKmLogisticsRate ?perKmLogisticsRate:datas.perKmLogisticsRate==null||datas.perKmLogisticsRate==""?0:datas.perKmLogisticsRate;
          param.gstOnLogisticsCharges = gstOnLogisticsCharges ? gstOnLogisticsCharges: datas.gstOnLogisticsCharges=""|| datas.gstOnLogisticsCharges==null?0:datas.gstOnLogisticsCharges;
          param.subscriptionCharges1 = subscriptionCharges1 ?subscriptionCharges1:datas.subscriptionCharges1==""||datas.subscriptionCharges1==null?0:datas.subscriptionCharges1;
          param.gstOnSubscriptionCharges1 = gstOnSubscriptionCharges1 ?gstOnSubscriptionCharges1:datas.gstOnSubscriptionCharges1==""||datas.gstOnSubscriptionCharges1==null?0:datas.gstOnSubscriptionCharges1;
          param.subscriptionCharges2 = subscriptionCharges2 ?subscriptionCharges2:datas.subscriptionCharges2==""||datas.subscriptionCharges2==null?0:datas.subscriptionCharges2;
          param.gstOnSubscriptionCharges2 = gstOnSubscriptionCharges2 ?gstOnSubscriptionCharges2:datas.gstOnSubscriptionCharges2==""||datas.gstOnSubscriptionCharges2==null?0:datas.gstOnSubscriptionCharges2;

          param.subscriptionCharges3 = subscriptionCharges3 ?subscriptionCharges3:datas.subscriptionCharges3==""||datas.subscriptionCharges3==null?0:datas.subscriptionCharges3;
          param.gstOnSubscriptionCharges3 = gstOnSubscriptionCharges3 ?gstOnSubscriptionCharges3:datas.gstOnSubscriptionCharges3==""||datas.gstOnSubscriptionCharges3==null?0:datas.gstOnSubscriptionCharges3;

          // param.gstOnSubscriptionCharges3 =gstOnSubscriptionCharges3 ? gstOnSubscriptionCharges3 : datas.gstOnSubscriptionCharges3;
          param.categoryImage = categoryImage ? categoryImage : datas.categoryImage;
          param.paymentAndNetworkCharges = paymentAndNetworkCharges ?paymentAndNetworkCharges:datas.paymentAndNetworkCharges==""||datas.paymentAndNetworkCharges==null?0:datas.paymentAndNetworkCharges;

         // param.paymentAndNetworkCharges = paymentAndNetworkCharges ? paymentAndNetworkCharges : datas.paymentAndNetworkCharges;
        }else{
          param.id = id;
          param.type = type;
          param.l3CategoryId = parent_id;
          param.radius = radius ? radius : datas.radius == null || datas.radius == "" ? 0 : datas.radius;
          param.merchantDeliveryCharges = merchantDeliveryCharges
            ? merchantDeliveryCharges
            : datas.merchantDeliveryCharges == "" || datas.merchantDeliveryCharges == null
            ? 0
            : datas.merchantDeliveryCharges;
          param.subscriptionCharges = subscriptionCharges ? subscriptionCharges : datas.subscriptionCharges == "" || datas.subscriptionCharges == null ? 0 : datas.subscriptionCharges;
          param.categoryDescription = categoryDescription ? categoryDescription : datas.categoryDescription == "" || datas.categoryDescription == null ? 0 : datas.categoryDescription;
          param.dryRunRadius = dryRunRadius ? dryRunRadius : datas.dryRunRadius == "" || datas.dryRunRadius == null ? 0 : datas.dryRunRadius;
          param.paymentAndNetworkCharges = paymentAndNetworkCharges
            ? paymentAndNetworkCharges
            : datas.paymentAndNetworkCharges == "" || datas.paymentAndNetworkCharges == null
            ? 0
            : datas.paymentAndNetworkCharges;
            console.log(datas.classification);
            console.log(classification);
            if ((classification == "" || classification == null)&&(datas.classification==null || datas.classification=='')) {
              param.classification = 0;
            } else { 
          param.classification = classification==""||classification == null ? datas.classification:classification.value;
            }
            
          param.deliveryChargeOnCustomer = deliveryChargeOnCustomer
            ? deliveryChargeOnCustomer
            : datas.deliveryChargeOnCustomer == "" || datas.deliveryChargeOnCustomer == null
            ? 0
            : datas.deliveryChargeOnCustomer;
          param.eligibilityForCBDelivery = eligibilityForCBDelivery
            ? eligibilityForCBDelivery
            : datas.eligibilityForCBDelivery == "" || datas.eligibilityForCBDelivery == null
            ? 0
            : datas.eligibilityForCBDelivery;
          param.gstOnPaymentAndNetworkCharges = gstOnPaymentAndNetworkCharges
            ? gstOnPaymentAndNetworkCharges
            : datas.gstOnPaymentAndNetworkCharges == "" || datas.gstOnPaymentAndNetworkCharges == null
            ? 0
            : datas.gstOnPaymentAndNetworkCharges;
          param.baseLogisticsRate = baseLogisticsRate ? baseLogisticsRate : datas.baseLogisticsRate == "" || datas.baseLogisticsRate == null ? 0 : datas.baseLogisticsRate;
          param.perKmLogisticsRate = perKmLogisticsRate ? perKmLogisticsRate : datas.perKmLogisticsRate == "" || datas.perKmLogisticsRate == null ? 0 : datas.perKmLogisticsRate;
          param.gstOnLogisticsCharges = gstOnLogisticsCharges ? gstOnLogisticsCharges : datas.gstOnLogisticsCharges == "" || datas.gstOnLogisticsCharges == null ? 0 : datas.gstOnLogisticsCharges;
          param.subscriptionCharges1 = subscriptionCharges1 ? subscriptionCharges1 : datas.subscriptionCharges1 == "" || datas.subscriptionCharges1 == null ? 0 : datas.subscriptionCharges1;
          param.gstOnSubscriptionCharges1 = gstOnSubscriptionCharges1
            ? gstOnSubscriptionCharges1
            : datas.gstOnSubscriptionCharges1 == "" || datas.gstOnSubscriptionCharges1 == null
            ? 0
            : datas.gstOnSubscriptionCharges1;
          param.subscriptionCharges2 = subscriptionCharges2 ? subscriptionCharges2 : datas.subscriptionCharges2 == "" || datas.subscriptionCharges2 == null ? 0 : datas.subscriptionCharges2;
          param.gstOnSubscriptionCharges2 = gstOnSubscriptionCharges2
            ? gstOnSubscriptionCharges2
            : datas.gstOnSubscriptionCharges2 == "" || datas.gstOnSubscriptionCharges2 == null
            ? 0
            : datas.gstOnSubscriptionCharges2;
          param.subscriptionCharges3 = subscriptionCharges3
            ? subscriptionCharges3
            : datas.subscriptionCharges3
            ? subscriptionCharges3
            : datas.subscriptionCharges3 == null || datas.subscriptionCharges3 == ""
            ? 0
            : datas.subscriptionCharges3;
          param.gstOnSubscriptionCharges3 = gstOnSubscriptionCharges3
            ? gstOnSubscriptionCharges3
            : datas.gstOnSubscriptionCharges3 == null || datas.gstOnSubscriptionCharges3 == ""
            ? 0
            : datas.gstOnSubscriptionCharges3;
        }
      }
    }

    await CategoryService.addUpdateCategory(param)
      .then(async (res) => {
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              let result = JSON.parse(res);
              if (result.code === 0) {
                // setEditPopup(!editPopup);
                // getCategoryTree();
                // setAddL3(false);
                alertSuccessMessage(`${result.message}`);
                
              } else { 
              }
            });
          } catch (err) {
            console.log(err);   
            alertDangerConfirmMessage(`${err}`)  
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
    function search(rows) {
      return rows.filter(
        (rows) => 
        rows.name.toLowerCase().indexOf(searchInput.toLowerCase()) > -1 ||
        rows.type.toLowerCase().indexOf(searchInput.toLowerCase()) > -1 ||
        rows.type.toLowerCase().indexOf(searchInput.toLowerCase()) > -1 
      )
    }

    // const recursivelyFindKeyValue = (key, keyValue, list) => {    
    //   if(keyValue) {
    //     for (let i = 0; i < list.length; i++) {
    //       const item = list[i];
    //       for (const key of Object.keys(item)) {
    //         //check if its not an array
    //         if (Array.isArray(item[key])) {
    //           console.log("child array found, searching", item);
    //           const res = recursivelyFindKeyValue(key, keyValue, item[key]);
    //           if (res?.found === true) return res;
    //           console.log("abca",item[key].includes(keyValue))
    //         }
    //         //we have found it
    //         else if (item[key] === keyValue) {
    //           console.log("abca",item[key].includes(keyValue))
    //           //found, return the list
    //           console.log("found ", keyValue);
    //           return { found: true, containingArray: list };
    //         }
    //       }
    //     }
    //   }
    //   else {
    //     return {containingArray: list};
    //   }

    // }

  const getCategoryTree = async () => {
    await CategoryService.getCategoryTree()
      .then(async (res) => {
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              let result = JSON.parse(res);
              if (result.code === 0) {
                setData([...result.data]);
              } else {
                console.log(result.message);
              }
            });
          } catch (err) {
            console.log(err);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
    }
    const App = () => (
      <div className=''>
      <Container className='left bg-style '>
      <form className='mg-left-50 pl-4 py-4'>
      <Button
            onClick={() => addCategory("l1","","l1")}
            className="custom-add-btn px-4 "
            >
              Add L1Category</Button>
              <div className="search">
                  <TextField id="" variant="outlined" placeholder="Search by Category" value={searchInput} onChange= {(e) => setSearchInput(e.target.value)}  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}/>
              </div>
        <Tree data={data} />
      </form>
      </Container>
      </div>
    );
    
    const Tree = ({data = []}) => ( 
      <div className='d-tree'>
        <ul className='d-flex d-tree-container flex-column'>
          {data.map(tree => (
            <TreeNode item={tree} />
          ))}
        </ul>
      </div>
      // <ul className={`ul-style font-12rem`}>
      //   {data && data.map(item => (
      //     <li 
      //     className= {` ${item.type === `l1` ? 'l1-category' : item.type === `l2` ? 'l2-category' : item.type === `l3` ? 'l3-category' : 'l4-category'} `}
      //     id={` ${item.type === `l1` ? 'l1' : item.type === `l2` ? 'l2' : item.type === `l3` ? 'l3y' : 'l4'} `}>
      //       {item.name!=="" ? (
      //         <div>
      //           {item.name}
      //           {console.log("item.name",item.type === `l1`)}
      //           <EditIcon
      //           className='mg-left-1 icons'
      //             onClick={() => editCategory(item.name,item.id,item.type,item.parent_id,item.datas,item.l1name,item.l2name,item.l3name)}
      //           />
      //           {item.type!=="l4" ? (
      //             <>
      //              <AddCircleIcon
      //              className='icons'
      //              onClick={() => addCategory("",item.id,item.type,item.parent_id,item.radius,item.l1name,item.l2name,item.l3name,item)}
      //              />
      //              </>
      //           ):(
      //             <div></div>
      //           )}
      //           <KeyboardArrowDownIcon onClick={handleChildVisible} />
      //         </div>
      //         ) : (
      //           <div></div>
      //         )}

      //       {item.children!==undefined && childVisible ? (
      //         <div>
      //           {item.children &&<Tree data={item.children} ></Tree>}
      //         </div>
      //         ) : (
      //           <div></div>
      //         )}    
      //     </li>
      //   ))}
      // </ul>
    );

    const TreeNode = ({item}) => {
      const [childVisible, setChildVisible] = useState(false);
      const hasChild = item ? true : false;
      const handleChildVisible = () => {
        setChildVisible(!childVisible);
      };
      return (
        <l1 className={`d-tree-node border-0 ${item.type === `l1` ? 'l1-category' : item.type === `l2` ? 'l2-category' : item.type === `l3` ? 'l3-category' : 'l4-category'} `}>
          <div className='d-flex' onClick={handleChildVisible}>
            {hasChild && (
              <div className={`d-inline d-tree-toggler ${childVisible ? 'active' : ''}`}>
                <KeyboardArrowDownIcon className='icons' />
              </div>
            )}
            {item.name!=="" ? (
              <div>
                {item.name}
                <EditIcon
                className='mg-left-1 icons'
                  onClick={() => editCategory(item.name,item.id,item.type,item.parent_id,item.datas,item.l1name,item.l2name,item.l3name)}
                />
                {item.type!=="l4" ? (
                  <>
                   <AddCircleIcon
                   className='icons'
                   onClick={() => addCategory("",item.id,item.type,item.parent_id,item.radius,item.l1name,item.l2name,item.l3name,item)}
                   />
                   </>
                ):(
                  <div></div>
                )}
              </div>
              ) : (
                <div></div>
              )}
          </div>
          {hasChild && !childVisible && <div className='d-tree-content'>
            <ul className='d-flex d-tree-container flex-column'>
              <Tree data= {item.children}/>
            </ul>
            </div>}
        </l1>
      )
    }

    return (
      <Container fluid>
        <Row>
          <Col xs={2} id="sidebar-wrapper">      
            <Sidebar />
          </Col>
          <Col  xs={10} id="page-content-wrapper">
            <div className="App">
              <h1 className='center'>Category Tree</h1>
                <Container  className='center'>  
                </Container>
                {/* <App /> */}
                <div className=''>
                  <Container className='left bg-style '>
                  <form className='mg-left-50 pl-4 py-4'>
                  <Button
                        onClick={() => addCategory("l1","","l1")}
                        className="custom-add-btn px-4 "
                        >
                          Add L1Category</Button>
                          <div className="search">
                              <TextField id="outlined-basic" variant="outlined" placeholder="Search by Category" value={searchInput} onChange= {(e) => setSearchInput(e.target.value)}  InputProps={{
                                startAdornment: (
                                  <InputAdornment>
                                    <SearchIcon />
                                  </InputAdornment>
                                )
                              }}/>
                          </div>
                    {/* <Tree data={recursivelyFindKeyValue('name',searchInput,data)?.containingArray} /> */}
                    <Tree data= {search(data)} />
                  </form>
                  </Container>
                  </div>
              {editPopup ? (
              <EditCategory
              isModal={editPopup}
              handleCancelBtn={handleCancelBtn}
              categoryName={category}
              categoryId={categoryId}
              categoryType={categoryType}
              parent_id={parent_id}
              addOrUpdate={addOrUpdate}
              isEdit={isEdit}
              datas={datas}
              addL3={addL3}
              addL4={addL4}
              addL1={addL1}
              datas={datas}
              l1name={l1name}
              l2name={l2name}
              l3name={l3name}
              addUpdateCategory={addUpdateCategory}
              handleCategoryName={handleCategoryName}
              handleRadius={handleRadius}
              handleMerchantDeliveryCharges={handleMerchantDeliveryCharges}
              handleSubscriptionCharges={handleSubscriptionCharges}
              handleChattyBaohomeDelivery={handleChattyBaohomeDelivery}
              handleDeliveryTAT={handleDeliveryTAT}
              handleCategoryDescription={handleCategoryDescription}
              handleDryRunRadius={handleDryRunRadius}
              handlePaymentAndNetworkCharges={handlePaymentAndNetworkCharges}
              handleClassification={handleClassification}
              handleDeliveryChargeOnCustomer={handleDeliveryChargeOnCustomer}
              handleEligibilityForCBDelivery={handleEligibilityForCBDelivery}
              handleGstOnPaymentAndNetworkCharges={handleGstOnPaymentAndNetworkCharges}
              handleBaseLogisticsRate={handleBaseLogisticsRate}
              handlePerKmLogisticsRate={handlePerKmLogisticsRate}
              handleGstOnLogisticsCharges={handleGstOnLogisticsCharges}
              handleSubscriptionCharges1={handleSubscriptionCharges1}
              handleGSTonsubscriptioncharge1={handleGSTonsubscriptioncharge1}
              handleSubscriptionCharges2={handleSubscriptionCharges2}
              handleGSTonsubscriptioncharge2={handleGSTonsubscriptioncharge2}
              handleSubscriptionCharges3={handleSubscriptionCharges3}
              handleGSTonsubscriptioncharge3={handleGSTonsubscriptioncharge3}
              handleImageDetails ={handleImageDetails}
              childVisible={!childVisible}
              />
            ) : null}
            </div>
          </Col>
        </Row>
      </Container>
    );
};

export default Home;