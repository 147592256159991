import React, { useEffect, useState } from "react";
import logo from "./LOGO.png";
import "./cpanel.css";
import { TextField, Autocomplete } from "@mui/material";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import Select, { components, DropdownIndicatorProps } from "react-select";
import SearchIcon from "@mui/icons-material/Search";
import { IndicatorSeparatorProps } from "react-select";
import axios from "axios";
import download from "../images/download.png";


const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props} style="{flexDirection:'row-reverse'}">
      <SearchIcon sx={{ fontSize: "20px" }} />
    </components.DropdownIndicator>
  );
};
const indicatorSeparatorStyle = {
  alignSelf: "stretch",
  //backgroundColor: colourOptions[2].color,
  flexDirection: "row-reverse",
  marginBottom: 8,
  marginTop: 8,
  width: 0,
};

export default function App() {
  const [selectCity, setSelectCity] = useState("");
  // const [city, setCity] = useState("");

  const [data, setData] = useState(null);
  const [pinCode, setPincode] = useState(null);
  const [cit, setCit] = useState(null);
  const [add, setAdd] = useState(null);
  const [total, setTotal] = useState([]);
  const [disable, setDisable] = useState(true);
  const [add1, setAdd1] = useState(null);
  const [name, setName] = useState("");
  const [submitEnable, setSubmitEnable] = useState(true);
  const [manual, setManual] = useState(true);

  // React.useEffect(() => {
  //   cityname();
  // }, []);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setTotal({ ...total, [name]: value });
  };
  const handleChangeName = (e) => {
    setName(e.target.value);
  };
  const changeAddress = (e) => {
    setAdd(e.target.value);
     if (manual == false) {
      if(e.target.value.trim() == "") {
        setSubmitEnable(true);
      }
      else if (cit.trim() == "" || cit == null || pinCode.trim() == "" || pinCode == null|| add1.trim() == "" || add1 == null) {
        setSubmitEnable(true);
      } else {
        setSubmitEnable(false);
      }
    } 
  };
  const changeAddress1 = (e) => {
    setAdd1(e.target.value);
    if (e.target.value.trim() == "") {
      setSubmitEnable(true);
    } else if (manual == false) {
      if (cit.trim() == "" || cit == null || pinCode.trim() == "" || pinCode == null||add.trim() == "" || add == null) {
        setSubmitEnable(true);
      } else {
        setSubmitEnable(false);
      }
    } else {
      if (cit.trim() == "" || cit == null || pinCode.trim() == "" || pinCode == null||add.trim() == "" || add == null) {
      setSubmitEnable(true);
      }else{
        setSubmitEnable(false);
      }
    }
  };
  const handlePincode = (e) => {
    setPincode(e.target.value);
    if (e.target.value.trim() == "") {
      setSubmitEnable(true);
    } else if (manual == false) {
      if (add1.trim() == "" || add1 == null || cit.trim() == "" || cit == null||add.trim() == "" || add == null) {
        setSubmitEnable(true);
      } else {
        setSubmitEnable(false);
      }
    } else {
      setSubmitEnable(false);
    }
  };
  const manualAddress = (e) => {
    setManual(false);
  };
  const handleCity = (e) => {
    setCit(e.target.value);
    if (e.target.value.trim() == "") {
      setSubmitEnable(true);
    } else if (manual == false) {
      if (add1.trim() == "" || add1 == null || pinCode.trim() == "" || pinCode == null || add.trim() == "" || add == null) {
        setSubmitEnable(true);
      } else {
        setSubmitEnable(false);
      }
    } else {
      setSubmitEnable(false);
    }
  };
  // const handleCityDropdown = (newValue) => {
  //   setCit(newValue);
  //   if (newValue == "" && newValue == null && newValue == undefined) {
  //     setSubmitEnable(true);
  //   } else if (manual == false) {
  //     if (add1 == "" || add1 == null || pinCode == "" || pinCode == null || newValue == "" || newValue == null || newValue == undefined) {
  //       setSubmitEnable(true);
  //     } else {
  //       setSubmitEnable(false);
  //     }
  //   } else {
  //     setSubmitEnable(false);
  //   }
  // };
  const urlParam = window.location.href;
  const userMob = urlParam.split("=")[1].split("&")[0];
  const noOfAddress = urlParam.split("&")[1].split("=")[1];
  let urlID="";
  if(urlParam.split("&").length==3){
  urlID = urlParam.substring(urlParam.lastIndexOf("=") + 1);
}else{
    urlID = "";
  }
  // console.log("urlID",urlID, urlParam.split("&").length==3);

  var redNum = 919289454472;
  const conv = urlParam.split("=")[0];
  if (conv.endsWith("m")) {
    redNum = 919289454473;
  }

  const userMobileNumber = atob(userMob);
  console.log(userMobileNumber);
  console.log(selectCity);

  useEffect(() => {
    setCit("");
    setPincode("");
    let add12 = "";
    setAdd1(add12);

    if (data != null) {
      setAdd(data.label);

      // setDisabled(false)

      console.log(data);

      geocodeByPlaceId(data.value.place_id)
        .then((results) => {
          console.log(results);
          for (let i = 0; i < results[0].address_components.length; i++) {
            if (results[0].address_components[i].types[0] === "locality") {
              console.log(results[0].address_components[i].long_name)
              setCit(results[0].address_components[i].long_name)
            }
          }

          if (results[0].address_components[results[0].address_components.length - 1].types[0] == "postal_code") {
            setPincode(results[0].address_components[results[0].address_components.length - 1].long_name);
            setDisable(true);
          } else {
            setDisable(false);
          }
        })
        .catch((error) => console.error(error));
    }
  }, [data]);

  useEffect(() => {
    //setAdd('');
  }, [add]);

  const call = (event) => {
    event.preventDefault();
    var input = document.querySelectorAll("input");
    console.log(input);
    var checkbox = document.querySelector('input[type="checkbox"]');
    //checking if fields are empty

    if (checkbox.checked) {
      if (add === "" || add === null) {
        alert("Address fields are mandatory");
        return;
      }

      if (input[2].value === "") {
        alert("Address fields are mandatory");
        return;
      }
      if (pinCode == "" || pinCode == null) {
        alert("Pincode field is mandatory");
        return;
      }

      // if (selectCity === "" && selectCity === null && selectCity === undefined) {
      //   alert("City field is mandatory");
      //   return;
      // }
    

      let fName = name;
      let al1 = add1;
      let al2 = add;
      let city = cit;
      let pincode = pinCode;
      var b = al1 + ", " + al2 ;
      var address = al1 + ", " + al2 + ", " + city + ", " + pincode;

      //updating address 1 for new users or users editing address 1
      console.log(noOfAddress);
      if (noOfAddress === "0") {
       
if(urlID==""){
        const addressUpdate = {
          userMobileNumber: userMobileNumber,
          // address1: b,
          // priority: 1,
          userName: fName,
          address1: b,
          city:city,
          pincode:pincode
          
          // "latitude": lat,
          // "longitude": lng
        };
        const updateUrl = window.addressURL + "updateUserAddress";
        const headers = {
          "Content-Type": "application/json",
        };
        axios.post(updateUrl, JSON.stringify(addressUpdate), { headers }).then((response) => {
          console.log(response);
          window.location.href = `https://api.whatsapp.com/send?phone=${redNum}&text=Name:%20${fName}.%20Address:%20${address}`;
        });
      }else{
        
        const addressUpdate = {
          userMobileNumber: userMobileNumber,
          // address1: b,
          // priority: 1,
          userName: fName,
          address1: b,
          city:city,
          pincode:pincode,
          id:urlID
          
          // "latitude": lat,
          // "longitude": lng
        };
        const updateUrl = window.addressURL + "updateUserAddress";
        const headers = {
          "Content-Type": "application/json",
        };
        axios.post(updateUrl, JSON.stringify(addressUpdate), { headers }).then((response) => {
          console.log(response);
          window.location.href = `https://api.whatsapp.com/send?phone=${redNum}&text=Name:%20${fName}.%20Address:%20${address}`;
        });

      }
      }

      // updating address 2 fields for users having 1 or more address fields
      else {
        console.log("here");
        if(urlID==""){
        const addressUpdate = {
          userMobileNumber: userMobileNumber,
          // address2: b,
          // priority: 2,
          userName: fName,
          address2: b,
          city:city,
          pincode:pincode
          // "latitude": lat,
          // "longitude": lng
        };
        const updateUrl = window.addressURL + "updateUserAddress";
        const headers = {
          "Content-Type": "application/json",
        };
        axios.post(updateUrl, JSON.stringify(addressUpdate), { headers }).then((response) => {
          console.log(response);
          window.location.href = `https://api.whatsapp.com/send?phone=${redNum}&text=Name:%20${fName}.%20Address:%20${address}`;
        });}
        else{
       
          const addressUpdate = {
            userMobileNumber: userMobileNumber,
            // address2: b,
            // priority: 2,
            userName: fName,
            address2: b,
            city:city,
            pincode:pincode,
            id:urlID
            // "latitude": lat,
            // "longitude": lng
          };
          const updateUrl = window.addressURL + "updateUserAddress";
          const headers = {
            "Content-Type": "application/json",
          };
          axios.post(updateUrl, JSON.stringify(addressUpdate), { headers }).then((response) => {
            console.log(response);
            window.location.href = `https://api.whatsapp.com/send?phone=${redNum}&text=Name:%20${fName}.%20Address:%20${address}`;
          });

        }
      }
      
    } else {
      alert("Please accept the Terms & Conditions and Privacy Policy to submit the form");
    }
  };

  // const cityname = async () => {
  //   var cityArrayResponse;
  //   var cityArray = [];
  //   return new Promise(async function (resolve, reject) {
  //     const response = await fetch("https://api.chattybao.com/v1/getCities");
  //     const data = await response.json();
  //     cityArrayResponse = data.data;

  //     cityArrayResponse.map((x) => cityArray.push(x.city));
  //     setCity(cityArray);

  //     // console.log(cityArray);
  //   });
  // };

 

  return (
    <div className="cpanel_container">
      <div className="icon">
        <img id="img" src={logo} alt="icon" />
      </div>

      <div className="user-content pb-2">
        <h1 id="heading" className="pb-2">
          Please provide address{" "}
        </h1>
        <form autoComplete="new-password">
          {manual == true ? (
            <>
              <GooglePlacesAutocomplete
                apiKey="AIzaSyDgzOSXP40P4tXXs1a2uUQ4dblD9pXKdEY"
                apiOptions={{ language: "en" }}
                minLengthAutocomplete={2}
                autocompletionRequest={{
                  componentRestrictions: {
                    country: ["in"],
                  },
                }}
                selectProps={{
                  styles: {
                    input: (provided) => ({
                      //  height:12,
                      //  alignitems:'center',
                      textAlign: "right",
                    }),
                    control: (provided) => ({
                      ...provided,
                      border: "1px solid #156CEF",
                      "&:hover": {
                        border: "1px solid #156CEF",
                      },
                    }),

                    placeholder: (provided) => ({
                      ...provided,
                      color: "rgba(0, 0, 0, 0.35)",
                    }),
                  },
                  components: { DropdownIndicator, IndicatorSeparator: () => null },
                  isClearable: true,
                  placeholder: "Type & search location / area",
                  data,
                  //onChange: setData,
                  onChange: (data) => {
                    setData(data);
                    setAdd("");
                  },
                  // onChange:go()
                }}
              />
              <img className="powered" src={download} />
            </>
          ) : (
            ""
          )}
          <br/>
          {data == null && manual == true ? (
            <div>
              <p onClick={manualAddress} style={{ marginTop: "38px", textAlign: "center", position: "absolute", color:"#007BFF", cursor: "pointer" }}>
                or Enter Address Manually
              </p>
            </div>
          ) : (
            ""
          )}

          {data != null || manual == false ? (
            <div className="category pt-4">
              <label
                htmlFor="FullName"
                className="label_style"
                style={{
                  color: "#156CEF",
                }}
              >
                Name <p style={{ color: "#A59090", display: "inline-block", fontSize: "10px" }}>(OPTIONAL)</p>
              </label>
              <input
                type="text"
                className="text_input"
                id="FullName"
                placeholder="TYPE YOUR NAME"
                name="name"
                value={name}
                autoComplete="rutjfkde"
                onChange={handleChangeName}
                style={{ borderBottom: "1px solid #156CEF" }}
              />
            </div>
          ) : (
            ""
          )}

          {data != null || manual == false ? (
            <div className="category">
              <label
                htmlFor="AddressLine1"
                className="label_style"
                style={{
                  color: "#156CEF",
                }}
              >
                Address <p style={{ color: "red", display: "inline-block" }}>*</p>
              </label>
              <input
                type="text"
                className="text_input"
                id="AddressLine1"
                autoComplete="rutjfkde"
                placeholder="HOUSE/ FLAT/ SOCIETY"
                name="ad1"
                value={add1}
                onChange={changeAddress1}
                style={{ borderBottom: "1px solid #156CEF" }}
              />
            </div>
          ) : (
            ""
          )}
          {data != null && manual != false ? (
            <div className="category">
              <label htmlFor="Address Line2" id="hide">
                Address
              </label>
              <input type="text" className="text_input" id="Address Line2" placeholder="HOUSE / FLAT / BLOCK NO." value={add} name="add" onChange={changeAddress} style={{ color: "#A59090" }} />
            </div>
          ) : (
            ""
          )}
          {data == null && manual == false ? (
            <div className="category">
              <label htmlFor="Address Line2" id="hide">
                Address
              </label>
              <input
                type="text"
                className="text_input textplace"
                id="Address Line2"
                placeholder="AREA / ROAD / SECTOR"
                value={add}
                name="adds"
                autoComplete="rutjfkde"
                onChange={changeAddress}
                style={{ color: "#212529", borderBottom: "1px solid #8e9091" }}
              />
            </div>
          ) : (
            ""
          )}
          {data != null && manual != false ? (
            <div className="category">
              <label htmlFor="City" className="label_style">
                City
              </label>
              {/* <input list="city" type="text" id="City" autoComplete="off" placeholder="eg :  Mumbai" onChange={cityChange} />
          <datalist id="city"></datalist> */}
              <input type="text" className="text_input" placeholder="CITY" id="pincode" value={cit} disabled={manual} onChange={handleCity} style={{ color: "#A59090", backgroundColor: "#fff" }} />
            </div>
          ) : (
            ""
          )}
          {data == null && manual == false ? (
            <div className="category">
              <label
                htmlFor="City"
                className="label_style"
                style={{
                  color: "#156CEF",
                }}
              >
                City <p style={{ color: "red", display: "inline-block" }}>*</p>
              </label>
              <input type="text" className="text_input textplace" placeholder="CITY" id="pincode" autoComplete="rutjfkde" value={cit} onChange={handleCity} style={{ color: "#212529", borderBottom: "1px solid #8e9091" }} />
              {/* <span style={{ width: "65%", fullWidth: "65%" }}>
                <Autocomplete
                  id="pincode"
                  value={manual === false ? selectCity : null}
                  onChange={(event, newValue) => {
                    setSelectCity(newValue);
                    handleCityDropdown(newValue);
                  }}
                  options={city}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      style={{ color: "#212529", textAlign: "left", alignItems: "left" }}
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "address-level4",
                      }}
                      id="standard-basic"
                      variant="standard"
                      placeholder="CITY"
                      sx={{
                        input: {
                          color: "#212529",
                          textAlign: "left",
                          alignItems: "left",
                          "&::-webkit-input-placeholder": {
                            color: "#212529",
                          },
                          "&::-moz-placeholder": {
                            color: "#212529",
                          },
                          "&::-ms-input-placeholder": {
                            color: "#212529",
                          },
                          "&::-moz-placeholder": {
                            color: "#212529",
                          },
                        },
                      }}
                    />
                  )}
                />
              </span> */}
            </div>
          ) : (
            ""
          )}

          {data != null && manual != false ? (
            <div className="category">
              <label htmlFor="pincode" className="label_style">
                Pincode
              </label>
              <input
                type="text"
                className="text_input"
                placeholder="eg :  400001"
                autoComplete="rutjfkde"
                id="pincode"
                value={pinCode}
                disabled={disable}
                onChange={handlePincode}
                style={{ color: "#A59090", backgroundColor: "#fff" }}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                maxLength={6}
              />
            </div>
          ) : (
            ""
          )}
          {data == null && manual == false ? (
            <div className="category">
              <label
                htmlFor="pincode"
                className="label_style"
                style={{
                  color: "#156CEF",
                }}
              >
                Pincode <p style={{ color: "red", display: "inline-block" }}>*</p>
              </label>
              <input
                type="text"
                className="text_input textplace"
                placeholder="eg :  400001"
                id="pincode"
                autoComplete="rutjfkde"
                value={pinCode}
                disabled={manual}
                onChange={handlePincode}
                style={{ color: "#212529", borderBottom: "1px solid #8e9091" }}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              maxLength={6}
              />
            </div>
          ) : (
            ""
          )}
          <input type="text" autoComplete="on" value="" style={{ display: "none", opacity: 0, position: "absolute", left: "-100000px" }} readOnly={true} />
          {data != null || manual == false ? (
            <div className="category">
              <input type="checkbox" name="Iagree" id="Iagree" defaultChecked />
              <div id="iagree">
                {" "}
                I Agree to{" "}
                <span className="bluetext">
                  <a href="https://chattybao.com/user-agreement" target="_blank" rel="noreferrer">
                    Terms & Conditions
                  </a>
                </span>{" "}
                and
                <span className="bluetext">
                  <a href="https://chattybao.com/privacy-policy" target="_blank" rel="noreferrer">
                    {" "}
                    Privacy Policy
                  </a>
                </span>
              </div>
            </div>
          ) : (
            ""
          )}
          {(data != null || manual == false) && submitEnable == true ? (
            <div className="category-button">
              <button className="submitbutton_disable" disabled="true" onClick={call}>
                Submit
              </button>
            </div>
          ) : (
            ""
          )}
          {(data != null || manual == false) && submitEnable != true ? (
            <div className="category-button">
              <button className="submitbutton" onClick={call}>
                Submit
              </button>
            </div>
          ) : (
            ""
          )}
        </form>
      </div>
    </div>
  );
}
