import { APIConfig } from "../api/apiConfig/apiConfig";

const { sendbirdBaseUrl } = APIConfig;
export const sendBirdGroupChannelUrl = (url) => `${sendbirdBaseUrl}/group_channels/${url}/messages`;

const constants = {
    distance: 'km',
    currency: '₹',
    defaultCity: 'Lucknow',
    dataUploadMessages: {
        uploadSuccessMsg: 'Data Upload Success',
        uploadFailMsg: 'Data Upload Failed',
        invalidFormatMsg: 'Invalid file format. Please upload a CSV or Excel file.'
    }

}

export { constants };