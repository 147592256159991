import { Fetch } from "../apiConfig/Fetch";
import { APIConfig } from "../apiConfig/apiConfig";

const OrderListService = {
    

    getOrderDetail : async (city) => {
        
        const { baseUrl, getOrderDetailsForPanel } = APIConfig;

        const url = baseUrl + getOrderDetailsForPanel+"?"+"city"+"="+city;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "token":window.sessionStorage.getItem('sessionId'),
           
        });


        const orderDetailPromise = await Fetch(
            url,
            "GET",
            headers,
            '',
            "services_orderDetail",
            true
        );
        return orderDetailPromise;
    },
    
    getList: async (city) => {
      
        const { baseUrl, getOrderDetailsListForPanel } = APIConfig;

        const url = baseUrl + getOrderDetailsListForPanel+"?"+"city"+"="+city;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "token":window.sessionStorage.getItem('sessionId'),
           
        });


        const orderListPromise = await Fetch(
            url,
            "GET",
            headers,
            '',
            "services_orderlist",
            true
        );
        return orderListPromise;
    },



    postOrderSummary : async (city, tab) => {

        const { baseUrl, postOrderSummary } = APIConfig;
        const url = baseUrl + postOrderSummary;
        const body = {
            "city": city,
            "tab": tab
        }

        const headers = {
            "Content-Type" : "application/json",
          };

        const res = await fetch(
            url,
            'POST',
            headers,
            body
        )

        return res;
      },


};

export default OrderListService;