import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import { Container } from "reactstrap";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Row, Col, Form } from "react-bootstrap";
import EditCategory from "./../components/customComponent/EditCategory";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "@mui/material";
import { APIConfig } from "../api/apiConfig/apiConfig";
import CategoryService from "./../api/services/CategoryService";
import { useHistory } from "react-router-dom";
import AwsService from "../api/services/AwsImageService";
import Sidebar from "./../components/sidebar.js";
import "../Dashboard.css";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@mui/material/TextField";
import { alertDangerConfirmMessage, alertSuccessMessage } from "../components/customComponent/ConfirmationMessage/ConfirmationMessage";
import Logo from "../chatty_bao_logo.png";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function Category() {
  const [data, setData] = useState([]);
  console.log("data", data);

  const headingdata = data.map((data) => data);
  const heading = headingdata[0];
  console.log("heading", heading);

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [open, setOpen] = React.useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [category, setCategory] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [parent_id, setParent_id] = useState("");
  const [categoryType, setCategoryType] = useState("");
  const [addOrUpdate, setAddOrUpdate] = React.useState(false);
  const [addL3, setAddL3] = React.useState(false);
  const [addL1, setAddL1] = React.useState(false);
  const [addL4, setAddL4] = React.useState(false);
  const [datas, setDatas] = useState("");
  const [l1name, setL1name] = useState("");
  const [l2name, setL2name] = useState("");
  const [l3name, setL3name] = useState("");
  const [radius, setRadius] = useState("");
  // const [merchantDeliveryCharges, setMerchantDeliveryCharges] = useState("");
  // const [subscriptionCharges, setSubscriptionCharges] = useState("");
  const [chattyBaoHomeDelivery, setChattyBaoHomeDelivery] = useState("");
  const [deliveryTAT, setDeliveryTAT] = useState("");
  const [categoryDescription, setCategoryDescription] = useState("");
  const [dryRunRadius, setDryRunRadius] = useState("");
  const [paymentAndNetworkCharges, setPaymentAndNetworkCharges] = useState("");
  // const [classification, setClassification] = useState("");
  const [deliveryChargeOnCustomer, setDeliveryChargeOnCustomer] = useState("");
  const [gstOnDeliveryChargeOnCustomer, setGSTOnDeliveryChargeOnCustomer] = useState("");
  const [eligibilityForCBDelivery, setEligibilityForCBDelivery] = useState("");
  const [gstOnPaymentAndNetworkCharges, setGstOnPaymentAndNetworkCharges] = useState("");
  const [subscriptionCharges1, setSubscriptionCharges1] = useState("");
  const [gstOnSubscriptionCharges1, setGstOnSubscriptionCharges1] = useState("");
  const [subscriptionCharges2, setSubscriptionCharges2] = useState("");
  const [gstOnSubscriptionCharges2, setGstOnSubscriptionCharges2] = useState("");
  const [subscriptionCharges3, setSubscriptionCharges3] = useState("");
  const [gstOnSubscriptionCharges3, setGstOnSubscriptionCharges3] = useState("");
  const [regularSettlementCharge, setRegularSettlementCharge] = useState("");
  const [vipSettlementCharge, setVipSettlementCharge] = useState("");
  const [commissionSDPer,setCommissionSDPer] =useState("");
  const [categoryImage, setCategoryImage] = useState("");
  const [range1, setRange1] = useState("");
  const [range2, setRange2] = useState("");
  const [range3, setRange3] = useState("");
  const [range4, setRange4] = useState("");
  const [range5, setRange5] = useState("");
  const [isEdit, setIsEdit] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [childVisible, setChildVisible] = useState(true);

  const [searchedArray, setSearchedArray] = React.useState(data);
  const [searchString, setSearchString] = React.useState("");
  console.log("searchString", searchString);

  const [previousArray, setPreviousArray] = useState([]);

  const [previousArrayl1, setPreviousArrayl1] = useState([]);
  const [previousArrayl2, setPreviousArrayl2] = useState([]);
  const [previousArrayl3, setPreviousArrayl3] = useState([]);
  const [nextArray, setNextArray] = useState([]);

  const deliveryOption = [
    { value: 0, label: "Yes", isCheck: false },
    { value: 1, label: "No", isCheck: false },
  ];

  const handleChildVisible = () => {
    setChildVisible(!childVisible);
  };

  const handleCategoryName = async (val) => {
    setCategory(val);
  };
  const handleRange1 = async (val) => {
    setRange1(val);
  };
  const handleRange2 = async (val) => {
    setRange2(val);
  };
  const handleRange3 = async (val) => {
    setRange3(val);
  };
  const handleRange4 = async (val) => {
    setRange4(val);
  };
  const handleRange5 = async (val) => {
    setRange5(val);
  };

  const handleRadius = async (val) => {
    setRadius(val);
  };

  // const handleMerchantDeliveryCharges = async (val) => {
  //   setMerchantDeliveryCharges(val);
  // };

  // const handleSubscriptionCharges = async (val) => {
  //   setSubscriptionCharges(val);
  // };

  const handleChattyBaohomeDelivery = async (val) => {
    setChattyBaoHomeDelivery(val);
  };

  const handleDeliveryTAT = async (val) => {
    setDeliveryTAT(val);
  };

  const handleCategoryDescription = async (val) => {
    setCategoryDescription(val);
  };

  const handleEligibilityForCBDelivery = async (val) => {
    setEligibilityForCBDelivery(val);
  };

  const handleGstOnPaymentAndNetworkCharges = async (val) => {
    setGstOnPaymentAndNetworkCharges(val);
  };
  const handleCommissionSDPer =async (val) =>{
    setCommissionSDPer(val);
  };

  const handleSubscriptionCharges1 = async (val) => {
    setSubscriptionCharges1(val);
  };

  const handleGSTonsubscriptioncharge1 = async (val) => {
    setGstOnSubscriptionCharges1(val);
  };

  const handleSubscriptionCharges2 = async (val) => {
    setSubscriptionCharges2(val);
  };

  const handleGSTonsubscriptioncharge2 = async (val) => {
    setGstOnSubscriptionCharges2(val);
  };

  const handleSubscriptionCharges3 = async (val) => {
    setSubscriptionCharges3(val);
  };

  const handleGSTonsubscriptioncharge3 = async (val) => {
    setGstOnSubscriptionCharges3(val);
  };

  const handleDryRunRadius = async (val) => {
    setDryRunRadius(val);
  };

  const handleImageDetails = async (val) => {
    setCategoryImage(val);
  };

  const handlePaymentAndNetworkCharges = async (val) => {
    setPaymentAndNetworkCharges(val);
  };

  // const handleClassification = async (val) => {
  //   setClassification(val);
  // };

  const handleDeliveryChargeOnCustomer = async (val) => {
    setDeliveryChargeOnCustomer(val);
  };
  const handleGSTOnDeliveryChargeOnCustomer = async (val) => {
    setGSTOnDeliveryChargeOnCustomer(val);
  };

  const handleRegularSettlementCharge = async (val) => {
    setRegularSettlementCharge(val);
  };

  const handleVipSettlementCharge = async (val) => {
    setVipSettlementCharge(val.toString());
  }

  useEffect(() => {
    getCategoryTree();

    const localprevcondition = localStorage.getItem("prevcondition");
    const localnextcondition = localStorage.getItem("nextcondition");
    const localprevdata = JSON.parse(localStorage.getItem("previousdata"));
    const localnextdata = JSON.parse(localStorage.getItem("nextdata"));

    if (window.performance) {
      console.info("window.performance works fine on this browser");
    }
    console.info(performance.navigation.type);
    if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
      console.info("This page is reloaded");
      setPreviousArray(localprevdata);
      setNextArray(localnextdata);
      if (localprevcondition == 0) {
        setData(localprevdata);
        setSearchedArray(localprevdata);
      }
      if (localnextcondition == 1) {
        setData(localnextdata);
        setSearchedArray(localnextdata);
      }
    } else {
      console.info("This page is not reloaded");

      const l1 = JSON.parse(localStorage.getItem("l1"));
      setPreviousArray(l1);
      const l2 = JSON.parse(localStorage.getItem("l2"));
      setPreviousArrayl2(l2);
      const l3 = JSON.parse(localStorage.getItem("l3"));
      const l4 = JSON.parse(localStorage.getItem("l4"));
      setPreviousArrayl3(l3);
      setNextArray(localnextdata);
      // prevcondition(localprevdata);

      if (localprevcondition == 0) {
        setData(localprevdata);
        setSearchedArray(localprevdata);
      }
      if (localnextcondition == 1) {
        setData(localnextdata);
        setSearchedArray(localnextdata);
      }

      const categorydata = searchedArray;
      const categorymaps = categorydata.map((data) => data.type);
      localStorage.removeItem("nextcondition");

      if (categorymaps[0] == "l2") {
        localStorage.setItem("previousdata", JSON.stringify(l2));
        localStorage.setItem("nextdata", JSON.stringify(l2));
        setData(l2);
        setSearchedArray(l2);
      }
      if (categorymaps[0] == "l3") {
        localStorage.setItem("previousdata", JSON.stringify(l3));
        localStorage.setItem("nextdata", JSON.stringify(l3));
        setData(l3);
        setSearchedArray(l3);
      }
      if (categorymaps[0] == "l4") {
        localStorage.setItem("previousdata", JSON.stringify(l4));
        localStorage.setItem("nextdata", JSON.stringify(l4));
        setData(l4);
        setSearchedArray(l4);
      }
    }
  }, []);

  const localprevcondition = localStorage.getItem("prevcondition");
  const localnextcondition = localStorage.getItem("nextcondition");

  const getCategoryTree = async () => {
    await CategoryService.getCategoryTree()
      .then(async (res) => {
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              let result = JSON.parse(res);
              if (result.code === 0) {
                localStorage.setItem("l1", JSON.stringify(result.data));
                setPreviousArrayl1([...result.data]);
                if (localprevcondition == 0 || localnextcondition == 1) {
                  console.log("else");
                } else {
                  setPreviousArray([...result.data]);
                  setNextArray([...result.data]);
                  setData([...result.data]);
                  setSearchedArray([...result.data]);

                  localStorage.setItem("previousdata", JSON.stringify(result.data));
                  localStorage.setItem("nextdata", JSON.stringify(result.data));
                }
              } else {
                console.log(result.message);
              }
            });
          } catch (err) {
            console.log(err);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const previousCategory = () => {
    const categorydata = searchedArray;
    const categorymaps = categorydata.map((data) => data.type);

    localStorage.setItem("prevcondition", 0);
    localStorage.removeItem("nextcondition");

    const datal1 = JSON.parse(localStorage.getItem("l1"));
    const datal2 = JSON.parse(localStorage.getItem("l2"));
    const datal3 = JSON.parse(localStorage.getItem("l3"));

    if (categorymaps[0] == "l2") {
      localStorage.setItem("previousdata", JSON.stringify(datal1));
      setData(datal1);
      setSearchedArray(datal1);
    }
    if (categorymaps[0] == "l3") {
      localStorage.setItem("previousdata", JSON.stringify(datal2));
      setData(datal2);
      setSearchedArray(datal2);
    }
    if (categorymaps[0] == "l4") {
      localStorage.setItem("previousdata", JSON.stringify(datal3));
      setData(datal3);
      setSearchedArray(datal3);
    }

    window.location.reload();
    window.scrollTo(0, 0);
  };

  const nextCategory = (nextdata) => {
    newlisting(nextdata);
    const categorydata = nextdata;
    localStorage.removeItem("prevcondition");
    localStorage.setItem("nextcondition", 1);
    // window.location.reload();
    // window.scrollTo(0, 0);
  };

  const editCategory = async (val, id, type, parentId, datas, l1name, l2name, l3name) => {
    console.log("hello");

    setIsEdit(true);
    setDatas(data);
    setL1name(l1name);
    setL2name(l2name);
    setL3name(l3name);
    if (type !== "l1" && type !== "l2") {
      setAddL3(true);
    } else {
      setAddL3(false);
    }
    if (type === "l3") {
      setAddL4(false);
    } else {
      setAddL4(true);
    }
    setParent_id(parentId);
    setCategory(val);
    setAddOrUpdate(false);
    setCategoryId(id);
    setCategoryType(type);
    setEditPopup(!editPopup);
    setDatas(datas);
  };

  const addCategory = async (val, id, type, parentId, datas, l1name, l2name, l3name, item) => {
    if (radius == "") {
      setRadius(0);
    }
    if (deliveryTAT == "") {
      setDeliveryTAT(0);
    }
    if (gstOnPaymentAndNetworkCharges == "") {
      setGstOnPaymentAndNetworkCharges(0);
    }
    if (deliveryChargeOnCustomer == "") {
      setDeliveryChargeOnCustomer(0);
    }
    if (gstOnDeliveryChargeOnCustomer == "") {
      setGSTOnDeliveryChargeOnCustomer(0);
    }
    if (eligibilityForCBDelivery == "") {
      setEligibilityForCBDelivery(0);
    }
    if (paymentAndNetworkCharges == "") {
      setPaymentAndNetworkCharges(0);
    }
    if (gstOnSubscriptionCharges1 == "") {
      setGstOnSubscriptionCharges1(0);
    }
    if (gstOnSubscriptionCharges2 == "") {
      setGstOnSubscriptionCharges2(0);
    }
    if (gstOnSubscriptionCharges3 == "") {
      setGstOnSubscriptionCharges3(0);
    }
    // if (merchantDeliveryCharges == "") {
    //   setMerchantDeliveryCharges(0);
    // }
    if (paymentAndNetworkCharges == "") {
      setPaymentAndNetworkCharges(0);
    }
    // if (subscriptionCharges == "") {
    //   setSubscriptionCharges(0);
    // }
    if (subscriptionCharges1 == "") {
      setSubscriptionCharges1(0);
    }
    if (subscriptionCharges2 == "") {
      setSubscriptionCharges2(0);
    }
    if (subscriptionCharges3 == "") {
      setSubscriptionCharges3(0);
    }
    if (dryRunRadius == "") {
      setDryRunRadius(0);
    }
    setIsEdit(false);
    setL1name(l1name);
    setL2name(l2name);
    setL3name(l3name);
    setDatas(item);
    if (type !== "l1") {
      setAddL3(true);
    } else {
      setAddL3(false);
    }
    if (type === "l3") {
      setAddL4(true);
    } else {
      setAddL4(false);
    }
    setAddOrUpdate(true);
    if (val === "l1") {
      setAddL1(true);
    } else {
      setCategory(val);
      setAddL1(false);
    }
    setParent_id(id);
    setCategoryType(type);
    setEditPopup(!editPopup);
  };

  const handleCancelBtn = () => {
    setEditPopup(!editPopup);
    setAddL3(false);
    window.location.reload();
    // getCategoryTree();
  };

  const addUpdateCategory = async (id, categoryType, parent_id, btn) => {
    let param = {
      categoryName: category,
    };
    param.btn = btn;

    if (btn === "0") {
      let type = categoryType === "l1" ? "l2" : categoryType === "l2" ? "l3" : "l4";
      if (!parent_id) {
        param.type = "l1";
      } else {
        if (type === "l2") {
          param.type = type;
          param.l1CategoryId = parent_id;
        } else if (type === "l3") {
          param.type = type;
          param.l2CategoryId = parent_id;
          param.radius = radius;
          param.categoryImage = categoryImage;
        } else {
          param.type = type;
          param.l3CategoryId = parent_id;
          param.radius = radius;
          // param.merchantDeliveryCharges = merchantDeliveryCharges;
          // param.subscriptionCharges = subscriptionCharges;
          param.categoryDescription = categoryDescription;
          param.dryRunRadius = dryRunRadius;
          param.paymentAndNetworkCharges = paymentAndNetworkCharges;
          // if (classification == "") {
          //   param.classification = 0;
          // } else {
          //   param.classification = classification.value;
          // }
          param.deliveryChargeOnCustomer = deliveryChargeOnCustomer;
          param.gstOnDeliveryChargeOnCustomer = gstOnDeliveryChargeOnCustomer;
          param.eligibilityForCBDelivery = eligibilityForCBDelivery;
          param.gstOnPaymentAndNetworkCharges = gstOnPaymentAndNetworkCharges;
          param.commissionSDPer=commissionSDPer;
          param.subscriptionCharges1 = subscriptionCharges1;
          param.gstOnSubscriptionCharges1 = gstOnSubscriptionCharges1;
          param.subscriptionCharges2 = subscriptionCharges2;
          param.gstOnSubscriptionCharges2 = gstOnSubscriptionCharges2;
          param.subscriptionCharges3 = subscriptionCharges3;
          param.gstOnSubscriptionCharges3 = gstOnSubscriptionCharges3;
          param.chattyBaoHomeDelivery = chattyBaoHomeDelivery === "" ? 0 : chattyBaoHomeDelivery.value;
          param.range1 = range1;
          param.range2 = range2;
          param.range3 = range3;
          param.range4 = range4;
          param.range5 = range5;
          param.regularSettlementCharge = regularSettlementCharge;
          param.vipSettlementCharge = vipSettlementCharge;
        }
      }
    } else {
      //update
      console.log(param);
      console.log(radius);
      if (radius == "") {
        setRadius(0);
      }
      if (deliveryTAT == "") {
        setDeliveryTAT(0);
      }
      if (gstOnPaymentAndNetworkCharges == "") {
        setGstOnPaymentAndNetworkCharges(0);
      }
      if (deliveryChargeOnCustomer == "") {
        setDeliveryChargeOnCustomer(0);
      }
      if (gstOnDeliveryChargeOnCustomer == "") {
        setGSTOnDeliveryChargeOnCustomer(0);
      }
      if (eligibilityForCBDelivery == "") {
        setEligibilityForCBDelivery(0);
      }
      if (paymentAndNetworkCharges == "") {
        setPaymentAndNetworkCharges(0);
      }
      if (gstOnSubscriptionCharges1 == "") {
        setGstOnSubscriptionCharges1(0);
      }
      if (gstOnSubscriptionCharges2 == "") {
        setGstOnSubscriptionCharges2(0);
      }
      if (gstOnSubscriptionCharges3 == "") {
        setGstOnSubscriptionCharges3(0);
      }
      // if (merchantDeliveryCharges == "") {
      //   setMerchantDeliveryCharges(0);
      // }
      if (paymentAndNetworkCharges == "") {
        setPaymentAndNetworkCharges(0);
      }
      // if (subscriptionCharges == "") {
      //   setSubscriptionCharges(0);
      // }
      if (subscriptionCharges1 == "") {
        setSubscriptionCharges1(0);
      }
      if (subscriptionCharges2 == "") {
        setSubscriptionCharges2(0);
      }
      if (subscriptionCharges3 == "") {
        setSubscriptionCharges3(0);
      }
      if (dryRunRadius == "") {
        setDryRunRadius(0);
      }
      let type = categoryType;
      if (!parent_id) {
        param.type = "l1";
        param.id = id;
      } else {
        if (type === "l2") {
          param.id = id;
          param.type = type;
          param.l1CategoryId = parent_id;
        } else if (type === "l3") {
          param.id = id;
          param.type = type;
          param.l2CategoryId = parent_id;
          param.radius = radius ? radius : datas.radius == null || datas.radius == "" ? 0 : datas.radius;
          param.categoryImage = categoryImage ? categoryImage : datas.categoryImage;
        } else {
          param.id = id;
          param.type = type;
          param.l3CategoryId = parent_id;
          param.commissionSDPer=commissionSDPer ? commissionSDPer : datas.commissionSDPer == null || datas.commissionSDPer == "" ? 0 : datas.commissionSDPer;
          param.radius = radius ? radius : datas.radius == null || datas.radius == "" ? 0 : datas.radius;
          // param.merchantDeliveryCharges = merchantDeliveryCharges
          //   ? merchantDeliveryCharges
          //   : datas.merchantDeliveryCharges == "" || datas.merchantDeliveryCharges == null
          //   ? 0
          //   : datas.merchantDeliveryCharges;
          // param.subscriptionCharges = subscriptionCharges ? subscriptionCharges : datas.subscriptionCharges == "" || datas.subscriptionCharges == null ? 0 : datas.subscriptionCharges;
          param.categoryDescription = categoryDescription ? categoryDescription : datas.categoryDescription == "" || datas.categoryDescription == null ? "" : datas.categoryDescription;
          param.dryRunRadius = dryRunRadius ? dryRunRadius : datas.dryRunRadius == "" || datas.dryRunRadius == null ? 0 : datas.dryRunRadius;
          param.paymentAndNetworkCharges = paymentAndNetworkCharges
            ? paymentAndNetworkCharges
            : datas.commissionPer == "" || datas.commissionPer == null
            ? 0
            : datas.commissionPer;
          // console.log(datas.classification);
          // console.log(classification);
          // if ((classification == "" || classification == null) && (datas.classification == null || datas.classification == "")) {
          //   param.classification = 0;
          // } else {
          //   param.classification = classification == "" || classification == null ? datas.classification : classification.value;
          // }

          param.deliveryChargeOnCustomer = deliveryChargeOnCustomer
            ? deliveryChargeOnCustomer
            : datas.deliveryChargeOnCustomer == "" || datas.deliveryChargeOnCustomer == null
            ? 0
            : datas.deliveryChargeOnCustomer;
          param.gstOnDeliveryChargeOnCustomer = gstOnDeliveryChargeOnCustomer
            ? gstOnDeliveryChargeOnCustomer
            : datas.gstOnDeliveryChargeOnCustomer == "" || datas.gstOnDeliveryChargeOnCustomer == null
            ? 0
            : datas.gstOnDeliveryChargeOnCustomer;
          console.log(chattyBaoHomeDelivery);
          const deliveryItem = deliveryOption.find((it) => it.value === datas.chattyBaoHomeDelivery);
          if (deliveryItem == undefined) {
            param.chattyBaoHomeDelivery = 0;
          } else if (chattyBaoHomeDelivery != "") {
            param.chattyBaoHomeDelivery = chattyBaoHomeDelivery.value;
          } else {
            param.chattyBaoHomeDelivery = deliveryItem.value;
          }
          param.eligibilityForCBDelivery = eligibilityForCBDelivery
            ? eligibilityForCBDelivery
            : datas.eligibilityForCBDelivery == "" || datas.eligibilityForCBDelivery == null
            ? 0
            : datas.eligibilityForCBDelivery;
          param.gstOnPaymentAndNetworkCharges = gstOnPaymentAndNetworkCharges
            ? gstOnPaymentAndNetworkCharges
            : datas.gstOnCommissionPer == "" || datas.gstOnCommissionPer == null
            ? 0
            : datas.gstOnCommissionPer;
          param.subscriptionCharges1 = subscriptionCharges1 ? subscriptionCharges1 : datas.subscriptionCharges1 == "" || datas.subscriptionCharges1 == null ? 0 : datas.subscriptionCharges1;
          param.gstOnSubscriptionCharges1 = gstOnSubscriptionCharges1
            ? gstOnSubscriptionCharges1
            : datas.gstOnSubscriptionCharges1 == "" || datas.gstOnSubscriptionCharges1 == null
            ? 0
            : datas.gstOnSubscriptionCharges1;
          param.subscriptionCharges2 = subscriptionCharges2 ? subscriptionCharges2 : datas.subscriptionCharges2 == "" || datas.subscriptionCharges2 == null ? 0 : datas.subscriptionCharges2;
          param.gstOnSubscriptionCharges2 = gstOnSubscriptionCharges2
            ? gstOnSubscriptionCharges2
            : datas.gstOnSubscriptionCharges2 == "" || datas.gstOnSubscriptionCharges2 == null
            ? 0
            : datas.gstOnSubscriptionCharges2;
          param.subscriptionCharges3 = subscriptionCharges3 ? subscriptionCharges3 : datas.subscriptionCharges3 == "" || datas.subscriptionCharges3 == null ? 0 : datas.subscriptionCharges3;
          param.gstOnSubscriptionCharges3 = gstOnSubscriptionCharges3
            ? gstOnSubscriptionCharges3
            : datas.gstOnSubscriptionCharges3 == "" || datas.gstOnSubscriptionCharges3 == null
            ? 0
            : datas.gstOnSubscriptionCharges3;
          param.range1 = range1 ? range1 : datas.range1;
          param.range2 = range2 ? range2 : datas.range2;
          param.range3 = range3 ? range3 : datas.range3;
          param.range4 = range4 ? range4 : datas.range3;
          param.range5 = range5 ? range5 : datas.range5;
          param.regularSettlementCharge = regularSettlementCharge ? regularSettlementCharge : datas.regular_settlement_charge === "" || datas.regular_settlement_charge == null ? 0 : datas.regular_settlement_charge;
          param.vipSettlementCharge = vipSettlementCharge ? vipSettlementCharge : datas.vip_settlement_charge === "" || datas.vip_settlement_charge == null ? 0 : datas.vip_settlement_charge;
        }
      }
    }

    if (category !== "") {
      await CategoryService.addUpdateCategory(param)
        .then(async (res) => {
          if (res.status === 200) {
            try {
              res.text().then(async (res) => {
                let result = JSON.parse(res);
                if (result.code === 0) {
                  // setEditPopup(!editPopup);
                  // getCategoryTree();
                  // setAddL3(false);
                  listing();
                  setOpen(!open);
                  setTimeout(() => {
                    setOpen(false);
                    alertSuccessMessage(`${result.message}`);
                  }, 3000);
                } else {
                }
              });
            } catch (err) {
              console.log(err);
              alertDangerConfirmMessage(`${err}`);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alert("Category name should not be empty");
    }
  };

  const listing = async () => {
    await CategoryService.getCategoryTree()
      .then(async (res) => {
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              let result = JSON.parse(res);
              console.log(result);
              if (result.code === 0) {
                let response = result.data;
                const categorymaps = searchedArray.map((data) => data.type);
                const parentId = searchedArray.map((data) => data.type !== "l1" && data.parent_id);
                console.log("parentId", parentId[0]);

                // This data is for current data update
                const mapdatal2 = [];
                const mapdatal3 = [];
                const mapdatal4 = [];
                const res1 = response.map((itemA) => itemA);
                res1.map((itemB) => itemB.children !== undefined && itemB.children.map((itemC) => itemC.parent_id === parentId[0] && mapdatal2.push(itemC)));

                res1.map(
                  (itemB) =>
                    itemB.children !== undefined &&
                    itemB.children.map((itemC) => itemC.children !== undefined && itemC.children.map((itemD) => itemD.parent_id === parentId[0] && mapdatal3.push(itemD)))
                );

                res1.map(
                  (itemB) =>
                    itemB.children !== undefined &&
                    itemB.children.map(
                      (itemC) =>
                        itemC.children !== undefined &&
                        itemC.children.map((itemD) => itemD.children !== undefined && itemD.children.map((itemE) => itemE.parent_id === parentId[0] && mapdatal4.push(itemE)))
                    )
                );

                console.log("mapdatal2", mapdatal2);
                console.log("mapdatal3", mapdatal3);
                console.log("mapdatal4", mapdatal4);

                if (categorymaps[0] == "l1") {
                  let datal1 = response;
                  setData(datal1);
                  setSearchedArray(datal1);
                  localStorage.setItem("nextdata", JSON.stringify(datal1));
                  localStorage.setItem("previousdata", JSON.stringify(datal1));
                  localStorage.setItem("l1", JSON.stringify(datal1));
                }
                if (categorymaps[0] == "l2") {
                  let datal2 = mapdatal2;
                  setData(datal2);
                  setSearchedArray(datal2);
                  localStorage.setItem("l2", JSON.stringify(datal2));
                  localStorage.setItem("nextdata", JSON.stringify(datal2));
                  localStorage.setItem("previousdata", JSON.stringify(datal2));
                }
                if (categorymaps[0] == "l3") {
                  let datal3 = mapdatal3;
                  setData(datal3);
                  setSearchedArray(datal3);
                  localStorage.setItem("l3", JSON.stringify(datal3));
                  localStorage.setItem("nextdata", JSON.stringify(datal3));
                  localStorage.setItem("previousdata", JSON.stringify(datal3));
                }
                if (categorymaps[0] == "l4") {
                  let datal4 = mapdatal4;
                  setData(datal4);
                  setSearchedArray(datal4);
                  localStorage.setItem("l4", JSON.stringify(datal4));
                  localStorage.setItem("nextdata", JSON.stringify(datal4));
                  localStorage.setItem("previousdata", JSON.stringify(datal4));
                } else {
                  console.log("null category");
                }
              } else {
                console.log(result.message);
              }
            });
          } catch (err) {
            console.log(err);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const newlisting = async (nextdata) => {
    await CategoryService.getCategoryTree()
      .then(async (res) => {
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              let result = JSON.parse(res);
              console.log(result);
              if (result.code === 0) {
                let response = result.data;
                const categorymaps = nextdata.map((data) => data.type);
                const parentId = nextdata.map((data) => data.type !== "l1" && data.parent_id);
                console.log("parentId", parentId[0]);

                // This data is for current data update
                const mapdatal2 = [];
                const mapdatal3 = [];
                const mapdatal4 = [];
                const res1 = response.map((itemA) => itemA);
                res1.map((itemB) => itemB.children !== undefined && itemB.children.map((itemC) => itemC.parent_id === parentId[0] && mapdatal2.push(itemC)));

                res1.map(
                  (itemB) =>
                    itemB.children !== undefined &&
                    itemB.children.map((itemC) => itemC.children !== undefined && itemC.children.map((itemD) => itemD.parent_id === parentId[0] && mapdatal3.push(itemD)))
                );

                res1.map(
                  (itemB) =>
                    itemB.children !== undefined &&
                    itemB.children.map(
                      (itemC) =>
                        itemC.children !== undefined &&
                        itemC.children.map((itemD) => itemD.children !== undefined && itemD.children.map((itemE) => itemE.parent_id === parentId[0] && mapdatal4.push(itemE)))
                    )
                );

                console.log("mapdatal2", mapdatal2);
                console.log("mapdatal3", mapdatal3);
                console.log("mapdatal4", mapdatal4);

                if (categorymaps[0] == "l1") {
                  let datal1 = response;
                  setData(datal1);
                  setSearchedArray(datal1);
                  localStorage.setItem("l1", JSON.stringify(datal1));
                  localStorage.setItem("nextdata", JSON.stringify(datal1));
                  localStorage.setItem("previousdata", JSON.stringify(datal1));
                }
                if (categorymaps[0] == "l2") {
                  let datal2 = mapdatal2;
                  setData(datal2);
                  setSearchedArray(datal2);
                  localStorage.setItem("l2", JSON.stringify(datal2));
                  localStorage.setItem("nextdata", JSON.stringify(datal2));
                  localStorage.setItem("previousdata", JSON.stringify(datal2));
                }
                if (categorymaps[0] == "l3") {
                  let datal3 = mapdatal3;
                  setData(datal3);
                  setSearchedArray(datal3);
                  localStorage.setItem("l3", JSON.stringify(datal3));
                  localStorage.setItem("nextdata", JSON.stringify(datal3));
                  localStorage.setItem("previousdata", JSON.stringify(datal3));
                }
                if (categorymaps[0] == "l4") {
                  let datal4 = mapdatal4;
                  setData(datal4);
                  setSearchedArray(datal4);
                  localStorage.setItem("l4", JSON.stringify(datal4));
                  localStorage.setItem("nextdata", JSON.stringify(datal4));
                  localStorage.setItem("previousdata", JSON.stringify(datal4));
                }
                window.location.reload();
                window.scrollTo(0, 0);
              } else {
                console.log(result.message);
              }
            });
          } catch (err) {
            console.log(err);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const searchdata = (e) => {
    if (e.target.value === "") {
      setSearchedArray(data);
    } else {
      const searchedObjects = [];
      data.forEach((singleHeroObject, index) => {
        if (singleHeroObject.name.toLowerCase().includes(e.target.value.toLowerCase())) {
          searchedObjects.push(singleHeroObject);
          return;
        }
      });
      setSearchedArray(searchedObjects);
    }
  };

  console.log("searchedArray", searchedArray);
  return (
    <Container fluid>
      <Row>
        <Col xs={2} id="sidebar-wrapper">
          <Sidebar />
        </Col>
        <Col xs={10} id="page-content-wrapper">
          <div className="App">
            <h1 className="center">Category Tree</h1>
            <Container className="center"></Container>
            {/* <App /> */}
            <div className="">
              <Container className="left bg-style ">
                <form className="mg-left-50 pl-4 py-4">
                  <Button onClick={() => addCategory("l1", "", "l1")} className="custom-add-btn px-4 ">
                    Add L1Category
                  </Button>
                  <div className="search">
                    <TextField
                      id="outlined-basic"
                      type="search"
                      variant="outlined"
                      placeholder="Search by Category"
                      value={searchString}
                      onChange={(e) => {
                        setSearchString(e.target.value);
                        searchdata(e);
                      }}
                      // InputProps={{
                      //   startAdornment: (
                      //     <InputAdornment>
                      //       <SearchIcon />
                      //     </InputAdornment>
                      //   ),
                      // }}
                    />
                  </div>
                </form>
              </Container>

              <div style={{ display: "flex", width: "100%", padding: "35px 5px 5px 98px", color: "#1976d2", fontSize: "22px", cursor: "pointer" }}>
                <h5 onClick={() => previousCategory()}>
                  {heading && heading.type === "l2" && <ArrowBackIosIcon />} {heading && heading.type === "l2" && "L1"}
                </h5>
                <h5 onClick={() => previousCategory()}>
                  {heading && heading.type === "l3" && <ArrowBackIosIcon />} {heading && heading.type === "l3" && "L2"}
                </h5>
                <h5 onClick={() => previousCategory()}>
                  {heading && heading.type === "l4" && <ArrowBackIosIcon />} {heading && heading.type === "l4" && "L3"}
                </h5>
              </div>

              <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", width: "100%", padding: "30px 5px 5px 5px", justifyItems: "center" }}>
                {searchedArray.length > 0 &&
                  searchedArray.map((parentdata, index) => (
                    <Card style={{ margin: "0px 0px 40px 0px", width: "90%", maxWidth: "90%", fontSize: "22px" }} key={index}>
                      <CardHeader
                        style={{ display: "grid", justifyContent: "center", gridTemplateColumns: "1fr 1fr 1fr" }}
                        avatar={
                          <Avatar sx={{ bgcolor: red[500] }} aria-label="data">
                            {parentdata.type === "l1" && "L1"}
                            {parentdata.type === "l2" && "L2"}
                            {parentdata.type === "l3" && "L3"}
                            {parentdata.type === "l4" && "L4"}
                          </Avatar>
                        }
                        action={
                          parentdata.name !== "" ? (
                            <div className="category_header">
                              <div>
                                <EditIcon
                                  style={{ cursor: "pointer" }}
                                  className="mg-left-1 icons"
                                  onClick={() =>
                                    editCategory(parentdata.name, parentdata.id, parentdata.type, parentdata.parent_id, parentdata.datas, parentdata.l1name, parentdata.l2name, parentdata.l3name)
                                  }
                                />
                              </div>
                              {parentdata.type !== "l4" ? (
                                <div>
                                  <AddCircleIcon
                                    className="icons"
                                    onClick={() =>
                                      addCategory("", parentdata.id, parentdata.type, parentdata.parent_id, parentdata.radius, parentdata.l1name, parentdata.l2name, parentdata.l3name, parentdata)
                                    }
                                  />
                                </div>
                              ) : (
                                <div></div>
                              )}

                              {parentdata.children !== undefined && parentdata.children.length > 0 && (
                                <div>
                                  <ArrowForwardIosIcon
                                    style={{ color: "grey", cursor: "pointer" }}
                                    onClick={() => {
                                      setSearchedArray(parentdata.children !== undefined ? parentdata.children : window.location.reload());
                                      setData(parentdata.children !== undefined ? parentdata.children : window.location.reload());
                                      setSearchString("");
                                      localStorage.setItem("nextdata", JSON.stringify(parentdata.children));
                                      nextCategory(parentdata.children);
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          ) : (
                            <div></div>
                          )
                        }
                        title={parentdata.name}
                      />
                    </Card>
                  ))}
              </div>
            </div>

            {editPopup ? (
              <EditCategory
                isModal={editPopup}
                handleCancelBtn={handleCancelBtn}
                categoryName={category}
                categoryId={categoryId}
                categoryType={categoryType}
                parent_id={parent_id}
                addOrUpdate={addOrUpdate}
                isEdit={isEdit}
                datas={datas}
                addL3={addL3}
                addL4={addL4}
                addL1={addL1}
                // datas={datas}
                l1name={l1name}
                l2name={l2name}
                l3name={l3name}
                addUpdateCategory={addUpdateCategory}
                handleCategoryName={handleCategoryName}
                handleRange1={handleRange1}
                handleRange2={handleRange2}
                handleRange3={handleRange3}
                handleRange4={handleRange4}
                handleRange5={handleRange5}
                handleRadius={handleRadius}
                // handleMerchantDeliveryCharges={handleMerchantDeliveryCharges}
                // handleSubscriptionCharges={handleSubscriptionCharges}
                handleChattyBaohomeDelivery={handleChattyBaohomeDelivery}
                handleDeliveryTAT={handleDeliveryTAT}
                handleCategoryDescription={handleCategoryDescription}
                handleDryRunRadius={handleDryRunRadius}
                handlePaymentAndNetworkCharges={handlePaymentAndNetworkCharges}
                // handleClassification={handleClassification}
                handleDeliveryChargeOnCustomer={handleDeliveryChargeOnCustomer}
                handleGSTOnDeliveryChargeOnCustomer={handleGSTOnDeliveryChargeOnCustomer}
                handleEligibilityForCBDelivery={handleEligibilityForCBDelivery}
                handleGstOnPaymentAndNetworkCharges={handleGstOnPaymentAndNetworkCharges}
                handleCommissionSDPer={handleCommissionSDPer}
                handleSubscriptionCharges1={handleSubscriptionCharges1}
                handleGSTonsubscriptioncharge1={handleGSTonsubscriptioncharge1}
                handleSubscriptionCharges2={handleSubscriptionCharges2}
                handleGSTonsubscriptioncharge2={handleGSTonsubscriptioncharge2}
                handleSubscriptionCharges3={handleSubscriptionCharges3}
                handleGSTonsubscriptioncharge3={handleGSTonsubscriptioncharge3}
                handleRegularSettlementCharge={handleRegularSettlementCharge}
                handleVipSettlementCharge={handleVipSettlementCharge}
                handleImageDetails={handleImageDetails}
                childVisible={!childVisible}
              />
            ) : null}
          </div>
        </Col>
      </Row>

      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}
