import React, { useContext, useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import { NavLink } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { AppBar } from "@material-ui/core";
import user from "../images/user.png";
import Moment from 'react-moment';
import 'moment-timezone';


import Logo from "../chatty_bao_logo.png";
// import UserList from "../components/home";
// import ContactList from "../components/home";
import useStyles from './styles';


const DashboardPage = props => {
  //const { window, logout } = props;
  const history = useHistory();
  // console.log(window.sessionStorage.getItem('adminMobileNumber'));




  const servicesRef = useRef();

  const classes = useStyles();
  const theme = useTheme();

  const [mobileOpen, setMobileOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [items, setItems] = useState([]);
  const [admin, setAdmin] = useState("");
  var mobile = window.sessionStorage.getItem('adminMobileNumber');

  setInterval(() => {
    var loginTimeStamp = sessionStorage.getItem('logintime');
    var now = Date.now();
    //var ms = Moment(now,"MM/DD/YYYY HH:mm:ss").diff(Moment(loginTimeStamp,"MM/DD/YYYY HH:mm:ss"));
    if ((loginTimeStamp != undefined)||(loginTimeStamp != null) ||(loginTimeStamp != 'null') || (loginTimeStamp != ' ')) {
      var hours = Math.abs(now - loginTimeStamp) / 36e5;
     
      if (hours > 8) { 
        history.push("/logout");
      }
    }
    }, 5000);

useEffect(() => {


  const items = JSON.parse(localStorage.getItem('adminMobileNumber'));
  if (items) {
    setItems(items);
  }
  if (window.sessionStorage.getItem('sessionId') == undefined) {
    history.push("/");
  } else {
    console.log(mobile);
    setAdmin(mobile.toString());
  }
}, []);

const home = () => {

}

const handleDrawerToggle = () => {
  setMobileOpen(!mobileOpen);
};

const onItemClick = title => {
  setTitle(title);
};

const onKeyPress = (eKey, item) => {
  if (eKey.key === "Enter") {
    onItemClick(item);
  }
};


const drawer = (
  <div>
    <div className={classes.logoDiv}>
      <img className={classes.logo} src={Logo} />
    </div>
    {window.sessionStorage.getItem('categoryPanel') == 0 ? <NavLink to={"/category"} className={isActive => "nav-link" + (!isActive ? " unselected  px-4" : "")} >
      <h5 className="h5-small">Category</h5>
    </NavLink> : ''}
    {window.sessionStorage.getItem('qcPanel') == 0 ? <NavLink to={"/chat"} className={isActive => "nav-link" + (!isActive ? " unselected  px-4" : "")} >
      <h5 className="h5-small">Chat</h5>
    </NavLink> : ''}
    {window.sessionStorage.getItem('categoryPanel') == 0 ? <NavLink to={"/CBemp"} className={isActive => "nav-link" + (!isActive ? " unselected  px-4" : "")} >
      <h5 className="h5-small">CB Employee</h5>
    </NavLink> : ''}
    { window.sessionStorage.getItem('categoryPanel') ==0?   <NavLink to={"/logistics"} className={isActive =>"nav-link" + (!isActive ? " unselected" : "")}>
            <h5 className="h5-small">Logistics</h5>
    </NavLink>:''}
    {window.sessionStorage.getItem('categoryPanel') == 0 ? <NavLink to={"/merchant"} className={isActive => "nav-link" + (!isActive ? " unselected" : "")}>
      <h5 className="h5-small">Merchant</h5>
    </NavLink> : ''}
    { window.sessionStorage.getItem('categoryPanel') ==0?   <NavLink to={"/Qcuser"} className={isActive =>"nav-link" + (!isActive ? " unselected" : "")}>
            <h5 className="h5-small">Qc User</h5>
    </NavLink>:''}
    {window.sessionStorage.getItem('qcPanel') == 0 ? <NavLink to={"/qc"} className={isActive => "nav-link" + (!isActive ? " unselected" : "")}>
      <h5 className="h5-small">Qc Panel</h5>
    </NavLink> : ''}
    <NavLink to={"/BU_summary"} className={isActive => "nav-link" + (!isActive ? " unselected" : "")}>
      <h5 className="h5-small">Business Summary</h5>
    </NavLink>
    <NavLink to={"/search"} className={isActive => "nav-link" + (!isActive ? " unselected" : "")}>
      <h5 className="h5-small">Order Search</h5>
    </NavLink>
    <NavLink to={"/Payout"} className={isActive => "nav-link" + (!isActive ? " unselected" : "")}>
      <h5 className="h5-small">Payout</h5>
    </NavLink>
    <NavLink to={"/vip-history"} className={isActive => "nav-link" + (!isActive ? " unselected" : "")}>
      <h5 className="h5-small">VIP History</h5>
    </NavLink>
    <NavLink to={"/delivery-dashboard"} className={isActive => "nav-link" + (!isActive ? " unselected" : "")}>
      <h5 className="h5-small">Delivery Dashboard</h5>
    </NavLink>
    <NavLink to={"/be-mapping"} className={isActive => "nav-link" + (!isActive ? " unselected" : "")}>
      <h5 className="h5-small">BE Mapping</h5>
    </NavLink>
    <NavLink to={"/logout"} className={isActive => "nav-link" + (!isActive ? " unselected" : "")}>
      <h5 className="h5-small">Logout</h5>
    </NavLink>
  </div>
);

// const container =
//   window !== undefined ? () => window().document.body : undefined;

return (
  <div className={classes.root}>
    <CssBaseline />
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <Grid justify={"space-between"} className={classes.appBarGrid}>
          <Grid item tabIndex="-1">
          </Grid>
          <Grid item className={classes.infoGrid}>
            {items}
            {/* <Button
                variant="contained"
                disableElevation
                className={classes.button}
                onClick={logout}
                startIcon={<ExitToAppIcon />}
              >
                Logout
              </Button> */}
            <img src={user} width='50px'></img>
            <h1 className="admin_deatails">{window.sessionStorage.getItem('categoryPanel') == 0 ? "Admin" : "Qc user"}<br></br>{mobile}</h1>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>

    <nav className={classes.drawer} id="nav" role="navigation">
      <Hidden smUp implementation="css">
        <Drawer

          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper
          }}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
    <main className={classes.content} ref={servicesRef} id="content" role="main">

      {/* { title === "Contact list" ? (
          <ContactList className={classes.childComponent}></ContactList>
        ): 
          <UserList className={classes.childComponent}></UserList>
        
        
        } */}

    </main>

  </div>
);
};

DashboardPage.propTypes = {

};

DashboardPage.defaultProps = {

};

export default DashboardPage;
