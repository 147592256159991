import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Form, Button, Modal } from "react-bootstrap";
import { withRouter } from "react-router";
import Sidebar from "../../components/sidebar.js";
import '../../Dashboard.css';
import right from "../../images/check.png";
import wrong from "../../images/close.png";
import ask from "../../images/ask.png";
import axios from "axios";
import '../style/Qc.css';
import "moment-timezone";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Typography, TextField } from "@mui/material";
import Select from "react-select";
import shopimagecheck from "./shopimagecheck";
import Moment from 'react-moment';


const Shopboard = (props) => {
  const [shopphoto, setShopphoto] = useState("");
  const [showShopPhoto, setShowshopPhoto] = useState(false);
  const [shopName, setShopName] = useState("");
  const [newShopName, setnewShopName] = useState("");
  const [showShopBoard, setshowShopBoard] = useState(false);
  const [business_qc, setBusiness_qc] = useState();
  const [MerchantidCheck, setMerchantidCheck] = useState("");
  const [TicketNoCheck, setTicketNoCheck] = useState("");
  const [errormsgShopboard, seterrormsgShopboard] = useState("");
  const [l1chategory, setL1chategory] = useState("Fixed Shop");
  const [selectReason, setSelectReason] = useState();
  const [rejected, setRejected] = useState(false);
  // const [value, setValue] = React.useState(Reason[0]);
  const [rightImage, setRightImage] = useState(false);
  const [remark, setRemark] = useState(props.Remark);
  const [sec2_reason, setSec2_reason] = useState("");
  const [updatedTimestamp, setupdatedTimestamp] = useState("");
  const [status, setstatus] = useState("");
  const [disable, setdisable] = useState(true);
  const [mobileNo,setMobileNo]=useState("");
  const [category_id, setCategory_id] = useState("");
  const [agent_id, setAgent_id] = useState("")
  const Resturant_id = "8e827a10-737b-11ec-9806-93163a488dc9";

  const CakeAndBakery_id = "c205e5d0-737f-11ec-9806-93163a488dc9";
  const SweetShop_id = "57991b70-7381-11ec-9806-93163a488dc9";
  
  // 
  const handleCloseShopPhoto = () => setShowshopPhoto(false);
  const handleShowShopPhoto = () => setShowshopPhoto(true);
  const reason_shopboard = [
    { value: 0, label: "Abusive words" },
    { value: 1, label: "Popular brand names" },
    { value: 2, label: "Inappropriate words photo" },
    { value: 3, label: "Others" }
  ];

  const sec2_reasonChange = (item) => {
    setSelectReason(item.label)
  }
  React.useEffect(() => {
    setL1chategory(props.data.l1_categoryname);
    if (props.data.l1_categoryname == "Bill Collection") {
      setL1chategory('Fixed Shop')
    }
    setShopphoto(props.data.picurl);
    setShopName(props.data.shopname);
    setnewShopName(props.data.shopname);
    setTicketNoCheck(props.data.ticket_no);
    setMerchantidCheck(props.match.params.id);
    setupdatedTimestamp(props.data.sec2_updated_at);
    setstatus(props.data.business_qc);
    setSec2_reason(props.data.sec2_reason);
    setRemark(props.Remark);
    setMobileNo(props.data.mainmobilenumber);
    setAgent_id(props.data.agent_id);
    if (props.data.categoryl3 == SweetShop_id || props.data.categoryl3 == CakeAndBakery_id || props.data.categoryl3 == Resturant_id) {
      setCategory_id(props.data.categoryl3);
    } else {
      setCategory_id('');
    }
    //setRemark(props.data.remark);
  }, [props]);

  const handleAprove = (event) => {
    setshowShopBoard(false);
    setRejected(false);
    setRightImage(true);
    setBusiness_qc(0);
   // setSelectReason('');
    setdisable(false);
    seterrormsgShopboard("");
  };

  const handleReject = (event) => {
    setshowShopBoard(true);
    setRejected(true);
    setRightImage(false);
    setBusiness_qc(1);
    setdisable(false);
    seterrormsgShopboard("");
    if (rejected === true) {
      setshowShopBoard(false);
      setRejected(false);
    }
  };

  const misMatchShopImage = (event) => {
    setshowShopBoard(true);
    setRightImage(false);
    setdisable(false);
    if (showShopBoard === true) {
      setshowShopBoard(false);
    }
    setBusiness_qc(1);
    setnewShopName("");
    setSelectReason('');
  };

  const rightShopImage = (event) => {
    setBusiness_qc(0);
    setshowShopBoard(false);
    setnewShopName(shopName);
    seterrormsgShopboard("");
    setRightImage(true);
    setdisable(false);
  };
  const wrongShopImage = (event) => {
    setBusiness_qc(0);
    setshowShopBoard(false);
    setnewShopName(shopName);
    seterrormsgShopboard("");
    setRightImage(false);
    setdisable(false);
  };
  const handleChangeShopName = (event) => {
    setnewShopName(event.target.value);
    seterrormsgShopboard("");
  };
  const shopBoardFinal = (event) => {
    if (newShopName.trim().length === 0) {
      seterrormsgShopboard("Enter Shopboard Name");
    }
   else if (((selectReason == undefined) || (selectReason == '')) && (l1chategory !== "Fixed Shop") && (business_qc == 1)) {

      seterrormsgShopboard("Select reason");
    } else {
      const headers = {
        "Content-Type": "application/json",
      };
      const qc_panelStage1 = {
        ticket_no: TicketNoCheck,
        merchantid: MerchantidCheck,
        shopname: newShopName,
        status: business_qc,
        reason: selectReason,
        type: l1chategory,
        remark: remark,
        mobileNo:mobileNo,
        category_id: category_id,
        agent_id: agent_id
      };
      console.log(qc_panelStage1);
      axios
        .post(window.baseUrl + `updateStage2`, qc_panelStage1, {
          headers: headers,
        })
        .then((res) => {
          console.log(res.data);
          console.log(res.data.message);
          window.location.reload();
        });
    }
  };

  const handlesubmit = () => {
    if (business_qc == 0) {

      setSelectReason('');
    }
    shopBoardFinal();

  };
  return (
    <>
      {l1chategory === "Fixed Shop" ? (
        <div className="background_validation pb-2">
          <Row>
            <Col className="mt-4 ">
              <div className=" pt-2 header">
                <b className="pt-3 title">Section -2: Verify business name</b>
                <textarea className="input_business_name" type="text" value={shopName}>{shopName}</textarea>

                <div className="d-inline-block float-right w-25 mt-3 border border-secondary">
                  <div className="d-flex flex-row border-bottom border-secondary last_update">
                    <p className="pl-2 mb-0"><b>Last Update</b></p>

                  </div>
                  <div className="d-flex flex-row mt-2">
                    <p className="pl-2">Status : </p>
                    <p>{status == 0 ? "Match" : status == 1 ? "No match" : "New"}</p>
                  </div>
                  <div className="d-flex flex-row">
                    <p className="pl-2">Reason : </p>
                    <p style={{ width: '70%' }}>{sec2_reason == null ? 'Null' : sec2_reason}</p>
                  </div>
                  <div className="d-flex flex-row">
                    <p className="pl-2">Timestamp : </p>
                    <p>{updatedTimestamp == null ? 'Null' : <Moment format="DD/MM/YYYY">{updatedTimestamp}</Moment>}</p>
                  </div>
                </div>
              </div>

              <div className="section_instruction instruction  position-absolute ">
                <b>Instructions:</b> <span>If name is visible on shopboard, match business name given above with the name mentioned on the shopboard image below</span>
              </div>
            </Col>
          </Row>

        <br/>
        <br/>
          <Row>
            <Col md={3} className="">
              <div className="color_border_validation height_cheque  position-absolute">
                <img className="w-100 h-100  cursor-pointer" src={shopphoto} onClick={handleShowShopPhoto}></img>
              </div>
            </Col>
            <Col md={1} className='pb-3'>
              {/* <div className="mt-4 image_validation">
                <img className="image_right cursor-pointer" src={right} onClick={rightShopImage}></img>
              </div> */}
              <div className="image_wrong_div mt-4 mx-auto" onClick={rightShopImage} style={{ backgroundColor: rightImage == true ? "#62FDA0 " : "" }}>
                <img className="image_wrong cursor-pointer" src={right} ></img>
              </div>
              <div className="text-center pt-2 pb-4">
                <b>Match</b>
              </div>
            </Col>
            {/* <Col md={2}>
            //   <div className=" mt-4 image_validation">
            //     <img className="image_right cursor-pointer" src={ask} onClick={wrongShopImage}></img>
            //   </div>
            //   <div className="text-center pt-2">Does not exist</div>
            // </Col> */}
            <Col md={1} className='pb-3'>
              <div className="image_wrong_div mt-4 image_validation " style={{ backgroundColor: showShopBoard == true ? "#FD626B " : "" }}>
                <img className="image_wrong cursor-pointer" src={wrong} onClick={misMatchShopImage}></img>
              </div>
              <Typography style={{ width: "215px", marginTop: "8px",fontSize:"0.875rem" }} noWrap>
                <b>No match /Partial match</b>
              </Typography>
            </Col>
          </Row>
          <Row>
            <Col md={7} className="mt-5">
              <div className="shopboard_input">
                <h6 style={{ display: showShopBoard ? "block" : "none" }}>Enter name as per shopboard</h6>
                <div>
                  <input
                    id="msg"
                    type="text"
                    className="pt-3 pb-3 pl-5 pr-5 form-control"
                    name="shopboard"
                    // placeholder="Enter name as per shopboard"
                    maxlength = "40"
                    style={{ textTransform:'uppercase',display: showShopBoard ? "block" : "none" }}
                    onChange={handleChangeShopName}
                  ></input>
                </div>
              </div>
            </Col>

            <Col className="mt-5" style={{ display: "grid", justifyContent: "end" }}>
              <span>
                <button className="btn" disabled={disable} onClick={shopBoardFinal}>
                  Submit
                </button>
              </span>
              <p className="text-danger text-center pt-3">{errormsgShopboard}</p>
            </Col>
          </Row>

          <Modal show={showShopPhoto} onHide={handleCloseShopPhoto}>
            <Modal.Body>
              <TransformWrapper>
                <TransformComponent>
                  <img className="w-100 h-100" src={shopphoto}></img>
                </TransformComponent>
              </TransformWrapper>
            </Modal.Body>
          </Modal>
        </div>
      ) : (
        <div className="background_validation pb-2">
          <Row>
            <Col className="  mt-4">
              <div className="pb-3 pt-2 header">
                <b className="pt-3 title">Section -2: Verify business name</b>
                <input className="input_business_name" type="text" value={shopName}></input>

                <div className="d-inline-block float-right w-25 mt-3 border border-secondary">
                  <div className="d-flex flex-row border-bottom border-secondary last_update">
                    <p className="pl-2 mb-0"><b>Last Update</b></p>

                  </div>
                  <div className="d-flex flex-row mt-2">
                    <p className="pl-2">Status : </p>
                    <p>{status == 0 ? "Match" : status == 1 ? "Resubmit" : "New"}</p>
                  </div>
                  <div className="d-flex flex-row">
                    <p className="pl-2">Reason : </p>
                    <p style={{ width: '70%' }}>{sec2_reason == null ? 'Null' : sec2_reason}</p>
                  </div>
                  <div className="d-flex flex-row">
                    <p className="pl-2">Timestamp : </p>
                    <p>{updatedTimestamp == null ? 'Null' : <Moment format="DD/MM/YYYY">{updatedTimestamp}</Moment>}</p>
                  </div>
                </div>
              </div>

              <div className=" section_instruction1 instruction_1 position-absolute">
                <b> Instructions:</b> <span>Check for abusive or inappropriate words</span>
              </div>
            </Col>
          </Row>

          <Row className="pl-3">

          </Row>

          <Row>
            <Col md={1}>
              {/* <div className="mt-4 image_validation">
                <img className="image_right cursor-pointer" src={right} onClick={handleAprove}></img>
              </div> */}
              <div className="image_wrong_div mt-4  mx-auto"  style={{ backgroundColor: rightImage == true ? "#62FDA0 " : "" }}>
                <img className="image_wrong cursor-pointer" src={right} onClick={handleAprove} ></img>
              </div>
              <div className="text-center pt-2">
                <b>Approve</b>
              </div>
            </Col>

            <Col md={1}>
              <div className="image_wrong_div mt-4 image_validation " style={{ backgroundColor: showShopBoard == true ? "#FD626B " : "" }}>
                <img className="image_wrong cursor-pointer" src={wrong} onClick={handleReject}></img>
              </div>
              <div className="text-center pt-2">
                <b>Rejected</b>
              </div>
            </Col>
            {rejected && (
              <Col className="mt-4" >
                <Select options={reason_shopboard} className="float-right reason_shopboard" onChange={sec2_reasonChange}></Select>

                {/* <Select options={reason_shopboard} className="float-right reason_shopboard" onChange={sec2_reasonChange}></Select> */}
              </Col>
            )}
          </Row>

          <Row style={{ display: "grid", justifyContent: "end" }}>
            <Col>
              <span>
                <button className="btn" disabled={disable} onClick={handlesubmit}>
                  Submit
                </button>
              </span>
              <p className="text-danger text-center pt-3">{errormsgShopboard}</p>
            </Col>
          </Row>

          <Modal show={showShopPhoto} onHide={handleCloseShopPhoto}>
            <Modal.Body>
              <TransformWrapper>
                <TransformComponent>
                  <img className="w-100 h-100" src={shopphoto}></img>
                </TransformComponent>
              </TransformWrapper>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
};

const Dashboard = withRouter(Shopboard);
export default Dashboard;
