import React from 'react';
import Home from './components/home.js';
import Category from './pages/Category.js';
import Chat from './pages/Chat.js';
import Logistics from './pages/Logistics.js';
import Login from './components/login.js';
import Sidebar from './components/sidebar.js';
import Dashboard from './components/merchantList.js';
import MerchantDetails from './components/merchentDetails.js';
import Editdetails from './components/editmerchentDetails.js';
import Logout from './components/logout.js';
import qcValidation from './qcpanel/qcValidation.js';
import qc from './qcpanel/components/QClist.js';
import cpanel from './cpanel/cpanel.js';
import AddEmployee from './CBemployee/addEmployee.js';
import Offer from "./pages/offer"
import Bu_summary from "./pages/home"
import Search from "./pages/searchOrder"
import Qcuser from './qcpanel/Qcuser.js';
import BEmapping from './pages/BEmapping';

import {Router, Route, Switch, withRouter } from "react-router-dom";
import './App.scss';
import {createBrowserHistory} from 'history';
import UploadImageToS3WithReactS3 from './components/UploadImageToS3WithReactS3.js';
import Payout from './pages/payout';
import VipHistory from './pages/VipHistory';
import deliveryDashboard from './pages/deliveryDashboard.js';
import DeliveryTC from './pages/offer/DeliveryTc';
export const customHistory = createBrowserHistory();
function App(){
  // return <div className='App'>
  //   <Login/>
  // </div>;
   const history = createBrowserHistory();
  return (
    <Router history={customHistory}>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/sidebar" component={Sidebar} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/category" component={Category} />
          <Route exact path="/chat" component={Chat} />
          <Route exact path="/logistics" component={Logistics} />
          <Route exact path="/Qcuser" component={Qcuser} />
          <Route exact path="/merchant" component={Dashboard} />
          <Route exact path="/merchentDetails/:id" component={MerchantDetails} />
          <Route exact path="/qcValidation/:id/:ticket" component={qcValidation} />
          <Route exact path="/editmerchentDetails/:id" component={Editdetails} />
          <Route exact path="/upload" component={UploadImageToS3WithReactS3} />
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/qc" component={qc} />
          <Route exact path="/address" component={cpanel} />
          <Route exact path="/CBemp" component={AddEmployee}/>
          <Route exact path="/CBemp" component={AddEmployee}/>
          <Route exact path="/offer/usercashback" component={Offer}/>
          <Route exact path="/offer/freedelivery" component={DeliveryTC}/>
          <Route exact path="/Bu_summary" component={Bu_summary}/>
          <Route exact path="/search" component={Search}/>
          <Route exact path="/Payout" component={Payout}/>
          <Route exact path="/vip-history" component={VipHistory}/>
          <Route exact path="/delivery-dashboard" component={deliveryDashboard}/>
          <Route exact path="/be-mapping" component={BEmapping}/>
        </Switch>
      </Router>
  );
}

export default App;