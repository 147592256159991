import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Form, Modal } from "react-bootstrap";
import { withRouter } from "react-router";
import Sidebar from "../../components/sidebar.js";
import '../../Dashboard.css';
import right from "../../images/check.png";
import wrong from "../../images/close.png";
import ask from "../../images/ask.png";
import resubmit from "../../images/resubmit.png";
import '../style/Qc.css';
import axios from 'axios';
import DatePicker from "react-datepicker";
import Moment from 'react-moment';
import 'moment-timezone';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import "react-datepicker/dist/react-datepicker.css";
import { Typography, TextField, Button } from "@mui/material";
import Select from "react-select";
import { sanitizeFetchType } from "react-admin";



const BusinessName = (props) => {
  const [shopphoto, setShopphoto] = useState("");
  const [shopName, setShopName] = useState("");
  const [licencephoto, setLicencephoto] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [license_qc, setlicense_qc] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [licensenumber, setLicenscenumber] = useState("");
  const [showShopPhoto, setShowshopPhoto] = useState(false);
  const [showLicencesPhoto, setShowLicencesPhoto] = useState(false);
  const [TicketNoLicense, setTicketNoLicense] = useState("");
  const [MerchantidLicense, setMerchantidLicense] = useState("");
  const [errormsgbusinessname, seterrormsgbusinessname] = useState("");
  const [rejected, setRejected] = useState(false);
  const [approved, setApproved] = useState(false);
  const [showShopBoard, setshowShopBoard] = useState(false);
  const [rightImage, setRightImage] = useState(false);
  const [remark, setRemark] = useState(props.Remark);
  const [type, setType] = useState();
  const [sec4_reason, setSec4_reason] = useState("");
  const [updatedTimestamp, setupdatedTimestamp] = useState("");
  const [status, setstatus] = useState("");
  const [selectReason, setSelectReason] = useState();
  const [disable, setdisable] = useState(true);
  const [mobileNo,setMobileNo]=useState("");
  const [agent_id, setAgent_id] = useState("");
  

  const [l1chategory, setL1chategory] = useState("Fixed Shop");
  const [category_id, setCategory_id] = useState("");
 
  const CakeAndBakery_id = "c205e5d0-737f-11ec-9806-93163a488dc9";
  const SweetShop_id = "57991b70-7381-11ec-9806-93163a488dc9";

  const handleCloseShopPhoto = () => setShowshopPhoto(false);
  const handleShowShopPhoto = () => setShowshopPhoto(true);
  const handleCloseLicencesPhoto = () => setShowLicencesPhoto(false);
  const handleShowLicencesPhoto = () => setShowLicencesPhoto(true);
  const Resturant_id = "8e827a10-737b-11ec-9806-93163a488dc9";
  const reason_licence = [
    { value: 0, label: "Details not matching" },
    { value: 1, label: "Photo is  hazy" },
    { value: 2, label: "Photo has incomplete details" },
    { value: 3, label: "Wrong photo" },
    { value: 4, label: "Others" }
  ];
  const sec4_reasonChange = (item) => {
    setSelectReason(item.label)
  }
  React.useEffect(() => {
    setL1chategory(props.data.l1_categoryname);
    if (l1chategory == "Bill Collection") {
      setL1chategory('Fixed Shop')
    }
    setShopphoto(props.data.picurl);
    setShopName(props.data.shopname);
    setBusinessName(props.data.shopname);
    setMobileNo(props.data.mainmobilenumber);
    var licencephoto_img = [];
      licencephoto_img= props.data.businessproofimg.split(',');

    setLicencephoto(licencephoto_img[0]);
    setTicketNoLicense(props.data.ticket_no);
    setMerchantidLicense(props.match.params.id);
    setRemark(props.Remark);
    setType(props.data.l1_categoryname);
    setupdatedTimestamp(props.data.sec4_updated_at);
    setstatus(props.data.license_qc);
    setSec4_reason(props.data.sec4_reason);
    setAgent_id(props.data.agent_id);
    if (props.data.categoryl3 == SweetShop_id || props.data.categoryl3 == CakeAndBakery_id || props.data.categoryl3 == Resturant_id) {
      setCategory_id(props.data.categoryl3);
    } else {
      setCategory_id('');
    }

  });

  const handleAprove = () => {
    setshowShopBoard(false);
    setRejected(false);
    setRightImage(true);
    setApproved(true);
    setlicense_qc(0);
    setdisable(false);
    seterrormsgbusinessname('');
  };

  const handleReject = () => {
    setshowShopBoard(true);
    setRejected(true);
    setRightImage(false);
    setApproved(false);
    setlicense_qc(1);
    setdisable(false);
    seterrormsgbusinessname('');
    if (rejected === true) {
      setshowShopBoard(false);
      setRejected(false);
    }
  };

  const handleChangeBusinessName = (event) => {
    setBusinessName(event.target.value);
  };
  const handleChangeDisplayName = (event) => {
    setDisplayName(event.target.value);
  };
  const handleChangeLicenceNumber = (event) => {
    setLicenscenumber(event.target.value);
  };
  const image_match = (event) => {
    setlicense_qc(0);
  };
  const image_notmatch = (event) => {
    setlicense_qc(1);
  };
  const submitBussinessName = (event) => {
    const toDay = new Date();
    seterrormsgbusinessname('');
    console.log(rejected);

    // if (((selectReason == undefined) || (selectReason == '')) && (approved == false)) {
    //   seterrormsgbusinessname("Select reason");
    // }
    // else if ((businessName == "") && (license_qc == 0)) {
    //   seterrormsgbusinessname("Enter Bussiness name");
    // }
    // else if ((licensenumber == "") && (license_qc == 0)) {
    //   seterrormsgbusinessname("Enter License number");
    // } else if ((startDate == "") && (license_qc == 0)) {
    //   seterrormsgbusinessname("Enter License start date");
    // } else if ((endDate == "") && (license_qc == 0)) {
    //   seterrormsgbusinessname("Enter License end date");
    // } else if (((endDate != "") && (license_qc == 0)) && ((toDay.getTime() >= endDate.getTime()))) {
    //   setlicense_qc(1);
    // } else {
      const headers = {
        "Content-Type": "application/json",
      };
      if (rejected == true) {

        const qc_panelStage1 = {
          ticket_no: TicketNoLicense,
          status: license_qc,
          bussinessname: shopName,
          lincenseno: '',
          lincenseissuedate: '',
          lincenseexpirydate: '',
          merchantid: MerchantidLicense,
          remark: remark,
          reason: selectReason,
          type: l1chategory,
          mobileNo:mobileNo,
          category_id: category_id,
          agent_id: agent_id
        };
        axios
          .post(window.baseUrl + `updateStage4`, qc_panelStage1, {
            headers: headers,
          })
          .then((res) => {
            console.log(res.data);
            console.log(res.data.message);
            window.location.reload();
          });
      } else {

        const qc_panelStage1 = {
          ticket_no: TicketNoLicense,
          status: license_qc,
          bussinessname: businessName,
          lincenseno: licensenumber,
          lincenseissuedate: startDate,
          lincenseexpirydate: endDate,
          merchantid: MerchantidLicense,
          remark: remark,
          reason: selectReason,
          type: l1chategory,
          category_id: category_id,
          agent_id: agent_id
        };
        axios
          .post(window.baseUrl + `updateStage4`, qc_panelStage1, {
            headers: headers,
          })
          .then((res) => {
            console.log(res.data);
            console.log(res.data.message);
            window.location.reload();
          });

      }



    // }
  };

  const handlesubmit = () => {
    // console.log("value", value);
  };

  return (
    <div className="background_validation">
      <Row>
        <Col className="mt-4">
          <div className="pb-3 pt-2 header">
            <b className="pt-3 title">Section -4: Match business name with name on  business proof provided  </b>
            <textarea className="input_match_business_name" type="text" value={shopName}>{shopName}</textarea>
            <div className="d-inline-block float-right w-25  border border-secondary">
              <div className="d-flex flex-row border-bottom border-secondary last_update">
                <p className="pl-2 mb-0"><b>Last Update</b></p>

              </div>
              <div className="d-flex flex-row mt-2">
                <p className="pl-2">Status : </p>
                <p>{status == 0 ? "Approved" : status == 1 ? "Resubmit" : "New"}</p>
              </div>
              <div className="d-flex flex-row">
                <p className="pl-2">Reason : </p>
                <p style={{ width: '70%' }}>{sec4_reason == null ? 'Null' : sec4_reason}</p>
              </div>
              <div className="d-flex flex-row">
                <p className="pl-2">Timestamp : </p>
                <p>{updatedTimestamp == null ? 'Null' : <Moment format="DD/MM/YYYY">{updatedTimestamp}</Moment>}</p>
              </div>
            </div>
          </div>
          <div className="section_instruction instruction  position-absolute ">
            <b>Instructions:</b> <span className="pl-3">Business proof photo is clear</span>
            <Typography style={{ marginLeft: "93px", fontSize: "0.875rem" }}>All details of business proof are visible</Typography>
            <Typography style={{ marginLeft: "93px", fontSize: "0.875rem" }}>Match business name mentioned above with
              name mentioned in business proof image below</Typography>
          </div>

        </Col>
      </Row>

      <br/>
      <Row >
        <Col md={3} className="pt-3  mt-4">
          <div className="color_border_validation height_cheque  position-absolute">
            <img className="w-100 h-100  cursor-pointer" src={licencephoto} onClick={handleShowLicencesPhoto}></img>
          </div>
          {/* <div className="text-center pt-1">License Photo</div> */}
        </Col>

        <Col md={1} className='pb-3  mt-4'>

          <div className="image_wrong_div mt-4 mx-auto" onClick={handleAprove} style={{ backgroundColor: rightImage == true ? "#62FDA0 " : "" }}>
            <img className="image_wrong cursor-pointer" src={right} ></img>
          </div>
          <div className="text-center pt-2">
            <b>Ok</b>
          </div>
          <div className="text-center pt-2">As per guidelines</div>
        </Col>

        <Col md={1} className=' mt-4'>
          <div className="image_wrong_div mt-4 image_validation " style={{ backgroundColor: showShopBoard == true ? "#FD626B " : "" }}>
            <img className="image_wrong cursor-pointer" src={resubmit} onClick={handleReject}></img>
          </div>
          <div className="text-center pt-2">
            <b>Resubmit</b>
          </div>
          <div className="text-left pt-2">
            <Typography style={{ width: "215px", fontSize: "0.875rem" }} noWrap>
              Hazy, not clear
            </Typography>
            <Typography style={{ width: "215px", fontSize: "0.875rem" }} noWrap>
              Inside photo, partial photo
            </Typography>
            <Typography style={{ width: "215px", fontSize: "0.875rem" }} noWrap>
              Wrong photo
            </Typography>
          </div>
        </Col>
        {rejected && (
          <Col>
            <Select options={reason_licence} className="float-right reason_shopimage" onChange={sec4_reasonChange}></Select>

            {/* <Select name="reasons" className="float-right reason_shopimage" defaultValue={value} options={Reason} value={value} onChange={setValue} getOptionLabel={(option) => option.name} getOptionValue={(option) => option.name} /> */}
          </Col>
        )}
      </Row>
     
      <Row style={{ display: "grid", justifyContent: "end" }}>
        <Col>
          <span>
            <button className="btn" disabled={disable} onClick={submitBussinessName}>
              Submit
            </button>
          </span>
          <p className="text-danger text-center pt-3">{errormsgbusinessname}</p>
        </Col>
      </Row>
      <Modal show={showShopPhoto} onHide={handleCloseShopPhoto}>
        <Modal.Body>
          <TransformWrapper>
            <TransformComponent>
              <img className="w-100 h-100" src={licencephoto}></img>
            </TransformComponent>
          </TransformWrapper>
        </Modal.Body>
      </Modal>
      <Modal show={showLicencesPhoto} onHide={handleCloseLicencesPhoto}>
        <Modal.Body>
          <img className="w-100 h-100" src={licencephoto}></img>
        </Modal.Body>
      </Modal>
    </div>
  );
};
const Dashboard = withRouter(BusinessName);
export default Dashboard;