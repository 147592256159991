import React, { useState, useEffect } from 'react';
import OrderListService from "../api/services/orderlist.js";
import Moment from 'react-moment';
import { Container } from "reactstrap";
import { Row, Col, Table } from "react-bootstrap";
import "../styles/home.css";
import "../Dashboard.css";
import Sidebar from "./../components/sidebar.js";
import axios from 'axios';
import {APIConfig} from '../api/apiConfig/apiConfig.js';
import { constants } from '../utils/constants.js';

const Home = (props) => {
    const { baseUrl } = APIConfig;
    const [orderDetail, setOrderDetail] = useState([]);
    const [date, setDate] = useState(new Date());
    const [city, setCity] = useState([]);
    const [selectCity, setSelectCity] = useState(constants.defaultCity);
    const [homeDelivery, setHomeDelivery] = useState({});
    const [overallDelivery, setOverallDelivery] = useState({});
    const [checkoutVIP, setcheckoutVIP] = useState({});
    const [checkoutNonVIP, setCheckoutNonVIP] = useState({});
    const [nonCheckoutVIP, setNonCheckoutVIP] = useState({});
    const [nonCheckoutNonVIP, setNonCheckoutNonVIP] = useState({});

    useEffect(() => {
        getCity();
        // getorderList(selectCity);
        getOrderDetail(selectCity);
        orderSummary(selectCity, '1');
    }, [])
    //City list
    const getCity = async () => {
        axios.get(window.baseUrl + 'getCities')
            .then((response) => {
                var res = response.data.data;
                // let result = JSON.parse(res);
                console.log(res, "res");
                setCity([...res]);

            });

    }

    const homeDeliveryDesiredOrder = [
        "Delivery Requested",
        "Delivery Completed",
        "Paid Deliveries",
        "Payment Recieved"
      ];


    const overallDeliveryDesiredOrder = [
            "Delivery Requested",
            "Delivery Completed",
            "loadshare",
            "forceapp",
            "cbDelivery",
            "homeDelivery"
        ];


    const checkoutDesiredOrder = [
            "Order Generated",
            "Orders Paid",
            "Self Delivery",
            "CB Delivery Requested",
            "CB Delivery Completed",
            "Payment Received"
        ];



    const nonCheckoutDesiredOrder = [
            "Order Generated",
            "Order Accepted",
            "Orders Paid",
            "Self Delivery",
            "CB Delivery Requested",
            "CB Delivery Completed",
            "Payment Received"
        ];


    //Today order list

    const orderSummary = async(city, tab) =>{

        await axios
        .post(baseUrl + "getBusinessSummary",{
            city: city,
            tab, tab,
        })
        .then(function (response) {
            if (response.data.code === 0) {
                // console.log("response is ", response.data);
                if (tab === '1') {
                    
                    var homeResponse = orderReaarange(response.data.homeDelivery, homeDeliveryDesiredOrder);
                    setHomeDelivery(homeResponse);
                    // console.log(homeDelivery);
                    // console.log("result ", homeDelivery.total);
                    
                    var overallResponse = orderReaarange(response.data.overallDelivery, overallDeliveryDesiredOrder);
                    setOverallDelivery(overallResponse);
                    {/*console.log("it is tab 1");*/}
                } else if (tab === '2'){
                    var checkoutVIPResponse = orderReaarange(response.data.checkoutVIP, checkoutDesiredOrder);
                    setcheckoutVIP(checkoutVIPResponse);

                    var checkoutNonVIPResponse=orderReaarange(response.data.checkoutNonVIP, checkoutDesiredOrder);
                    setCheckoutNonVIP(checkoutNonVIPResponse);
                    // console.log("it is tab 2");
                }else if (tab === '3'){
                    var nonCheckoutVIPResponse = orderReaarange(response.data.nonCheckoutVIP, nonCheckoutDesiredOrder);
                    setNonCheckoutVIP(nonCheckoutVIPResponse);

                    var nonCheckoutNonVIPResponse = orderReaarange(response.data.nonCheckoutNonVIP, nonCheckoutDesiredOrder);
                    setNonCheckoutNonVIP(nonCheckoutNonVIPResponse);
                }else{
                    console.log("wrong tab response");
                }
                console.log("response is", response.data.message);
            } else{
                console.log("no response");
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    }
    
    function orderReaarange(dataResponse,desiredOrder) {
        
        const rearrangedResponse = Object.keys(dataResponse).reduce((acc, key) => {
        const sortedItems = dataResponse[key].sort((a, b) => {
            const indexA = desiredOrder.indexOf(a.query);
            const indexB = desiredOrder.indexOf(b.query);
            return indexA - indexB;
        });
        acc[key] = sortedItems;
        return acc;
        }, {});
        
        console.log(rearrangedResponse);
        return rearrangedResponse;
    }


    // const getorderList = async (selectCity) => {
    //     // let data = { city: selectCity };
    //     await OrderListService.getOrderDetail(selectCity)
    //         .then(async (res) => {
    //             if (res.status === 200) {
    //                 try {
    //                     res.text().then(async (res) => {
    //                         let result = JSON.parse(res);
    //                         if (result.code === 0) {
    //                             if (result.data) {
    //                                 console.log(result.data.last_updated);
    //                                 setDate(result.data.last_updated);
    //                                 setOrdersGenerated(result.data.total_order);
    //                                 setOrdersAccepted(result.data.order_accepted);
    //                                 setPayment_link(result.data.order_with_payment_link);
    //                                 if (result.data.total_amt_recevied == null || result.data.total_amt_recevied == '') {

    //                                     setPayment_received(0);
    //                                 }
    //                                 else {
    //                                     setPayment_received(result.data.total_amt_recevied);
    //                                 }
    //                                 setpaidLink(result.data.order_with_paid_link);
    //                                 setDeliveryReq(result.data.cb_delivery_req);
    //                                 setDeliveryCompleted(result.data.cb_delivery_delivered);
    //                                 setDeliveryPartner(result.data.cb_delivery_allocated);

    //                             }
    //                         }
    //                         else {
    //                             setDate(new Date());
    //                             setOrdersGenerated(0);
    //                             setOrdersAccepted(0);
    //                             setPayment_link(0);
    //                             setPayment_received(0);
    //                             setpaidLink(0);
    //                             setDeliveryReq(0);
    //                             setDeliveryCompleted(0);
    //                             setDeliveryPartner(0);

    //                         }
    //                     });
    //                 } catch (err) {
    //                     console.log(err);

    //                 }

    //             }
    //         });

    // }
    
    //Order detail API
    //let data = { city: city };
    console.log(selectCity);
    const getOrderDetail = async (selectCity) => {
        // let data = { city: selectCity };
        // console.log(data);
        await OrderListService.getList(selectCity)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 1) {
                              
                                if (result.data) {
                                    console.log([...result.data]);
                                    setOrderDetail([...result.data]);
                                    var res = [...result.data];
                                    res.sort(function (a, b) {
                                        return new Date(b.order_accepted_date) - new Date(a.order_accepted_date)
                                    })
                                    setOrderDetail(res);


                                }else{
                                    setOrderDetail([]);
                                }
                            }
                        });
                    } catch (err) {
                        console.log(err);

                    }
                   

                }
                else{
                    setOrderDetail([]);
                }
            });

    }


    const handleSelectCity = (event) => {
        event.preventDefault();
        setSelectCity(event.target.value);
        orderSummary(event.target.value, activeTab);
        getOrderDetail(event.target.value);
    }


    const handleTabs = (cityName, currentTab) => {
        // event.preventDefault();
        // setSelectCity(cityName);
        orderSummary(cityName, currentTab);
        getOrderDetail(cityName);

    }

    const [activeTab, setActiveTab] = useState('1');

    const openTab = (tabName) => {
      setActiveTab(tabName); }

    return (
        <Container fluid>
            <Row>
                <Col xs={2} id="sidebar-wrapper">
                    <Sidebar />
                </Col>
                <Col xs={10} id="page-content-wrapper">
                    <Row>
                        <Col id="page-content-wrapper">
                            <div className="App" >
                                <h1 style={{ marginTop: '160px' }}>Today's business summary dashboard</h1>

                                <div className='text-center pt-3'>
                                    <div className='d-inline-block fs-2 font-weight-bold'>
                                        <Moment format=" dddd">{date}</Moment>,
                                        <Moment format=" D MMM, YYYY  ">{date}</Moment>
                                    </div>
                                    <div className='d-inline-block pl-4 font-weight-bold'>
                                        Last updated  at <Moment format="hh:mm A">{date}</Moment> </div>
                                    <select v-model="selected" className="editInput" value={selectCity} onChange={handleSelectCity} style={{ width: '25%', marginLeft: '2%' }}>
                                        {/* <option value="" selected disabled hidden>Select city</option> */}
                                        {city.map(data => (

                                            <option value={data.city} >
                                                {data.city}
                                            </option>
                                        ))}
                                    </select>
                                    
                                </div>

                                {/* Tab and card to show Business Summary of the day */}
                                <div>
                                    <div className="tab">
                                        <button
                                        className={activeTab === '1' ? 'tablinks active' : 'tablinks'}
                                        onClick={() => {openTab('1');handleTabs(selectCity,'1')}}
                                        >
                                        Delivery Summary
                                        </button>
                                        <button
                                        className={activeTab === '2' ? 'tablinks active' : 'tablinks'}
                                        onClick={() => {openTab('2');handleTabs(selectCity, '2')}}
                                        >
                                        Checkout Summary
                                        </button>
                                        <button
                                        className={activeTab === '3' ? 'tablinks active' : 'tablinks'}
                                        onClick={() => {openTab('3');handleTabs(selectCity, '3')}}
                                        >
                                        Non Checkout Summary
                                        </button>
                                    </div>

                                    {/* first tab upper card content */}
                                    <div id="1" className={`tabcontent ${activeTab === '1' ? 'active' : ''}`}>
                                    <div className='cardDesign'>
                                    <div className='tab'><h4>Home Delivery Summary</h4></div>
                                    <div className=" cardRowStyle">
                                    <div className=" labelStyle ">Total</div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(homeDelivery).length !== 0  && homeDelivery.total[0].row_count}</div>
                                        <div>Deliveries Requested</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(homeDelivery).length !== 0  && homeDelivery.total[1].row_count}</div>
                                        <div>Deliveries Completed</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(homeDelivery).length !== 0  && homeDelivery.total[2].row_count}</div>
                                        <div>Paid Deliveries</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(homeDelivery).length !== 0  && homeDelivery.total[3].row_count}</div>
                                        <div>Payment Received</div>
                                    </div>
                                    </div>
                                    <div className=" cardRowStyle">
                                    <div className=" labelStyle ">VIP</div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(homeDelivery).length !== 0  && homeDelivery.VIP[0].row_count}</div>
                                        <div>Deliveries Requested</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(homeDelivery).length !== 0  && homeDelivery.VIP[1].row_count}</div>
                                        <div>Deliveries Completed</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(homeDelivery).length !== 0  && homeDelivery.VIP[2].row_count}</div>
                                        <div>Paid Deliveries</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(homeDelivery).length !== 0  && homeDelivery.VIP[3].row_count}</div>
                                        <div>Payment Received</div>
                                    </div>
                                </div>
                                <div className=" cardRowStyle">
                                <div className=" labelStyle ">Non VIP</div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(homeDelivery).length !== 0  && homeDelivery.NonVIP[0].row_count}</div>
                                        <div>Deliveries Requested</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(homeDelivery).length !== 0  && homeDelivery.NonVIP[1].row_count}</div>
                                        <div>Deliveries Completed</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(homeDelivery).length !== 0  && homeDelivery.NonVIP[2].row_count}</div>
                                        <div>Paid Deliveries</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(homeDelivery).length !== 0  && homeDelivery.NonVIP[3].row_count}</div>
                                        <div>Payment Received</div>
                                    </div>
                                </div></div>


                                {/* first tab lower card content */}
                                <div className=" lowerCardContent">
                                <div className='tab'><h4>Overall Delivery Summary</h4></div>
                                    <div className=" cardRowStyle">
                                    <div className=" labelStyle ">Total</div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(overallDelivery).length !== 0  && overallDelivery.total[0].row_count}</div>
                                        <div>Deliveries Requested</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(overallDelivery).length !== 0  && overallDelivery.total[1].row_count}</div>
                                        <div>Deliveries Completed</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(overallDelivery).length !== 0  && overallDelivery.total[2].row_count}</div>
                                        <div>Loadshare</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(overallDelivery).length !== 0  && overallDelivery.total[3].row_count}</div>
                                        <div>Force App</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(overallDelivery).length !== 0  && overallDelivery.total[4].row_count}</div>
                                        <div>CB Deliveries</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(overallDelivery).length !== 0  && overallDelivery.total[5].row_count}</div>
                                        <div>Home Deliveries</div>
                                    </div>
                                </div>
                                <div className=" cardRowStyle">
                                <div className=" labelStyle ">VIP</div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(overallDelivery).length !== 0  && overallDelivery.VIP[0].row_count}</div>
                                        <div>Deliveries Requested</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(overallDelivery).length !== 0  && overallDelivery.VIP[1].row_count}</div>
                                        <div>Deliveries Completed</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(overallDelivery).length !== 0  && overallDelivery.VIP[2].row_count}</div>
                                        <div>Loadshare</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(overallDelivery).length !== 0  && overallDelivery.VIP[3].row_count}</div>
                                        <div>Force App</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(overallDelivery).length !== 0  && overallDelivery.VIP[4].row_count}</div>
                                        <div>CB Deliveries</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(overallDelivery).length !== 0  && overallDelivery.VIP[5].row_count}</div>
                                        <div>Home Deliveries</div>
                                    </div>
                                </div>
                                <div className=" cardRowStyle">
                                <div className=" labelStyle ">Non VIP</div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(overallDelivery).length !== 0  && overallDelivery.NonVIP[0].row_count}</div>
                                        <div>Deliveries Requested</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(overallDelivery).length !== 0  && overallDelivery.NonVIP[1].row_count}</div>
                                        <div>Deliveries Completed</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(overallDelivery).length !== 0  && overallDelivery.NonVIP[2].row_count}</div>
                                        <div>Loadshare</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(overallDelivery).length !== 0  && overallDelivery.NonVIP[3].row_count}</div>
                                        <div>Force App</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(overallDelivery).length !== 0  && overallDelivery.NonVIP[4].row_count}</div>
                                        <div>CB Deliveries</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(overallDelivery).length !== 0  && overallDelivery.NonVIP[5].row_count}</div>
                                        <div>Home Deliveries</div>
                                    </div>
                                    </div>
                                </div>
                                    </div>

                                    {/* Checkout orders VIP Merchants */}
                                    <div id="2" className={`tabcontent ${activeTab === '2' ? 'active' : ''}`}>
                                    <div className='cardDesign'>
                                    <div className='tab'><h4>Check Out Orders Summary - VIP Merchants </h4></div>
                                    <div className=" cardRowStyle">
                                    <div className=" labelStyle ">Total</div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(checkoutVIP).length !== 0  && checkoutVIP.total[0].row_count}</div>
                                        <div>Orders Generated</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(checkoutVIP).length !== 0  && checkoutVIP.total[1].row_count}</div>
                                        <div>Orders Paid</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(checkoutVIP).length !== 0  && checkoutVIP.total[2].row_count}</div>
                                        <div>Self Delivery</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(checkoutVIP).length !== 0  && checkoutVIP.total[3].row_count}</div>
                                        <div>CB deliveries Requested</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(checkoutVIP).length !== 0  && checkoutVIP.total[4].row_count}</div>
                                        <div>CB deliveries Completed</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(checkoutVIP).length !== 0  && checkoutVIP.total[5].row_count}</div>
                                        <div>Payment Amount</div>
                                    </div>
                                    </div>
                                    <div className=" cardRowStyle">
                                    <div className=" labelStyle ">User App</div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(checkoutVIP).length !== 0  && checkoutVIP.userapp[0].row_count}</div>
                                        <div>Orders Generated</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(checkoutVIP).length !== 0  && checkoutVIP.userapp[1].row_count}</div>
                                        <div>Orders Paid</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(checkoutVIP).length !== 0  && checkoutVIP.userapp[2].row_count}</div>
                                        <div>Self Delivery</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(checkoutVIP).length !== 0  && checkoutVIP.userapp[3].row_count}</div>
                                        <div>CB deliveries Requested</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(checkoutVIP).length !== 0  && checkoutVIP.userapp[4].row_count}</div>
                                        <div>CB deliveries Completed</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(checkoutVIP).length !== 0  && checkoutVIP.userapp[5].row_count}</div>
                                        <div>Payment Amount</div>
                                    </div>
                                </div>
                                <div className=" cardRowStyle">
                                <div className=" labelStyle ">Website</div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(checkoutVIP).length !== 0  && checkoutVIP.website[0].row_count}</div>
                                        <div>Orders Generated</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(checkoutVIP).length !== 0  && checkoutVIP.website[1].row_count}</div>
                                        <div>Orders Paid</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(checkoutVIP).length !== 0  && checkoutVIP.website[2].row_count}</div>
                                        <div>Self Delivery</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(checkoutVIP).length !== 0  && checkoutVIP.website[3].row_count}</div>
                                        <div>CB deliveries Requested</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(checkoutVIP).length !== 0  && checkoutVIP.website[4].row_count}</div>
                                        <div>CB deliveries Completed</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(checkoutVIP).length !== 0  && checkoutVIP.website[5].row_count}</div>
                                        <div>Payment Amount</div>
                                    </div>
                                </div></div>


                                {/* Second tab lower content */}
                                <div className=" lowerCardContent">
                                <div className='tab'><h4>Check Out Orders Summary - Non VIP Merchants</h4></div>
                                    <div className=" cardRowStyle">
                                    <div className=" labelStyle ">Total</div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(checkoutNonVIP).length !== 0  && checkoutNonVIP.total[0].row_count}</div>
                                        <div>Orders Generated</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(checkoutNonVIP).length !== 0  && checkoutNonVIP.total[1].row_count}</div>
                                        <div>Orders Paid</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(checkoutNonVIP).length !== 0  && checkoutNonVIP.total[2].row_count}</div>
                                        <div>Self Delivery</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(checkoutNonVIP).length !== 0  && checkoutNonVIP.total[3].row_count}</div>
                                        <div>CB deliveries Requested</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(checkoutNonVIP).length !== 0  && checkoutNonVIP.total[4].row_count}</div>
                                        <div>CB deliveries Completed</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(checkoutNonVIP).length !== 0  && checkoutNonVIP.total[5].row_count}</div>
                                        <div>Payment Amount</div>
                                    </div>
                                    </div>
                                    <div className=" cardRowStyle">
                                    <div className=" labelStyle ">User App</div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(checkoutNonVIP).length !== 0  && checkoutNonVIP.userapp[0].row_count}</div>
                                        <div>Orders Generated</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(checkoutNonVIP).length !== 0  && checkoutNonVIP.userapp[1].row_count}</div>
                                        <div>Orders Paid</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(checkoutNonVIP).length !== 0  && checkoutNonVIP.userapp[2].row_count}</div>
                                        <div>Self Delivery</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(checkoutNonVIP).length !== 0  && checkoutNonVIP.userapp[3].row_count}</div>
                                        <div>CB deliveries Requested</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(checkoutNonVIP).length !== 0  && checkoutNonVIP.userapp[4].row_count}</div>
                                        <div>CB deliveries Completed</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(checkoutNonVIP).length !== 0  && checkoutNonVIP.userapp[5].row_count}</div>
                                        <div>Payment Amount</div>
                                    </div>
                                </div>
                                <div className=" cardRowStyle">
                                <div className=" labelStyle ">Website</div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(checkoutNonVIP).length !== 0  && checkoutNonVIP.website[0].row_count}</div>
                                        <div>Orders Generated</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(checkoutNonVIP).length !== 0  && checkoutNonVIP.website[1].row_count}</div>
                                        <div>Orders Paid</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(checkoutNonVIP).length !== 0  && checkoutNonVIP.website[2].row_count}</div>
                                        <div>Self Delivery</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(checkoutNonVIP).length !== 0  && checkoutNonVIP.website[3].row_count}</div>
                                        <div>CB deliveries Requested</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(checkoutNonVIP).length !== 0  && checkoutNonVIP.website[4].row_count}</div>
                                        <div>CB deliveries Completed</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(checkoutNonVIP).length !== 0  && checkoutNonVIP.website[5].row_count}</div>
                                        <div>Payment Amount</div>
                                    </div>
                                </div>
                                </div>
                                    </div>

                                    {/* Non Checkour Order VIP Merchants  */}
                                    <div id="3" className={`tabcontent ${activeTab === '3' ? 'active' : ''}`}>
                                        <div className='cardDesign'>
                                    <div className='tab'><h4>Non Check Out Orders Summary - VIP Merchants </h4></div>
                                    <div className=" cardRowStyle">
                                    <div className=" labelStyle ">Total</div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutVIP).length !== 0  && nonCheckoutVIP.total[0].row_count}</div>
                                        <div>Orders Generated</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutVIP).length !== 0  && nonCheckoutVIP.total[1].row_count}</div>
                                        <div>Orders Accepted</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutVIP).length !== 0  && nonCheckoutVIP.total[2].row_count}</div>
                                        <div>Orders Paid</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutVIP).length !== 0  && nonCheckoutVIP.total[3].row_count}</div>
                                        <div>Self Delivery</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutVIP).length !== 0  && nonCheckoutVIP.total[4].row_count}</div>
                                        <div>Deliveries Requested</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutVIP).length !== 0  && nonCheckoutVIP.total[5].row_count}</div>
                                        <div>Deliveries Completed</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutVIP).length !== 0  && nonCheckoutVIP.total[6].row_count}</div>
                                        <div>Payment Amount</div>
                                    </div>
                                    </div>
                                    <div className=" cardRowStyle">
                                    <div className=" labelStyle ">User App</div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutVIP).length !== 0  && nonCheckoutVIP.userapp[0].row_count}</div>
                                        <div>Orders Generated</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutVIP).length !== 0  && nonCheckoutVIP.userapp[1].row_count}</div>
                                        <div>Orders Accepted</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutVIP).length !== 0  && nonCheckoutVIP.userapp[2].row_count}</div>
                                        <div>Orders Paid</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutVIP).length !== 0  && nonCheckoutVIP.userapp[3].row_count}</div>
                                        <div>Self Delivery</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutVIP).length !== 0  && nonCheckoutVIP.userapp[4].row_count}</div>
                                        <div>Deliveries Requested</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutVIP).length !== 0  && nonCheckoutVIP.userapp[5].row_count}</div>
                                        <div>Deliveries Completed</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutVIP).length !== 0  && nonCheckoutVIP.userapp[6].row_count}</div>
                                        <div>Payment Amount</div>
                                    </div>
                                </div>
                                <div className=" cardRowStyle">
                                <div className=" labelStyle ">Website</div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutVIP).length !== 0  && nonCheckoutVIP.website[0].row_count}</div>
                                        <div>Orders Generated</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutVIP).length !== 0  && nonCheckoutVIP.website[1].row_count}</div>
                                        <div>Orders Accepted</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutVIP).length !== 0  && nonCheckoutVIP.website[2].row_count}</div>
                                        <div>Orders Paid</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutVIP).length !== 0  && nonCheckoutVIP.website[3].row_count}</div>
                                        <div>Self Delivery</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutVIP).length !== 0  && nonCheckoutVIP.website[4].row_count}</div>
                                        <div>Deliveries Requested</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutVIP).length !== 0  && nonCheckoutVIP.website[5].row_count}</div>
                                        <div>Deliveries Completed</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutVIP).length !== 0  && nonCheckoutVIP.website[6].row_count}</div>
                                        <div>Payment Amount</div>
                                    </div>
                                </div></div>


                                {/* Third tab lower content  */}
                                <div className=" lowerCardContent">
                                <div className='tab'><h4>Non Check Out Orders Summary - Non VIP Merchants</h4></div>
                                    <div className=" cardRowStyle">
                                    <div className=" labelStyle ">Total</div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutNonVIP).length !== 0  && nonCheckoutNonVIP.total[0].row_count}</div>
                                        <div>Orders Generated</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutNonVIP).length !== 0  && nonCheckoutNonVIP.total[1].row_count}</div>
                                        <div>Orders Accepted</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutNonVIP).length !== 0  && nonCheckoutNonVIP.total[2].row_count}</div>
                                        <div>Orders Paid</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutNonVIP).length !== 0  && nonCheckoutNonVIP.total[3].row_count}</div>
                                        <div>Self Delivery</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutNonVIP).length !== 0  && nonCheckoutNonVIP.total[4].row_count}</div>
                                        <div>Deliveries Requested</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutNonVIP).length !== 0  && nonCheckoutNonVIP.total[5].row_count}</div>
                                        <div>Deliveries Completed</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutNonVIP).length !== 0  && nonCheckoutNonVIP.total[6].row_count}</div>
                                        <div>Payment Amount</div>
                                    </div>
                                    </div>
                                <div className=" cardRowStyle">
                                <div className=" labelStyle ">User App</div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutNonVIP).length !== 0  && nonCheckoutNonVIP.userapp[0].row_count}</div>
                                        <div>Orders Generated</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutNonVIP).length !== 0  && nonCheckoutNonVIP.userapp[1].row_count}</div>
                                        <div>Orders Accepted</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutNonVIP).length !== 0  && nonCheckoutNonVIP.userapp[2].row_count}</div>
                                        <div>Orders Paid</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutNonVIP).length !== 0  && nonCheckoutNonVIP.userapp[3].row_count}</div>
                                        <div>Self Delivery</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutNonVIP).length !== 0  && nonCheckoutNonVIP.userapp[4].row_count}</div>
                                        <div>Deliveries Requested</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutNonVIP).length !== 0  && nonCheckoutNonVIP.userapp[5].row_count}</div>
                                        <div>Deliveries Completed</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutNonVIP).length !== 0  && nonCheckoutNonVIP.userapp[6].row_count}</div>
                                        <div>Payment Amount</div>
                                    </div>
                                </div>
                                <div className=" cardRowStyle">
                                <div className=" labelStyle ">Website</div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutNonVIP).length !== 0  && nonCheckoutNonVIP.whatsApp[0].row_count}</div>
                                        <div>Orders Generated</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutNonVIP).length !== 0  && nonCheckoutNonVIP.whatsApp[1].row_count}</div>
                                        <div>Orders Accepted</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutNonVIP).length !== 0  && nonCheckoutNonVIP.whatsApp[2].row_count}</div>
                                        <div>Orders Paid</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutNonVIP).length !== 0  && nonCheckoutNonVIP.whatsApp[3].row_count}</div>
                                        <div>Self Delivery</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutNonVIP).length !== 0  && nonCheckoutNonVIP.whatsApp[4].row_count}</div>
                                        <div>Deliveries Requested</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutNonVIP).length !== 0  && nonCheckoutNonVIP.whatsApp[5].row_count}</div>
                                        <div>Deliveries Completed</div>
                                    </div>
                                    <div className=" wrapperSummaryPanel">
                                        <div>{Object.keys(nonCheckoutNonVIP).length !== 0  && nonCheckoutNonVIP.whatsApp[6].row_count}</div>
                                        <div>Payment Amount</div>
                                    </div>
                                </div>
                                </div>
                                    </div>
                                    </div>

                                
                            </div>


                        </Col>
                    </Row>

                    {/* table */}


                    <div className="orderList_table">
                        {orderDetail.length != 0 ?
                            <Table striped bordered size="sm" style={{ position: "sticky", top: '0', zIndex: 1, whiteSpace: 'nowrap', display: 'block' }}>
                                <thead>
                                    <tr style={{ position: "sticky", top: '0', zIndex: 1, border: '1px' }} >
                                        <th className=" align-middle text-center" style={{ position: "sticky", top: '0', zIndex: 1 }}>City</th>
                                        <th className=" align-middle text-center" style={{ position: "sticky", top: '0', zIndex: 1 }}> Time</th>
                                        <th className=" align-middle text-center" style={{ position: "sticky", top: '0', zIndex: 1 }}>orderID</th>
                                        <th className="  align-middle text-center" style={{ position: "sticky", top: '0', zIndex: 1 }}>Business Name</th>
                                        <th className=" align-middle text-center" style={{ position: "sticky", top: '0', zIndex: 1 }}>MID</th>
                                        <th className="align-middle text-center" style={{ position: "sticky", top: '0', zIndex: 1 }}>Merchant address</th>
                                        <th className="align-middle text-center" style={{ position: "sticky", top: '0', zIndex: 1 }}>User Address</th>
                                        <th className="align-middle text-center" style={{ position: "sticky", top: '0', zIndex: 1 }}>Loadshare Serviceability</th>
                                        <th className="align-middle text-center" style={{ position: "sticky", top: '0', zIndex: 1 }}>Delivery distance</th>
                                        <th className="align-middle text-center" style={{ position: "sticky", top: '0', zIndex: 1 }}>Accept status</th>
                                        <th className="align-middle text-center" style={{ position: "sticky", top: '0', zIndex: 1 }}>CB delivery request time</th>
                                        <th className="align-middle text-center" style={{ position: "sticky", top: '0', zIndex: 1 }}>Allocation status</th>
                                        <th className="align-middle text-center" style={{ position: "sticky", top: '0', zIndex: 1 }}>Allocation time</th>
                                        <th className="align-middle text-center" style={{ position: "sticky", top: '0', zIndex: 1 }}>Delivery time</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {orderDetail.map((val, key) => {
                                        return (
                                            <tr className="p-3 text-left align-middle" key={key}>
                                                <td className="p-3 text-left align-middle">{val.city}</td>
                                                <td className="p-3 text-left align-middle"><Moment format="MMM Do YYYY hh:mm A">{val.order_accepted_date}</Moment></td>
                                                <td className="p-3 text-left align-middle">{val.order_id}</td>
                                                <td className="p-3 text-left align-middle">{val.shopname}</td>
                                                <td className="p-3 text-left align-middle">{val.merchantid}</td>
                                                <td className="p-3 text-left align-middle">{val.addressline1},{val.addressline2},{val.pincode}</td>
                                                <td className="p-3 text-left align-middle">{(val.useraddline1!="" && val.useraddline1!=null &&  val.useraddline1!="null") && (val.useraddline2!="" && val.useraddline2!=null && val.useraddline2!="null")?val.useraddline1+' '+val.useraddline2+' '+val.usercity+' '+val.pincode:(val.useraddline1!="" && val.useraddline1!=null && val.useraddline1!="null") ?val.useraddline1+' '+val.usercity+' '+val.pincode:(val.useraddline2!="" && val.useraddline2!=null && val.useraddline2!="null")?val.useraddline2+' '+val.usercity+' '+val.pincode:''}</td>
                                                <td className="p-3 text-left align-middle">{val.loadshare_serviceability == 1 ? 'false' : val.loadshare_serviceability == 0 ? 'true' : '-'}</td>
                                                <td className="p-3 text-left align-middle">{val.user_merch_distance}</td>
                                                <td className="p-3 text-left align-middle">{val.stage_status == 1 ? 'Accepted' : val.stage_status == 2 ? 'Amount Paid' : val.stage_status == 3 ? 'Selected Delivery' : val.stage_status == 4 ? 'Confirm Dispatch' : ''}</td>
                                                <td className="p-3 text-left align-middle">{val.delivery_mode == 0 ? val.set_delivery_mode_datetime == null || val.set_delivery_mode_datetime == "null" || val.set_delivery_mode_datetime == '' ? '' : <Moment format="MMM Do YYYY hh:mm A">{val.set_delivery_mode_datetime}</Moment> : ''}</td>
                                                <td className="p-3 text-left align-middle">{val.delivery_mode == 0 ? val.shipment_status == 1 ? "Assigned" : val.shipment_status == 2 ? "Arrived" : val.shipment_status == 3 ? "Picked Up" : val.shipment_status == 4 ? "Reached" : val.shipment_status == 5 ? "Delivered" : val.shipment_status == 6 ? "Rejected" : '' : ''}</td>
                                                <td className="p-3 text-left align-middle">{val.delivery_mode == 0 ? val.cb_allocation_time == null || val.cb_allocation_time == "null" || val.cb_allocation_time == '' ? '' : <Moment format="MMM Do YYYY hh:mm A">{val.cb_allocation_time}</Moment> : ''}</td>
                                                <td className="p-3 text-left align-middle">{val.delivery_mode == 0 ? val.cb_delivered_time == null || val.cb_delivered_time == "null" || val.cb_delivered_time == '' ? '' : <Moment format="MMM Do YYYY hh:mm A">{val.cb_delivered_time}</Moment> : ''}</td>
                                                {/* <a href={`/orderDetails/${key}`}> */}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                            : ''}
                    </div>

                </Col>

            </Row>         </Container>
    );
};

export default Home;