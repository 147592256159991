import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import ReactPlayer from "react-player";
import { timestampToTime, handleEnterPress } from "../utils/messageUtils";
import ReactAudioPlayer from "react-audio-player";

export default function MessagesList(props) {
  const { messages, handleDeleteMessage, updateMessage, messageMarkAsDelivered, isMessagesRead } = props;
  const UserId = window.sessionStorage.getItem("UserId");
  console.log("UserId", UserId);

  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "auto" });
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 2000);
  }, [messages]);

  return (
    <div className="message-list">
      {messages.map((message) => {
        // if (!message.sender) return null;
        const messageSentByYou = message.sender && message.sender.userId === UserId;
        return (
          <div key={message.messageId} className={`message-item ${message.messageType === "admin" ? "admin_msg" : messageSentByYou ? "message-from-you" : ""}`}>
            <Message
              message={message}
              UserId={UserId}
              isMessagesRead={isMessagesRead}
              handleDeleteMessage={handleDeleteMessage}
              messageMarkAsDelivered={messageMarkAsDelivered}
              updateMessage={updateMessage}
              messageSentByYou={messageSentByYou}
              // messagesEndRef={messagesEndRef}
            />
            {/* <ProfileImage user={message.sender} /> */}
          </div>
        );
      })}
      <div ref={messagesEndRef} />
    </div>
  );
}

const Message = ({ message, updateMessage, handleDeleteMessage, messageSentByYou, messageMarkAsDelivered, isMessagesRead, UserId }) => {
  // console.log("createdAt", message);
  if (message.url) {
    if (message.plainUrl != null && message.plainUrl !== undefined) {
      let type = ''
      if (message.type.includes('image')) {
        type = 'jpg'
      } else if (message.type.includes('video')) {
        type = 'mp4'
      } else if (message.type.includes('audio')) {
        type = 'mp3'
      }
      console.log("type", type);
      return (
        <div className={`message  ${messageSentByYou ? "message-from-you" : ""}`}>
          <div className="message-user-info">
            {/* <div className="message-sender-name">{"XXX" + `${message.sender.nickname.length != 10 ? message.sender.nickname.slice(2, 12).substring(6, 10) : message.sender.nickname.substring(6, 10)}`}</div> */}
          </div>
          {type != "mp4" && type != "mp3" && type != "oga" && type != "peg" && <img className="img_input" src={message.url} />}
          {/* {message.type !== "image/gif" && ( */}
          {type != "mp4" && type != "jpg" && type != "png" && <ReactAudioPlayer src={message.url} height="60%" width="60%" controls />}
          {type != "jpg" && type != "png" && type != "oga" && type != "mp3" && type != "peg" && (
            <ReactPlayer
              url={message.url}
              height="50%"
              width="20%"
              controls
              style={{ display: "inline-block" }}
              onReady={() => console.log("onReady callback")}
              onStart={() => console.log("onStart callback")}
              onPause={() => console.log("onPause callback")}
              onEnded={() => console.log("onEnded callback")}
              onError={() => console.log("onError callback")}
            />
          )}
          {/* )} */}
          <div style={{ display: "inline-block", marginLeft: "15px", justifyContent: "flex-end", color: "#cccccc", height: "100%", verticalAlign: "middle" }}>
            {timestampToTime(message.createdAt)}{" "}
            {messageSentByYou && messageMarkAsDelivered && (
              <div style={{ padding: "0px 0px 0px 5px" }}>
                <img className={`message-icon double_tick-icon ${isMessagesRead && "double_tick-icon-read"}`} src={isMessagesRead ? "/double_tick_as_read.png" : "/double_tick.png"} />
              </div>
            )}
          </div>
        </div>
      );
    }
  }
  // const messageSentByCurrentUser = message.userId === UserId;

  return (
    <>
      <div className={`message  ${message.messageType === "admin" ? "admin_msg" : messageSentByYou ? "message-from-you" : ""}`}>
        <div className="message-info">
          <div className="message-user-info">
            {/* <div className="message-sender-name">{"XXX" + `${message.sender.nickname.length != 10 ? message.sender.nickname.slice(2, 12).substring(6, 10) : message.sender.nickname.substring(6, 10)}`}</div> */}
          </div>
          {/* {messageSentByCurrentUser && (
            <div>
              <button className="control-button button_input" onClick={() => updateMessage(message)} disabled={overallStatus}>
              <img className="message-icon img_input" src="/icon_edit.png" />
              </button>
              <button className="control-button button_input" onClick={() => handleDeleteMessage(message)} disabled={overallStatus}>
                <img className="message-icon img_input" src="/icon_delete.png" />
              </button>
            </div>
          )} */}
        </div>

        <div className="message_info">{message.message}</div>

        <div style={{ display: "inline-block", justifyContent: "flex-end", marginLeft: "15px", color: "#cccccc", height: "100%", verticalAlign: "middle" }}>
          {timestampToTime(message.createdAt)}{" "}
          {messageSentByYou && messageMarkAsDelivered && (
            <div style={{ padding: "0px 0px 0px 5px" }}>
              <img className={`message-icon double_tick-icon ${isMessagesRead && "double_tick-icon-read"}`} src={isMessagesRead ? "/double_tick_as_read.png" : "/double_tick.png"} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const ProfileImage = ({ user }) => {
  if (user.plainProfileUrl) {
    return <img className="profile-image img_input" src={user.plainProfileUrl} />;
  } else {
    return <div className="profile-image-fallback">{user.nickname.charAt(0)}</div>;
  }
};
