import React, { useState, useEffect } from 'react';
import './index.scss';

const Offer = () => {
    //let history = useHistory();


    return (
        <div class="main">

            {/* <div class="image">

            </div> */}
            <div class="content">
                <h2>ChattyBao Welcome Cashback Offer</h2>
                <h3>Terms and conditions :</h3>
                <ul>
                    <li className='offer'>Get ₹50 cashback on first order from every shop (max of 5 times), for orders placed on the ChattyBao app</li>
                    <li className='offer'>To get cashback, minimum order value shall be ₹100</li>
                    <li className='offer'>Maximum 3 cashbacks can be claimed in a day</li>
                    <li className='offer'>Offer is applicable on home delivery orders in Lucknow</li>
                    <li className='offer'>Customer shall provide correct delivery address on the address link shared by ChattyBao</li>
                    <li className='offer'>Cashback will be credited within 24 hours, directly in the bank account of the user, for an eligible order</li>
                    <li className='offer'>ChattyBao reserves its absolute right to withdraw and/or alter any terms and conditions of the offer, at any time without prior notice</li>
                    <li className='offer'>Offer valid till 31 Dec'2023</li>
                    <li className='offer'>Other T&Cs may apply</li>
                </ul>
            </div>
        </div>
    )
}
export default Offer;