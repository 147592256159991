//window.baseUrl="http://localhost:4000/v1/";
window.baseUrl = "https://dev.chattybao.com/v1/";
window.addressURL="https://dev.chattybao.com/v2/";
// window.baseUrl="https://api.chattybao.com/v1/";
// window.addressURL="https://api.chattybao.com/v2/";
// if((window.sessionStorage.getItem('sessionId')!=undefined)&&(window.sessionStorage.getItem('sessionId')!="")&&(window.sessionStorage.getItem('sessionId')!=null))
// {
// window.token=window.sessionStorage.getItem('sessionId');
// }

const APIConfig = {
  getCategoryTree: "getCategoryTree?type=web",
  addUpdateCategory: "addUpdateCategory",
  updateMerchant: "updateMerchant",
  viewMerchant: "viewMerchant",
  getStates: "getStates",
  getAgents: "getAgents",
  getCBEmployee: "getCBEmployee",
  getCitiesByState: "getCitiesByState",
  getPincodes: "getPincodes",
  getLogistics: "getLogistics",
  addUpdateLogistics: "addUpdateLogistics",
  addCbEmployee: "addCbEmployee",
  getCbEmployeeDetail: "getCbEmployeeDetail",
  updateCbEmployeeDetail: "updateCbEmployeeDetail",
  getAgentDetail: "getAgentDetail",
  addAgent: "addAgent",
  updateAgentDetail: "updateAgentDetail",
  getOrderDetailsForPanel: "getOrderDetailsForPanel",
  getOrderDetailsListForPanel: "getOrderDetailsListForPanel",
  postOrderSummary: "getBusinessSummary",
  searchByOrderId: "searchByOrderId",
  getOrderDetailsByMerchantMobileNo: "getOrderDetailsByMerchantMobileNo",
  getOrderDetailsByUserMobileNo: "getOrderDetailsByUserMobileNo",
  getMerchantConnectedUser: "getMerchantConnectedUser",
  getUserMerchantChannels: "getUserMerchantChannels",
  deliveryOrderDetails: "getDeliveryOrderDetails",
  nearbyDeliveryAgents: "getNearbyDeliveryAgents",
  assignDeliveryAgents: "assignDeliveryAgent",
  addUpdateFSEMerchants: 'addUpdateFSEMerchants',
  bucketName: 'categoryvisitingcards',
  qcPayoutCSV: 'qcPayoutCSV',

  //Test
  ACCESS_KEY: 'AKIAYKLXEH5H65VJAXW3',
  baseUrl: "https://dev.chattybao.com/v1/",
  SECRET_ACCESS_KEY: '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',
  S3_BUCKET: 'testservergoping/sendbird_yellow_images',
  ApiToken: 'd7da3aa4c2750034642de167063557200f770c52',
  sendbirdBaseUrl: `https://api-17E6AA94-8E87-4DB9-BCE5-F727DD481D50.sendbird.com/v3`,

  //Prod
  //  baseUrl:"https://api.chattybao.com/v1/",
  //  ACCESS_KEY: 'AKIAYKLXEH5HUXC67I6R',
  //  SECRET_ACCESS_KEY: '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',
  //  S3_BUCKET: 'menucards/pdf_menu',
  //  ApiToken: 'a1dbe1f77ca24b9f428e150c2b8d83792c9fbc26',
  // sendbirdBaseUrl: `https://api-8766441F-3EB0-4B2F-9AD7-D080362CD92F.sendbird.com/v3`,
};
export const SENDBIRD_APP_ID = {
  appId: "17E6AA94-8E87-4DB9-BCE5-F727DD481D50", // TEST
  //  appId: "8766441F-3EB0-4B2F-9AD7-D080362CD92F", // PROD
};

export { APIConfig };
