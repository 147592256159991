import React, { useState, useEffect } from 'react';
import { Container } from "reactstrap";
import { Row, Col } from "react-bootstrap";
import "../styles/home.css"
import "../Dashboard.css";
import Sidebar from "../components/sidebar.js";
import { TextField } from "@mui/material";
import axios from 'axios';
import Moment from "react-moment";
import { APIConfig } from "../api/apiConfig/apiConfig";

const VipHistory = () => {
    const [searchValue, setSearchValue] = useState('');
    const [currentStatus, setCurrentStatus] = useState({});
    const [vipHistory, setVipHistory] = useState([]);
    const [showTable, setShowTable] = useState(false);
    const [freeDeliveryData, setFreeDeliveryData] = useState({});
    const { baseUrl } = APIConfig;

    const getVipHistory = () => {
        axios
            .post(baseUrl + "qcVipHistory", {
                mob: searchValue,
            })
            .then(function (response) {
                if (response.data.status === 0) {
                    let freeDeliveryDat = {};
                    console.log("res", response)
                    setCurrentStatus(response.data.currentStatus[0]);
                    setVipHistory(response.data.VIPHistory);
                    freeDeliveryDat = {
                        prevFreeDeliveryCount: response.data.data.prev_free_delivery_count,
                        freeDelCountLeft: response.data.data.free_delivery_count_left
                    }
                    setFreeDeliveryData(freeDeliveryDat);
                    setShowTable(true);
                } else {
                    alert("Merchant not found!")
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (

        <Container fluid>
            <Row>
                <Col xs={2} id="sidebar-wrapper">
                    <Sidebar />
                </Col>
                <Col xs={10} id="page-content-wrapper">
                    <div className='page_container'>
                        <div className='vip_history_input_container'>
                            <TextField className='vip_history_input' label="Search merchant (phone/merchant-ID)" onChange={(e) => setSearchValue(e.target.value)} />
                            <button className='vip_history_submit_btn' onClick={() => { getVipHistory(); setShowTable(false); }}>Submit</button>
                        </div>

                        {showTable === true && (
                            <>
                                <div style={{ width: '400px' }}>
                                    <table
                                        style={{ marginTop: '20px', marginLeft: 0 }}
                                    >
                                        <tr>
                                            <th colSpan="2" className="tableTextStyle">Current Status</th>
                                        </tr>
                                        <tbody>
                                            <tr className="merchentList_tr">
                                                <td className="merchentList_td">
                                                    Shop Name
                                                </td>
                                                <td className="merchentList_td">
                                                    {currentStatus.ShopName}
                                                </td>
                                            </tr>
                                            <tr className="merchentList_tr">
                                                <td className="merchentList_td">
                                                    Merchant Mobile
                                                </td>
                                                <td className="merchentList_td">
                                                    {currentStatus.MerchantMobile}
                                                </td>
                                            </tr>
                                            <tr className="merchentList_tr">
                                                <td className="merchentList_td">
                                                    Merchant Category (L4)
                                                </td>
                                                <td className="merchentList_td">
                                                    {currentStatus.MerchantCategory}
                                                </td>
                                            </tr>
                                            <tr className="merchentList_tr">
                                                <td className="merchentList_td">
                                                    VIP status
                                                </td>
                                                <td className="merchentList_td">
                                                    {currentStatus.VIPStatus}
                                                </td>
                                            </tr>
                                            <tr className="merchentList_tr">
                                                <td className="merchentList_td">
                                                    Start date
                                                </td>
                                                <td className="merchentList_td">
                                                    {currentStatus.VIPStatus !== 'Regular' && (
                                                        <>
                                                            {currentStatus.startDate !== null && (
                                                                <Moment
                                                                    style={{ wordBreak: "break-word" }}
                                                                    format="DD-MMM-yyyy"
                                                                >
                                                                    {currentStatus.startDate}
                                                                </Moment>
                                                            )}
                                                        </>
                                                    )}
                                                </td>

                                            </tr>
                                            <tr className="merchentList_tr">
                                                <td className="merchentList_td">
                                                    End date
                                                </td>
                                                <td className="merchentList_td">

                                                    {currentStatus.VIPStatus !== 'Regular' && (
                                                        <>
                                                            {currentStatus.endDate !== null && (
                                                                <Moment
                                                                    style={{ wordBreak: "break-word" }}
                                                                    format="DD-MMM-yyyy"
                                                                >
                                                                    {currentStatus.endDate}
                                                                </Moment>
                                                            )}
                                                        </>
                                                    )}
                                                </td>

                                            </tr>
                                            <tr className="merchentList_tr">
                                                <td className="merchentList_td">
                                                    Free deliveries earned
                                                </td>
                                                <td className="merchentList_td">
                                                    {currentStatus.freeDeliveriesEarned}
                                                </td>
                                            </tr>
                                            <tr className="merchentList_tr">
                                                <td className="merchentList_td">
                                                    Free deliveries used
                                                </td>
                                                <td className="merchentList_td">
                                                    {currentStatus.freeDeliveriesUsed}
                                                </td>
                                            </tr>
                                            <tr className="merchentList_tr">
                                                <td className="merchentList_td">
                                                    Carry forward delivery<br />count ( last month )
                                                </td>
                                                <td className="merchentList_td">
                                                    {currentStatus.previousFreeDelivery}
                                                </td>
                                            </tr>
                                            <tr className="merchentList_tr">
                                                <td className="merchentList_td">
                                                    Total free delivery count<br /> left ( current month )
                                                </td>
                                                <td className="merchentList_td">
                                                    {currentStatus.freeDeliveryLeft}
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>

                                <div>
                                    <table
                                        style={{ marginTop: '20px', marginLeft: 0 }}
                                    >
                                        <tr>
                                            <th colSpan="7" className="tableTextStyle">VIP History</th>
                                        </tr>
                                        <tbody>
                                            <tr className="merchentList_tr">
                                                <td className="merchentList_td vip_history_table_head">
                                                    S No
                                                </td>
                                                <td className="merchentList_td vip_history_table_head">
                                                    Amount Paid
                                                </td>
                                                <td className="merchentList_td vip_history_table_head">
                                                    Plan Name
                                                </td>
                                                <td className="merchentList_td vip_history_table_head">
                                                    Date of payment
                                                </td>
                                                <td className="merchentList_td vip_history_table_head">
                                                    Payment mode
                                                </td>
                                                <td className="merchentList_td vip_history_table_head">
                                                    Plan start date
                                                </td>
                                                <td className="merchentList_td vip_history_table_head">
                                                    Plan end date
                                                </td>

                                            </tr>
                                            {vipHistory && vipHistory.map((data, key) => (
                                                <tr className="merchentList_tr">
                                                    <td className="merchentList_td">
                                                        {key + 1}
                                                    </td>
                                                    <td className="merchentList_td">
                                                        {data.amtPaid}
                                                    </td>
                                                    <td className="merchentList_td">
                                                        {data.planName}
                                                    </td>
                                                    <td className="merchentList_td">
                                                        {data.dateOfPayment !== null && (
                                                            <Moment
                                                                style={{ wordBreak: "break-word" }}
                                                                format="DD-MMM-yyyy"
                                                            >
                                                                {data.dateOfPayment}
                                                            </Moment>
                                                        )}

                                                    </td>
                                                    <td className="merchentList_td">
                                                        {data.paymentMode}
                                                    </td>
                                                    <td className="merchentList_td">
                                                        {data.startDate !== null && (
                                                            <Moment
                                                                style={{ wordBreak: "break-word" }}
                                                                format="DD-MMM-yyyy"
                                                            >
                                                                {data.startDate}
                                                            </Moment>
                                                        )}
                                                    </td>
                                                    <td className="merchentList_td">
                                                        {data.endDate !== null && (
                                                            <Moment
                                                                style={{ wordBreak: "break-word" }}
                                                                format="DD-MMM-yyyy"
                                                            >
                                                                {data.endDate}
                                                            </Moment>
                                                        )}
                                                    </td>

                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                </div>

                            </>
                        )}


                    </div>

                </Col>
            </Row>
        </Container>
    );
};

export default VipHistory;