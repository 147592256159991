import {S3} from 'aws-sdk'
import { APIConfig } from '../apiConfig/apiConfig';
const S3_BUCKET ='categoryvisitingcards';
const REGION ='mumbai';

const {ACCESS_KEY, SECRET_ACCESS_KEY} = APIConfig;
const time = Date.now();
const config = {
    bucketName: S3_BUCKET,
    region: REGION,
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
}
const TAG = 'AwsImageService';
const AwsImageService = {
  uploadImageToS3: async (file) => {
    const s3bucket = new S3({
      accessKeyId: config.accessKeyId,
      secretAccessKey: config.secretAccessKey,
      Bucket: config.bucketName,
      signatureVersion: 'v4',
    });
    let contentType = file.type;
    let contentDeposition = 'inline:filename="' + file.name + '"';
    // const base64 = await fs.readFile(file.uri, 'base64');
    // const arrayBuffer = decode(base64);
    console.log("fileName", 'panelupload_' + time + "_" + file.name)
    return new Promise((resolve, reject) => {
      s3bucket.createBucket(() => {
        const params = {
          Bucket: config.bucketName,
          Key: 'panelupload_' + time + "_" + file.name,
          Body: file,
          ContentDisposition: contentDeposition,
          ContentType: contentType,
        };
        console.log("params",params)
        s3bucket.upload(params, (err, data) => {
          if (err) {
            console.log('error in callback f',err);
            reject(err);
          } else if(data){
            console.log('error in callback p',data);
            console.log('success');
            console.log('===+++', data);
            resolve({uri: data.Location});
          }
        });
      });
    });
  },
}
export default AwsImageService; 