import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import { withRouter } from "react-router";
import Sidebar from "./sidebar.js";
import '../Dashboard.css';
import axios from 'axios';
import view from "../images/view.png";
import edit from "../images/edit.png";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { Link } from "react-router-dom";
import merchantService from "../api/services/MerchantService.js";
import CategoryService from '../api/services/CategoryService';
import Moment from 'react-moment';
import 'moment-timezone';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  alertSearchFailMessage,
} from "./customComponent/ConfirmationMessage/ConfirmationMessage";
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { APIConfig } from "../api/apiConfig/apiConfig.js";

const shopCloseValues = [
  { id: 0, value: 'Closed' },
  { id: 1, value: 'Open' },
]

const Dashboard = (props) => {

  const [merchentList, setMerchentList] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [l3Data, setL3Sata] = useState([]);
  const [l1Data, setL1Data] = useState([])
  localStorage.removeItem('L3CategoryData');
  localStorage.removeItem('L1CategoryData');
  const [deactiveadvertisers, setDeactiveAdvertisers] = useState([]);
  const [deactiveCount, setDeactiveCount] = useState(1);
  const [offset, setOffSet] = useState(0);
  const [totalMerchant, setTotalMerchant] = useState('');
  const [limt, setlimt] = useState(50);
  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = useState('');
  const [displayStart, setDisplayStart] = useState('');
  const [displayEnd, setDisplayEnd] = useState('');
  const [displayPagination, setDisplayPagination] = useState(false);
  const [shopClose, setShopClose] = useState([]);
  const { baseUrl } = APIConfig;

  useEffect(() => {
    getViewMerchant(offset, limt);
  }, []);
  const getList = async () => {
    setIsLoading(true);
    await merchantService.getList()
      .then(async (res) => {
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              let result = JSON.parse(res);
              if (result.code === 0) {
                if (result.data) {
                  setMerchentList([...result.data]);
                  setIsLoading(false);
                } else {
                  setMerchentList([]);
                  setIsLoading(false);
                }
              } else {
                setMerchentList([]);
                setIsLoading(false);
              }
            });
          } catch (err) {
            console.log(err);
            setIsLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }
  const getCategoryTree = async () => {
    await CategoryService.getCategoryTree()
      .then(async (res) => {
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              let result = JSON.parse(res);
              if (result.code === 0) {
                let array = []
                let l1Array = []
                {
                  result.data && result.data.map(item => {
                    { item && l1Array.push(item) }
                    {
                      item.children && item.children.map(it => {
                        {
                          it.children && it.children.map(data => {
                            // console.log("meow",data.name)
                            {
                              data &&
                                array.push(data)
                            }
                          })
                        }
                      })
                    }
                  })
                }
                setL3Sata([...array]);
                setL1Data([...l1Array]);
                console.log(l1Array);
              } else {
                console.log(result.message);
              }
            })
          } catch (err) {
            console.log(err);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function search(rows) {
    console.log("bow2", rows)
    return rows.filter(
      (rows) =>

        // rows.merchantId.toLowerCase().indexOf(searchInput.toLowerCase()) > -1 
        rows.shopname !== null && rows.shopname !== undefined && rows.shopname.toLowerCase().indexOf(searchInput.toLowerCase()) > -1 ||
        // rows.city.toLowerCase().indexOf(searchInput.toLowerCase()) > -1 ||
        rows.mainmobilenumber !== null && rows.mainmobilenumber !== undefined && rows.mainmobilenumber.toLowerCase().indexOf(searchInput.toLowerCase()) > -1
      // rows.teammobilenumbers.toLowerCase().indexOf(searchInput.toLowerCase()) > -1

    )
  }

  const getViewMerchant = (offset2, limt2) => {
    axios.get(window.baseUrl + 'viewMerchant?limit=' + limt2 + '&offset=' + offset2)
      .then((response) => {
        setDisplayPagination(true);
        setTotalMerchant(response.data.totalMerchants);
        let pageCount1 = response.data.totalMerchants / 50;
        pageCount1 = Math.ceil(pageCount1);
        if (pageCount != pageCount1) {

          setPageCount(pageCount1);
          setDisplayStart('1');
          setDisplayEnd('50');
        }

        var res = response.data.data;
        let arr = [];
        res.map(mer => {
          arr.push(mer.is_shop_closed);
        })
        setShopClose(arr);

        // for (var i = 0; i < res.length; i++) {
        //   console.log(res[0].createddate);
        //   res.sort(function (a, b) {

        //     return new Date(b.createddate) - new Date(a.createddate)
        //   })
        setMerchentList(response.data.data);
        // }

      });
    getCategoryTree();
  }

  // const deactiveIncrementCounter = () => {
  //   setDeactiveCount(deactiveCount + 1);
  //   let offset1 = offset;
  //   let offset2 = (deactiveCount + 1) * 50;
  //   let limt1 = limt;

  //   if (offset2 > totalMerchant) {
  //     offset1 = totalMerchant - (offset1 + 50);
  //     limt1 = offset1;
  //     setlimt(offset1);
  //   }
  //   else {
  //     offset1 = offset1 + 50;
  //     setlimt(50);
  //     limt1 = 50
  //   }
  //   setOffSet(offset1);
  //   getViewMerchant(offset1, limt1);
  // };
  const handleSearchInput = (event) => {
    setSearchInput(event);
    if (event == "" || event == "NULL") {
      let offset1 = 0;
      setlimt(50);
      let limt1 = 50;
      setOffSet(offset1);
      getViewMerchant(offset1, limt1);
    }
  }
  const handleChange = (event, value) => {
    setPage(value);
    let offset1 = offset;
    let offset2 = (value) * 50;
    let limt1 = limt;

    if (value == 1) {
      offset1 = 0;
      setlimt(50);
      limt1 = 50;
      setDisplayStart('1');
      setDisplayEnd('50');
    } else
      if (offset2 > totalMerchant) {
        offset1 = totalMerchant - ((value - 1) * 50);
        limt1 = offset1;
        setlimt(offset1);
        setDisplayStart(((value - 1) * 50) + 1);
        setDisplayEnd(totalMerchant);
      }
      else {
        offset1 = (value * 50);
        limt1 = 50;
        setlimt(50);
        setDisplayStart(((value - 1) * 50) + 1);
        setDisplayEnd(value * 50);
      }

    setOffSet(offset1);
    getViewMerchant(offset1, limt1);

  };

  // const deactiveDecrementCounter = () => {
  //   let offset1 = offset;
  //   setDeactiveCount(deactiveCount - 1);
  //   if (offset > 0) {
  //     offset1 = offset1 - 50;
  //     if (offset1 < 0) {
  //       offset1 = 0;
  //     }

  //   }
  //   setOffSet(offset1);
  //   let deactivecount = deactiveCount - 1;
  //   getViewMerchant(offset1, 50);
  //   if (deactiveCount < 2) {
  //     setDeactiveCount(1);
  //   }
  // };
  const handleSearch = (event) => {

    axios.get(window.baseUrl + 'viewMerchant?search=' + searchInput)
      .then((response) => {
        // var res = response.data.data;
        // console.log(res.length);
        if (response.data.message == "Empty List") {

          alertSearchFailMessage(`${response.data.message}`);

        }
        else {
          setDisplayPagination(false);
          setMerchentList(response.data.data);
          let arr = [];
          response.data.data.map(mer => {
            arr.push(mer.is_shop_closed);
          })
          setShopClose(arr);
        }
        console.log("bow", response);
      });
  };

  const handleShopStatus = (e) => {
  }

  const updateShopClosingTime = async (mob, key) => {
    let value = shopClose[key];
    await axios
      .post(baseUrl + "updateShopClosingTime", {
        is_shop_closed: value,
        merch_mob_num: mob,
        is_from_panel: 0
      })
      .then(function (response) {
        console.log("shopCloseRes", response)
        if (response.data.code === 0) {
          window.location.reload();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleShopCloseChange = (key, e) => {
    setShopClose(prev => {
      const arr = [...prev];
      arr[key] = e.target.value;
      return arr;
    })
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={2} id="sidebar-wrapper">
            <Sidebar />
          </Col>
          <Col xs={10} id="page-content-wrapper">
            <div className="tittle ">
              <h1>Merchant List</h1>
            </div>
            <div className="Merchentlist" style={{ marginBottom: '5%' }}>
              <div className="search py-4 align-right px-4">
                <TextField id="outlined-basic" variant="outlined" placeholder="Business name/phone/MID" value={searchInput} onChange={(event) => handleSearchInput(event.target.value)} InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <SearchIcon />
                    </InputAdornment>
                  )
                }} style={{ width: '70%' }} />
                {/* <input type= "text" /> */}
                <Button variant="outlined" className="Search_button" onClick={handleSearch}>Search</Button>
              </div>
              <table border="1" cellpadding="0" cellSpacing="0" className="merchentList_table">
                <tr className="merchentList_tr">
                  <th className="merchentList_th">Business Name</th>
                  <th className="merchentList_th"> Address</th>
                  <th className="merchentList_th">Merchant ID</th>
                  <th className="merchentList_th">Merchant Name</th>
                  <th className="merchentList_th">Category Name</th>
                  <th className="merchentList_th">Mobile Number</th>
                  <th className="merchentList_th">Date & time</th>
                  <th className="merchentList_th">Status</th>
                  <th className="merchentList_th">Action</th>
                  <th className="merchentList_th">Shop Status</th>
                </tr>
                <tbody>
                  {merchentList.map((val, key) => {
                    return (
                      <tr className="merchentList_tr" key={key}>
                        <td className="merchentList_td">{val.shopname}</td>
                        <td className="merchentList_td">{val.addressline1},{val.addressline2},{val.city}</td>
                        <td className="merchentList_td">{val.merchantid}</td>
                        <td className="merchentList_td">{val.merchantname}</td>
                        <td className="merchentList_td">{val.l1_categoryname},{val.l2_categoryname},{val.l3_categoryname},{val.l4_categoryname}</td>
                        <td className="merchentList_td">{val.mainmobilenumber}</td>
                        <td className="merchentList_td"><Moment format="MMM Do YYYY hh:mm A">{val.tnctime}</Moment></td>
                        <td className="merchentList_td">{val.bizstatus === 0 ? 'Active' : 'In Active'}</td>

                        <td className="merchentList_td">
                          {val.merchantid != null ?
                            <a className="pr-2" href={`/merchentDetails/${val.merchantid}`}>
                              <img style={{ width: '30px', height: '30px' }} src={view} ></img>
                            </a> : ''}

                          {/* <a className="px-2" href={`/editmerchentDetails/${val.merchantid}`}> */}
                          {val.merchantid != null ?
                            <Link to={{
                              pathname: `/editmerchentDetails/${val.merchantid}`,
                              state: val,
                              l3Data: l3Data,
                              l1Data: l1Data
                            }}>
                              <img style={{ width: '20px', height: '20px' }} src={edit} ></img>
                            </Link> : ''}
                          {/* </a> */}
                        </td>
                        {/* <a href={`/merchentDetails/${key}`}> */}
                        <td className="merchentList_td">
                          <select v-model="selected" className="editInput" value={shopClose[key]} onChange={(e) => handleShopCloseChange(key, e)}>
                            {shopCloseValues.map(item => (
                              <option value={item.id} >
                                {item.value}
                              </option>
                            ))}
                          </select>
                          <button className="shop_status_submit_btn" onClick={() => updateShopClosingTime(val.mainmobilenumber, key)}>Submit</button>


                        </td>
                      </tr>
                    )
                  })}

                </tbody>
              </table>
            </div>

            {/* <div style={{ display: "flex", justifyContent: "center", alignItems: "center", justifyItems: "center", position: "fixed", bottom: "0px", width: "-webkit-fill-available", maxWidth: "-webkit-fill-available", backgroundColor: "#0375B0", margin: "0px", padding: "0px" }}>

              <span>
                <button style={{ fontSize: "25px", cursor: "pointer", padding: "0px 5px 0px 5px" }} disabled={offset == 0 ? true : false} onClick={() => deactiveDecrementCounter()}>
                  <RemoveIcon />
                </button>
              </span>
              <span style={{ fontSize: "28px", margin: "2px 0px 0px 0px", width: "5%", textAlign: "center" }}> {deactiveCount}</span> */}
            {/* {deactiveCount < totalDeactivePages && ( */}
            {/* <span>
                <button style={{ fontSize: "25px", cursor: "pointer", padding: "0px 5px 0px 5px" }} disabled={offset + 50 >= totalMerchant ? true : false} onClick={() => deactiveIncrementCounter()} >
                  <AddIcon />
                </button>
              </span> */}
            {/* )} */}

            {/* </div> */}
            {displayPagination == true ?
              <Stack spacing={2} style={{ display: 'block', width: '83%', marginBottom: '5px', left: '16%', right: '0px', position: 'fixed', bottom: '0px', backgroundColor: '#CDECF4', padding: '5px 3px ' }}>
                <Typography style={{ display: 'inline-block', width: '30%' }}>Showing Results {displayStart} to {displayEnd} of {totalMerchant} </Typography>
                <Pagination style={{ display: 'inline-block', marginTop: '-3px', float: 'right' }} count={pageCount} page={page} className="PaginationCheck" onChange={handleChange} />
              </Stack>
              : ''}
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Dashboard