import {S3} from 'aws-sdk'
import { APIConfig } from '../apiConfig/apiConfig';

const {S3_BUCKET, ACCESS_KEY, SECRET_ACCESS_KEY} = APIConfig;

const REGION ='mumbai';

const config = {
    bucketName: S3_BUCKET,
    region: REGION,
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
}
const TAG = 'AwsPDFService';
const AwsPDFService = {
    uploadImageToS3: async (file) => {
    const s3bucket = new S3({
      accessKeyId: config.accessKeyId,
      secretAccessKey: config.secretAccessKey,
      Bucket: config.bucketName,
      signatureVersion: 'v4',
    });
    let contentType = 'application/pdf';
    let contentDeposition = 'inline:filename="' + file.name + '"';
    // const base64 = await fs.readFile(file.uri, 'base64');
    // const arrayBuffer = decode(base64);
    return new Promise((resolve, reject) => {
      s3bucket.createBucket(() => {
        const params = {
          Bucket: config.bucketName,
          Key: file.name,
          Body: file,
          ContentDisposition: contentDeposition,
          ContentType: contentType,
        };
        console.log("params",params)
        s3bucket.upload(params, (err, data) => {
          if (err) {
            console.log('error in callback f',err);
            reject(err);
          } else if(data){
            console.log('error in callback p',data);
            console.log('success');
            console.log('===+++', data);
            resolve({uri: data.Location});
          }
        });
      });
    });
  },
}
export default AwsPDFService; 