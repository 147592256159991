import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Form, Modal } from "react-bootstrap";
import '../../Dashboard.css';
import '../style/Qc.css';
import axios from 'axios';
import { withRouter } from "react-router";
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import DownloadIcon from '@mui/icons-material/Download';
import { saveAs } from "file-saver";
import Moment from 'react-moment';
import 'moment-timezone';
import moment from 'moment';
import AwsPDFService from '../../api/services/AwsPDFService';
import FileUploader from '../../components/customComponent/FileUploader/FileUploader';





const MenuValidation = (props) => {
    const [mobileNo, setMobileNo] = useState("");
    const [remark, setRemark] = useState(props.Remark);
    const [updatedTimestamp, setupdatedTimestamp] = useState("");
    const [catalogphotos, setCatalogphotos] = useState([]);
    const [status, setstatus] = useState("");
    const [catlogPDF, setCatlogPDF] = useState('');
    const [menuStatus, setMenuStatus] = useState('');
    const [selectValue, setSelectValue] = useState('');
    const [merchantName, setMerchantName] = useState('');
    const [TicketNo, setTicketNo] = useState("");
    const [Merchantid, setMerchantid] = useState("");
    const [type, setType] = useState();
    const [agent_id, setAgent_id] = useState("");

    



    React.useEffect(() => {
        setstatus(props.data.menu_qc);
        setupdatedTimestamp(props.data.sec5_updated_at);
        setCatlogPDF(props.data.menu_pdf);
        //merchantalias
        setMerchantName(props.data.merchantalias);
        setTicketNo(props.data.ticket_no);
        setMerchantid(props.match.params.id);
        setRemark(props.Remark);
        setType(props.data.l1_categoryname);
        setAgent_id(props.data.agent_id);




        const moment = require('moment');
        var catlog_img = props.data.catalogphotos;

        var array_catlog_img = [];
        { props.data.catalogphotos == "undefined" || props.data.catalogphotos == null ? array_catlog_img = [] : array_catlog_img = catlog_img };

        { props.data.catalogphotos == "undefined" || props.data.catalogphotos == 'null' || props.data.catalogphotos == null || props.data.catalogphotos == '' ? setCatalogphotos('') : setCatalogphotos(array_catlog_img) };
    }, [props]);
    function handleDownloadPDF(url) {

        var FileSaver = require('file-saver');
        let url1 = url;
        let image_type = url1.split(/[#?]/)[0].split('.').pop().trim();
        let newimge = "image" + "." + image_type;
        FileSaver.saveAs(url, newimge);
    };
    function handleDownload(imageUrl, index) {
        let image = imageUrl.replace('https://testservergoping', 'http://testservergoping');
        fetch(
            image,
            {
                method: "GET",
                headers: {}
            }
        )
            .then((response) => {
                response.arrayBuffer().then(function (buffer) {
                    const url = window.URL.createObjectURL(new Blob([buffer]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.download = "image.png";
                    link.click();
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const headers = {
        "Content-Type": "application/json",
    };
    function handleApprovePDF() {
        setMenuStatus('0');
        const qc_panelStage5 = {
            ticket_no: TicketNo,
            merchantid: Merchantid,
            status: 0,
            reason: "",
            type: type,
            remark: remark,
            catalogpdf: catlogPDF,
            agent_id: agent_id
        };
        axios
            .post(window.baseUrl + `updateStage5`, qc_panelStage5, {
                headers: headers,
            })
            .then((res) => {
                console.log(res.data);
                console.log(res.data.message);
                window.location.reload();
            });

    };


    function handleNewPDF(item) {

        setMenuStatus('0');
        console.log(item[0].file);

        let newName = merchantName.replace(/ /gi, "-") + "_menu_" + moment().unix();

        var prefix = item[0].file.name.split('.')[0];
        var fileExtension = '.' + item[0].file.name.split('.')[1];
        var name = newName + new Date().getTime() + fileExtension;
        var blob = item[0].file.slice(0, item[0].size, item[0].type);
        var newFile = new File([blob], name, { type: item[0].type });


        AwsPDFService.uploadImageToS3(newFile)
            .then(data => {
                //   url = data.uri;
                if (data.uri !== '') {
                    // setCategoryImage(url);
                    // alert(data.uri)
                    console.log(data.uri);
                    const qc_panelStage5 = {
                        ticket_no: TicketNo,
                        merchantid: Merchantid,
                        status: 0,
                        reason: "",
                        type: type,
                        remark: remark,
                        catalogpdf: data.uri,
                        agent_id: agent_id
                    };
                    axios
                        .post(window.baseUrl + `updateStage5`, qc_panelStage5, {
                            headers: headers,
                        })
                        .then((res) => {
                            console.log(res.data);
                            console.log(res.data.message);
                            window.location.reload();
                        });
                }
            }
            )
            .catch(err => console.error(err));

    };
    function handleRejectPDF() {
        setMenuStatus('1');
        const qc_panelStage5 = {
            ticket_no: TicketNo,
            merchantid: Merchantid,
            status: 1,
            reason: "",
            type: type,
            remark: remark,
            catalogpdf: catlogPDF,
            agent_id: agent_id
        };
        axios
            .post(window.baseUrl + `updateStage5`, qc_panelStage5, {
                headers: headers,
            })
            .then((res) => {
                console.log(res.data);
                console.log(res.data.message);
                window.location.reload();
            });

    };
    function changeFunc(event) {
        event.preventDefault();
        setSelectValue(event.target.value);
        if (event.target.value == 1) {
            handleApprovePDF();
        } else if (event.target.value == 3) {
            handleRejectPDF();
        }
    };
    return (
        <div className="background_validation">
            <Row>
                <Col className="mt-4">
                    <div className="pt-2 header" style={{ marginBottom: '8%' }}>
                        <b className="pt-3 title">Section -5: Verify Restaurant OnBoarding  </b>
                        <div className="d-inline-block float-right w-25  border border-secondary">
                            <div className="d-flex flex-row border-bottom border-secondary last_update">
                                <p className="pl-2 mb-0"><b>Last Update</b></p>
                            </div>
                            <div className="d-flex flex-row mt-2">
                                <p className="pl-2">Status : </p>
                                <p>{status == 0 ? "Approved" : status == 1 ? "Resubmit" : "New"}</p>
                            </div>
                            <div className="d-flex flex-row">
                                <p className="pl-2">Timestamp : </p>
                                <p>{updatedTimestamp == null ? 'Null' : <Moment format="DD/MM/YYYY">{updatedTimestamp}</Moment>}</p>
                            </div>
                        </div>
                    </div>
                    <Box sx={{ flexGrow: 1 }} style={{marginBottom:'2%'}}>
                        <Grid container spacing={{ xs: 2, md: 4 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                            {Array.from(Array(catalogphotos.length)).map((val, index) => (
                                <Grid item xs={2} sm={4} md={4} key={index}>
                                    <img src={catalogphotos[index]} width={'98%'} height={'200px'} style={{ objectFit: 'contain' }}></img>
                                    <DownloadIcon fontSize="large" style={{ verticalAlign: 'top', marginLeft: '-30px', cursor: 'pointer' }} onClick={() => { handleDownload(catalogphotos[index], index) }} >
                                    </DownloadIcon>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Col>
            </Row>
            <Row>
                {catlogPDF != '' && catlogPDF != 'null' && catlogPDF != null ? <p style={{ display: 'inline-flex' }}>{catlogPDF} </p> : ''}
            </Row>
            <div style={{marginBottom:'3%'}}>
                <Row>
                    <Col className="mt-4">
                 
                        <Row style={{display:'inline-block',width:'20%'}}>
                            {catlogPDF != '' && catlogPDF != 'null' && catlogPDF != null ? <button onClick={() => handleDownloadPDF(catlogPDF)} style={{ display: 'inline-block', borderRadius: '4px', lineHeight: '41px',width:'90%' }}> Download PDF</button> : ''}

                        </Row>
                        <Row style={{display:'inline-block',width:'79%'}}>
                            <select id="selectBox" onChange={changeFunc} style={{marginRight:'1%',height:'39px',borderRadius:'4px'}}>
                                <option value="" disabled selected>Validate section</option>
                                <option value="1" >Approve PDF</option>
                                <option value="2" >Upload new PDF </option>
                                <option value="3" >Reject ticket </option>
                            </select>
                            {/* {/* <div className="approveDiv" style={{ width: '16%' }} >
                                <button class="btn_sec" onClick={handleApprovePDF} style={{ width: '83%' }} > Approve PDF</button>
                            </div> */}
                            {selectValue == '2' ?
                                <div className="approveDiv">
                                    <div className="" style={{width:'90%'}}>
                                        <FileUploader
                                            newPdf={true}
                                            handleNewPDF={(item) =>
                                                handleNewPDF(item)
                                            }
                                            isEditable={false}
                                        />
                                        {/* <input type='file' accept='.pdf' style={{ display: 'inline-block', width: '62%' }}  onChange={handleNewPDF}/> */}
                                        {/* <button class="btn_sec" onClick={handleNewPDF} style={{ display: 'inline-block', width: '29%' }}>Upload new PDF </button> */}
                                    </div>
                                </div> : ''
                            }
                            {/* <div className="approveDiv" style={{ width: '15%' }}>
                                <button class="btn_sec" onClick={handleRejectPDF} style={{ width: '100%' }}> Reject ticket </button>
                            </div> */}
                        </Row>
                    </Col>
                </Row>


            </div>
        </div>
    );
};
const Dashboard = withRouter(MenuValidation);
export default Dashboard;
